import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '../helpers/ProtectedRoute';
import EventLists from '../views/Broadcast/EventLists';

const broadcastRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <ProtectedRoute path={`${path}/`} exact>
        <EventLists />
      </ProtectedRoute>

    </Switch>
  );
};

export default broadcastRoutes;
