import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

import { formatDate } from '../../../helpers/utils';
import ActionButton from '../../ActionButton/ActionButton';
import { DocumentsContext } from '../../../contexts/documents.context';
import ActionMessage from '../ActionMessage';
import usePrevious from '../../../hooks/usePrevious';

const HTML_DATE_FORMAT = 'YYYY-MM-DD';

const FormAccept = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { document } = useContext(DocumentsContext);
  const [date, setDate] = useState('');
  const [formError, setFormError] = useState('');
  const prevDocument = usePrevious(document);

  useEffect(() => {
    if (prevDocument?.document_id !== document?.document_id) {
      setDate(formatDate(document?.valid_until, HTML_DATE_FORMAT));
    }
  }, [document]);

  const handleDate = (e) => {
    setDate(e?.target?.value || '');
  };

  const handleAccept = () => {
    if (!date || !dayjs(date, HTML_DATE_FORMAT).isValid()) {
      setFormError(
        t('DocumentAction.invalidDate', 'Format de date invalide ou vide'),
      );
    } else {
      onSubmit(date);
    }
  };

  return (
    <>
      <div className="date-input__container">
        <Form.Group controlId="duedate">
          <Form.Label className="date-input__label">
            {t('DocumentAction.datelabel', "Valable jusqu'au *")}
          </Form.Label>
          <Form.Control
            type="date"
            name="duedate"
            placeholder="Due date"
            value={date}
            onChange={handleDate}
            className="date-input"
          />
        </Form.Group>
      </div>

      <div
        className={`submit-button-container d-flex ${
          formError ? 'justify-content-between' : 'justify-content-end'
        }`}
      >
        {
          !!formError && (
            <ActionMessage message={formError} type="error" />
          )
        }
        <ActionButton
          type="button"
          buttonClassName="submit-button"
          onClick={handleAccept}
        >
          {t('DocumentAction.button', 'Valider')}
        </ActionButton>
      </div>
    </>
  );
};

FormAccept.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default FormAccept;
