import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import arrow from '../../assets/dropdown-arrow-grey.svg';
import redCross from '../../assets/square-red-cross.svg';
import greenCheck from '../../assets/square-green-check.svg';
import { toKey } from '../../helpers/utils';

const DropdownMenu = ({
  items,
  value,
  onSubmit,
  onChange,
  noLimit,
  isDisabled,
}) => {
  const [option, setOption] = useState(value);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleOption = (status) => () => {
    setOption(status);
    setIsEditMode(true);
    if (onChange) {
      onChange(status);
    }
  };

  const handleSubmit = async () => {
    if (option && onSubmit) {
      onSubmit(option.value);
    }
  };

  const cancel = () => {
    setIsEditMode(false);
    setOption(value);
  };

  useEffect(() => {
    setOption(value);
    setIsEditMode(false);
  }, [value]);

  return (
    <div>
      <Dropdown
        className={[
          'dropdown-container',
          onSubmit ? 'dropdown-container__submit' : '',
        ].join(' ')}
      >
        <Dropdown.Toggle
          disabled={isDisabled}
          variant="btn-sm"
          id="dropdown-basic"
          className="dropdown-list d-flex"
        >
          <span className="dropdown-title">
            {
              option?.label
              || ''
            }
          </span>
          <span>
            <img src={arrow} alt="arrow down" className="arrow-dropdown" />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className={noLimit ? 'dropdown__no-height' : ''}>
          {items.map((item) => (
            <Dropdown.Item
              key={toKey(item)}
              onClick={handleOption(item)}
              disabled={item.disabled}
            >
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {onSubmit && isEditMode && (
        <span>
          <button
            onClick={handleSubmit}
            type="submit"
            className="no-button-style"
          >
            <img src={greenCheck} alt="change value" className="validate-icon" />
          </button>
          <button
            type="button"
            onClick={cancel}
            className="no-button-style"
            aria-label="cancel"
          >
            <img src={redCross} alt="change value" className="return-icon" />
          </button>
        </span>
      )}
    </div>

  );
};

DropdownMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  noLimit: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

DropdownMenu.defaultProps = {
  onChange: null,
  onSubmit: null,
  items: [],
  value: {},
  noLimit: false,
  isDisabled: false,
};

export default DropdownMenu;
