import {
  safeFetch,
} from '../helpers/response-handler';
import {
  queryToString,
} from '../helpers/utils';

const {
  REACT_APP_URL_BROADCAST_API,
} = process.env;

export const getEvents = (query) => safeFetch(
  `${REACT_APP_URL_BROADCAST_API}/v1/broadcast/events${queryToString(query)}`,
);
