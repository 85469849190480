import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import AMLinesChart from '../AMLinesChart/AMLinesChart';
import AMPieChart from '../AMPieChart/AMPieChart';

const Charts = ({ dataChart, dataPieChart, bigPieChart }) => (
  <Row className="mx-5 my-3 bg-white border">
    <Col md={12} className="my-5">
      <AMLinesChart config={{
        series: dataChart,
        showLegend: true,
      }}
      />
    </Col>
    <Col md={12} className="mt-5">
      <AMPieChart data={dataPieChart} valueField="value" categoryField="display_name" bigChart={bigPieChart} />
    </Col>
  </Row>
);

Charts.propTypes = {
  dataChart: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.number),
  })).isRequired,
  dataPieChart: PropTypes.arrayOf(PropTypes.shape({
    display_name: PropTypes.string,
    value: PropTypes.number,
  })).isRequired,
  bigPieChart: PropTypes.bool.isRequired,
};

export default Charts;
