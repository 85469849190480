/* 400 */
export const ClassicBadRequest = {
  httpStatus: 400,
  message: 'Bad request',
  code: 400,
};

export const BadRequest = {
  httpStatus: 400,
  message: 'Bad request',
  code: 400.1,
};

export const UnreadableApiKey = {
  httpStatus: 400,
  message: 'Unreadable Api Key.',
  code: 400.1001,
};

export const UnreadableToken = {
  httpStatus: 400,
  message: 'Unreadable Token.',
  code: 400.1002,
};

export const UnknownVerb = {
  httpStatus: 400,
  message: 'Unknown verb.',
  code: 400.1003,
};

/* 401 */
export const Unauthorized = {
  httpStatus: 401,
  message: 'Unauthorized',
  code: 401,
};

export const UnauthorizedAccess = {
  httpStatus: 401,
  message: 'Unauthorized access',
  code: 401.1,
};

/* 403 */
export const Forbidden = {
  httpStatus: 403,
  message: 'Forbidden app for current user.',
  code: 403,
};

export const ForbiddenApp = {
  httpStatus: 403,
  message: 'Forbidden app for current user.',
  code: 403.1001,
};

export const ForbiddenAction = {
  httpStatus: 403,
  message: 'Forbidden action',
  code: 403.1006,
};

export const ForbiddenVerb = {
  httpStatus: 403,
  message: 'Forbidden verb.',
  code: 403.1002,
};

export const NoResource = {
  httpStatus: 403,
  message: 'Forbidden resource.',
  code: 403.1003,
};

export const NoScope = {
  httpStatus: 403,
  message: 'No scope found.',
  code: 403.1004,
};

/* 404 */
export const ClassicNotFound = {
  httpStatus: 404,
  message: 'Resource not found',
  code: 404,
};

export const NotFound = {
  httpStatus: 404,
  message: 'Resource not found',
  code: 404.1,
};

/* 500 */
export const ClassicServer = {
  httpStatus: 500,
  message: 'Server Error',
  code: 500,
};

export const Server = {
  httpStatus: 500,
  message: 'Server Error',
  code: 500.1,
};

export const NoDatabaseConfiguration = {
  httpStatus: 500,
  message: 'No database configured.',
  code: 500.2,
};

export const BadGateway = {
  httpStatus: 502,
  message: 'Bad gateway.',
  code: 502,
};

/* 520 */
export const Unknown = {
  httpStatus: 520,
  message: 'Unknown error',
  code: 520.1,
};
