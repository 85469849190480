import React from 'react';
import PropTypes from 'prop-types';
import { UUID_REGEX } from '../../helpers/patterns';

const ContractList = ({ onPaste }) => {
  const changeValue = (e) => {
    const val = doList(e.clipboardData.getData('text'));
    onPaste(val);
  };

  const doList = (val) => {
    const array = val.split('\r\n');
    let listValid = true;
    array.forEach((element) => {
      if (!isValid(element)) {
        listValid = false;
      }
    });
    if (listValid) {
      const delElem = document.getElementById('errorList');
      if (delElem) {
        delElem.remove();
      }
      return array;
    }
    const delElem = document.getElementById('errorList');
    if (delElem) {
      delElem.remove();
    }
    const p = document.createElement('p');
    p.textContent = 'Liste non valide';
    p.style.color = 'red';
    p.id = 'errorList';
    const root = document.getElementById('root');
    root.appendChild(p);
    return [];
  };

  const isValid = (str) => UUID_REGEX.test(str);

  return (
    <div className="textAreaContainer">
      <textarea name="textArea" id="textAreaId" cols="50" rows="10" onPaste={changeValue} />
    </div>
  );
};

ContractList.propTypes = {
  /** onPaste value */
  onPaste: PropTypes.func,
};

ContractList.defaultProps = {
  /** onPaste value */
  onPaste: () => { },
};

export default ContractList;
