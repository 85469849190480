import { safeFetch } from '../helpers/response-handler';

const { REACT_APP_URL_COLLECTION_API } = process.env;

export const createCollectionDocType = (body) => safeFetch(
  `${REACT_APP_URL_COLLECTION_API}/v1/collection/collections/doctypes`,
  {
    method: 'post',
    body: JSON.stringify(body),
  },
);

export const getCollectionDoctype = () => safeFetch(
  `${process.env.REACT_APP_URL_COLLECTION_API}/v1/collection/collections/doctypes`,
);

export const getCollection = (collectionId) => safeFetch(
  `${process.env.REACT_APP_URL_COLLECTION_API}/v1/collection/collections/${collectionId}`,
);

export const deleteCollectionDocType = (id) => safeFetch(
  `${process.env.REACT_APP_URL_COLLECTION_API}/v1/collection/collections/doctypes/${id}`,
  { method: 'delete' },
);
export const actionItem = (itemId, action) => safeFetch(
  `${process.env.REACT_APP_URL_COLLECTION_API}/v1/collection/items/${itemId}/actions/${action}`,
  { method: 'post' },
);

export const getItemsFromPartner = (collectionId, partnerId) => safeFetch(
  `${process.env.REACT_APP_URL_COLLECTION_API}/v1/collection/items/values?${new URLSearchParams({
    format: 'json',
    orderBy: JSON.stringify({ created_at: 'DESC' }),
    page: '1',
    limit: '1000000',
  }).toString()}`,
  {
    method: 'POST',
    body: JSON.stringify({
      'collection.id': collectionId,
      'itemPartner.partner_id': partnerId,
    }),
  },
);
