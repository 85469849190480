import React from 'react';
import '../../styles/css/style.css';
import VendorsList from '../../components/VendorsList/VendorsList';
import Header from '../../components/Header/Header';
import Filter from '../../components/Filter/Filter';

const { REACT_APP_VERSION } = process.env;

const ComplianceDashboard = () => (
  <div className="container-fluid">
    <div className="row">
      <Header />
      <Filter />
      <VendorsList />
    </div>
    <div className="version">
      {`V: ${REACT_APP_VERSION}`}
    </div>
  </div>
);

export default ComplianceDashboard;
