import React, { useState, useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import searchIcon from '../../assets/search.svg';
import cross from '../../assets/cross.svg';
import { toKey } from '../../helpers/utils';

/**
 * SearchBox is a component that allows you to search for a specific
 * chain of character inside a table. You can use an API to fetch your table,
 *  you just need a request URL to do so.
 * @version 1
 * @since 29/11/2021
 */

const SearchInput = ({
  label,
  placeholder,
  search,
  data,
  onClick,
  onReset,
  clear,
}) => {
  const [selected, setSelected] = useState({});
  const [value, setValue] = useState('');
  const { t } = useTranslation();

  const hasResult = () => Object.keys(data).length || !value;

  const hasSelected = () => !!selected.name;

  /**
   * This function is used to handle the typing speed of the user
   * First, it's a 'callback' hook with no dependencies, it means that the function instance
   * will be the same whatever the count of render
   * Second, the callback takes usually a function as first arg but here,
   * it's a debounce function (from lodash) that will execute its "content"
   * only if it don't be called during the given delay (second arg of debounce).
   */
  const searchOnChange = useCallback(
    debounce(async (val) => {
      if (val) {
        search(val);
      } else if (!val) {
        clearSearchField();
      }
    }, 300),
    [],
  );

  const changeValue = async (e) => {
    setValue(e?.target?.value);
    searchOnChange(e?.target?.value);
    setSelected({});
  };

  const clearSearchField = () => {
    setValue('');
    setSelected({});
    onReset();
  };

  const handleClick = (item) => () => {
    setValue(item.name);
    setSelected(item);
    onClick(item);
  };

  useEffect(() => {
    if (clear) clearSearchField();
  }, [clear]);

  return (
    <div className="label-and-option">
      <div className="filtre-label">{label}</div>
      <div className="search-and-results">
        <div className="search-container">
          <input
            type="search"
            id="seachVendorId"
            className=" search-input"
            placeholder={placeholder}
            onChange={changeValue}
            value={value}
            autoComplete="off"
          />
          {!value ? (
            <img src={searchIcon} className="search" alt="search" />
          ) : (
            <img
              src={cross}
              className="search cursor-pointer"
              onClick={clearSearchField}
              alt="delete"
              aria-hidden="true"
            />
          )}
        </div>
        <div className="results-container">
          {!hasResult() && (
            <div className="dropdown__noresult">
              <div>{t('Searchbox.noresults', 'Pas de résultat')}</div>
            </div>
          )}
          {value && hasResult() && !hasSelected() && (
            <div className="dropdown__container">
              {data.map((item) => (
                <div
                  key={toKey(item)}
                  className="dropdown__item"
                  onClick={handleClick(item)}
                  aria-hidden="true"
                >
                  {item.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchInput;

SearchInput.propTypes = {
  /** Label above the input */
  label: PropTypes.string,
  /** Placeholder inside the input */
  placeholder: PropTypes.string,
  /** vendor or customer value */
  search: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  onReset: PropTypes.func,
  clear: PropTypes.bool,
};

SearchInput.defaultProps = {
  /** Label above the input */
  label: '',
  /** Placeholder inside the input */
  placeholder: '',
  data: [],
  onClick: () => {},
  onReset: () => {},
  clear: false,
};
