import React, { useState } from 'react';
import SearchBox from '../../components/SearchBox/SearchBox';
import '../../styles/css/style.css';
import './UpdateContractOwner.scss';
import ContractList from '../../components/ContractList/ContractList';
import Header from '../../components/Header/Header';
import * as Services from '../../services/contracts.service';

/**
 *Updates the company attached to a list of contracts in the database
 *The list of contracts must be pasted into the textArea
 *The new company that will be attached to the contracts must be selected with the SearchBox
 */
const UpdateContractOwner = () => {
  const [list, setList] = useState([]);
  const [enterprise, setEnterprise] = useState('');

  const changeValue = (value) => {
    setList(value);
  };

  const handleClick = (item) => {
    setEnterprise(item);
  };

  /**
   * Iterates over the list of contracts,
   * checks the existence of each contract and calls the update method if they exist
   */
  const iterate = async () => {
    const container = document.getElementById('components-container');
    if (list && enterprise) {
      const delElem = document.getElementById('list');
      if (delElem) {
        delElem.remove();
      }
      list.forEach((element) => {
        exist(element)
          .then((result) => {
            if (result === true) {
              update(element);
              const p = document.createElement('p');
              p.textContent = `Success on ${element} | new owner: ${enterprise.name}`;
              p.style.color = 'green';
              p.id = 'list';
              container.appendChild(p);
              setList(true);
            } else {
              const p = document.createElement('p');
              p.textContent = `Error on ${element} | contract does not exist`;
              p.style.color = 'red';
              p.id = 'list';
              container.appendChild(p);
              setList(true);
            }
          })
          .catch(Error);
      });
      const p = document.createElement('p');
      p.textContent = '-----------------------';
      p.style.color = 'black';
      p.id = 'list';
      container.appendChild(p);
    }
  };

  /**
   * Checks that a contract does indeed exist by searching for it in the database
   * @param {*} contract
   * @returns a promise with result true or false
   */
  const exist = async (contract) => {
    const testedContract = await Services.getContractById(contract);
    return testedContract.success;
  };

  /**
   * Update a contract in database with API call
   * @param {*} contractId
   */
  const update = async (contractId) => {
    await Services.updateContract(contractId, {
      enterpriseId: enterprise.id,
    });
  };

  return (
    <div>
      <Header />
      <div className="container">
        <div id="components-container">
          <div className="explaination_container">
            <h2>Change contract owner</h2>
            <p>
              Change the owners of your contracts.
              Paste a list of valid contracts into the box provided and select
              the new contract owner. Then click on the button.
            </p>
          </div>
          <div className="update_container">
            <div>
              <h3>Paste valid contracts</h3>
              <ContractList onPaste={changeValue} />
            </div>
            <div>
              <h3>Select enterprise</h3>
              <SearchBox placeholder="write enterprise" type="customer" onClick={handleClick} />
            </div>
            <button className="iterate__button" type="submit" onClick={iterate}>Update contract(s)</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateContractOwner;
