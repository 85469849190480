import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';

import AWFormContent from '../../components/AWFormContent/AWFormContent';
import ActionButton from '../../components/ActionButton/ActionButton';
import Step from './Step';
import Task from './Task';
import StepTasks from './StepTasks';

import { AlertContext } from '../../contexts/alert.context';
import { randString, removeFakeIds } from '../../helpers/utils';
import useLoadingPromise from '../../hooks/useLoadingPromise';
import { ConnectionConfigContext } from '../../contexts/connectionConfig.context';
import { createConnectionConfig, updateConnectionConfig } from '../../services/enterprise.service';

const Content = ({
  buildConnectionConfig,
  isSubmitButtonEnabled,
}) => {
  const { t } = useTranslation();
  const { replace } = useHistory();
  const { setNotif } = useContext(AlertContext);
  const {
    connectionConfig,
    setConnectionConfig,
    customTaskContents,
    isConnectionConfigSketch,
  } = useContext(ConnectionConfigContext);
  const { waitWithLoad, isLoading } = useLoadingPromise();

  const [activeContent, setActiveContent] = useState();
  const [deleteStepModal, setDeleteStepModal] = useState({
    isOpen: false,
    stepNumber: null,
  });

  useEffect(() => {
    const contentToDisplay = connectionConfig.steps.reduce((acc, step) => {
      if (step.display) return step;
      if (step.tasks && step.tasks.length) {
        const taskToDisplay = step.tasks.find((t) => t.display);
        if (taskToDisplay) return { ...taskToDisplay, stepNumber: step.step };
      }
      return acc;
    }, connectionConfig.steps[0]);
    setActiveContent(contentToDisplay);
  }, [connectionConfig.steps]);

  const addStep = () => {
    const clone = buildConnectionConfig();
    if (clone.steps[clone.steps.length - 1].tasks.length) {
      clone.steps.push({
        step: connectionConfig.steps.length + 1,
        tasks: [],
        checkers: [],
        conditions: [],
        display: true,
      });
      setConnectionConfig(clone);
    }
  };

  const deleteStep = (stepNumber) => {
    const clone = buildConnectionConfig();
    clone.steps = clone.steps.filter((s) => s.step !== stepNumber);
    clone.steps = clone.steps.map((s, i) => ({ ...s, step: i + 1 }));
    setConnectionConfig(clone);
    if (deleteStepModal.isOpen) {
      setDeleteStepModal({ isOpen: false, stepNumber: null });
    }
  };

  const handleDeleteStep = (step) => {
    if (step.tasks.length) {
      setDeleteStepModal({ isOpen: true, stepNumber: step.step });
    } else {
      deleteStep(step.step);
    }
  };

  const createNewConnectionConfig = async () => {
    const _connectionConfig = buildConnectionConfig();
    _connectionConfig.steps = _connectionConfig.steps.map((s) => ({
      ...s,
      conditions: s.conditions.map(removeFakeIds),
      tasks: s.tasks.map(removeFakeIds),
    }));
    const res = await waitWithLoad(_connectionConfig.id
      ? updateConnectionConfig(_connectionConfig)
      : createConnectionConfig(_connectionConfig));
    if (res.success) {
      setNotif({
        translated: true,
        message: t('ConnectionConfig.addedSuccess.', 'Processus sauvegardé avec succès'),
        variant: 'success',
      });
      replace(`/customers/${res.data.customer_id}/configs/${res.data.id}/content`);
    } else {
      setNotif({
        translated: true,
        message: t('ConnectionConfig.addedFailed.', 'Une erreur est survenue'),
        variant: 'danger',
      });
    }
  };

  const displayContent = useMemo(() => {
    if (activeContent) {
      if ('step' in activeContent) {
        return <Step step={activeContent} buildConnectionConfig={buildConnectionConfig} />;
      }
      if ('content' in activeContent) {
        return <Task task={activeContent} buildConnectionConfig={buildConnectionConfig} />;
      }
    }
    return <> </>;
  }, [activeContent, connectionConfig]);

  const displayNewTask = (stepNumber) => {
    setActiveContent({
      id: randString(),
      content: customTaskContents[0].value,
      owners: [],
      stepNumber,
      display: true,
    });
  };

  const displayTask = (task, stepNumber) => {
    setActiveContent({ ...task, stepNumber });
  };

  const deleteTask = (task) => {
    const clone = buildConnectionConfig();
    const step = clone.steps.find(
      (_step) => _step.tasks.some((_task) => _task.id === task.id),
    );
    step.tasks = step.tasks.filter((_task) => _task.id !== task.id);
    setActiveContent(<> </>);
    setConnectionConfig(clone);
  };

  return (
    <>
      <AWFormContent>
        <AWFormContent.ContentList title={t('ConnectionConfig.manageSteps', 'Gestion des étapes')}>
          {
            connectionConfig.steps?.length ? connectionConfig.steps.map((step) => (
              <StepTasks
                key={step.step}
                step={step}
                deleteStepEnabled={connectionConfig.steps.length > 1 && isConnectionConfigSketch}
                handleAddTask={displayNewTask}
                handleTask={displayTask}
                handleStep={setActiveContent}
                handleDeleteStep={handleDeleteStep}
                handleDeleteTask={deleteTask}
              />
            )) : <> </>
          }
          {isConnectionConfigSketch ? (
            <>
              <ActionButton
                buttonClassName="my-4 text-center dashed-button"
                onClick={addStep}
              >
                {t('ConnectionConfig.addStep', 'Ajouter une étape')}
              </ActionButton>
              <ActionButton
                backgroundColor="#12957D"
                color="white"
                buttonClassName="w-100"
                onClick={createNewConnectionConfig}
                disabled={!isSubmitButtonEnabled || isLoading || !isConnectionConfigSketch}
                type="button"
              >
                {connectionConfig.id ? t(('Common.update', 'Mettre à jour')) : t('ConnectionConfig.create', 'Créer le processus')}
              </ActionButton>
            </>
          ) : <> </>}
        </AWFormContent.ContentList>
        <AWFormContent.Content>
          {displayContent}
        </AWFormContent.Content>
      </AWFormContent>
      <Modal
        show={deleteStepModal.isOpen}
        centered
      >
        <Modal.Header>
          <p>{t('ConnectionConfig.deleteStep', 'Supprimer une étape')}</p>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t(
              'ConnectionConfig.deleteStepDescription',
              'La suppression de cette étape entrainera la suppression des tâches associées.',
            )}
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <ActionButton
            type="button"
            onClick={() => setDeleteStepModal({ isOpen: false, stepNumber: null })}
          >
            {t('Common.back', 'Retour')}
          </ActionButton>
          <ActionButton
            backgroundColor="#12957D"
            color="white"
            onClick={() => deleteStep(deleteStepModal.stepNumber)}
            type="button"
          >
            {t('Common.delete', 'Supprimer')}
          </ActionButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Content.propTypes = {
  buildConnectionConfig: PropTypes.func.isRequired,
  isSubmitButtonEnabled: PropTypes.bool.isRequired,
};

export default Content;
