import React from 'react';
import PropTypes from 'prop-types';

const LabelValueLine = ({
  label,
  children,
  className,
}) => (
  <div className={`d-flex justify-content-between ${className}`}>
    <div className="label d-flex">
      {label}
    </div>
    <div>
      {children}
    </div>
  </div>
);

LabelValueLine.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LabelValueLine.defaultProps = {
  className: '',
};

export default LabelValueLine;
