import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DocumentActionButton from '../../DocumentActionButton/DocumentActionButton';

import { ACTION_STATUS } from '../../../helpers/constants';

import btnRefusedClicked from '../../../assets/btnRefusedClicked.svg';
import btnRefusedOff from '../../../assets/btnRefusedOff.svg';
import btnRefusedOn from '../../../assets/btnRefusedOn.svg';
import btnValidatedClicked from '../../../assets/btnValidatedClicked.svg';
import btnValidatedOff from '../../../assets/btnValidatedOff.svg';
import btnValidatedOn from '../../../assets/btnValidatedOn.svg';
import btnWaitClicked from '../../../assets/btnWaitClicked.svg';
import btnWaitOn from '../../../assets/btnWaitOn.svg';
import btnWaitOff from '../../../assets/btnWaitOff.svg';

import { DocumentsContext } from '../../../contexts/documents.context';

const iconEnum = {
  on: {
    validated: btnValidatedOn,
    rejected: btnRefusedOn,
    pending: btnWaitOn,
  },
  clicked: {
    validated: btnValidatedClicked,
    rejected: btnRefusedClicked,
    pending: btnWaitClicked,
  },
  off: {
    validated: btnValidatedOff,
    rejected: btnRefusedOff,
    pending: btnWaitOff,
  },
};

const StatusButton = ({
  type,
  onChange,
  status,
}) => {
  const { document } = useContext(DocumentsContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (document?.status) {
      onChange('');
    }
  }, [document]);

  const getIcon = () => {
    let icon;
    if (document?.status === type) {
      icon = iconEnum.on[type || ACTION_STATUS.pending];
    } else if (status === type) {
      icon = iconEnum.clicked[type || ACTION_STATUS.pending];
    } else {
      icon = iconEnum.off[type || ACTION_STATUS.pending];
    }
    return icon;
  };

  const handleClick = () => {
    if (status !== type) {
      onChange(type);
    }
  };

  const getTooltip = () => {
    switch (type) {
      case ACTION_STATUS.validated:
        return t(
          'StatusButton.Validate',
          'Valider',
        );
      case ACTION_STATUS.rejected:
        return t(
          'StatusButton.Refuse',
          'Refuser',
        );
      case ACTION_STATUS.pending:
        return t(
          'StatusButton.Pending',
          'Mettre en attente',
        );
      default:
        return t(
          'StatusButton.Pending',
          'Mettre en attente',
        );
    }
  };

  return (
    <DocumentActionButton
      icon={getIcon()}
      onClick={handleClick}
      disabled={document?.status === type}
      tooltip={getTooltip()}
    />
  );
};

StatusButton.propTypes = {
  /** The type of the component, it can be 'validated' or 'rejected' */
  type: PropTypes.string,
  /** Event when user change the status (before submit) */
  onChange: PropTypes.func.isRequired,
  /** Status of external */
  status: PropTypes.string,
};

StatusButton.defaultProps = {
  type: '',
  status: '',
};

export default StatusButton;
