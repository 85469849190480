import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const AWFormContent = ({ children }) => (
  <Container fluid className="p-0 aw-form-container">
    <Row>
      {children}
    </Row>
  </Container>
);

const ContentList = ({ title, children }) => (
  <Col xs={4} className="padding-col bg-white question-list overflow-auto">
    <h3>
      {title}
    </h3>
    {children}
  </Col>
);

const Content = ({ children }) => (
  <Col xs={8} className="padding-col survey overflow-auto">
    {children}
  </Col>
);

AWFormContent.ContentList = ContentList;
AWFormContent.Content = Content;

AWFormContent.propTypes = {
  children: PropTypes.node.isRequired,
};

ContentList.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AWFormContent;
