import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DocumentInfoTab from '../DocumentInfoTab/DocumentInfoTab';
import VendorTab from '../VendorTab/VendorTab';
import CommentTab from '../CommentTab/CommentTab';

const TABS = {
  document: 'document',
  provider: 'provider',
  comment: 'comment',
};

const ActiveTab = React.memo(({ active }) => {
  switch (active) {
    case TABS.provider:
      return <VendorTab />;
    case TABS.comment:
      return <CommentTab />;
    default:
      return <DocumentInfoTab />;
  }
});

ActiveTab.propTypes = {
  active: PropTypes.string.isRequired,
};

/**
 * DocumentInfo is a component that contains multiple
 * tabs and allow the user to show the tab he clicks on.
 * @version 1
 * @since 13/12/2021
 */

const DocumentInfo = () => {
  const { t } = useTranslation();
  const [activeDocTab, setActiveDocTab] = useState('document');

  const handleTab = (active) => () => {
    setActiveDocTab(active);
  };

  return (
    <div className="tabs margin-top-35">
      <ul className="nav-info w-30">
        <li
          className={activeDocTab === TABS.document ? 'active' : ''}
          onClick={handleTab(TABS.document)}
          aria-hidden="true"
        >
          {t('DocumentInfo.document', 'Document')}
        </li>
        <li
          className={activeDocTab === TABS.provider ? 'active' : ''}
          onClick={handleTab(TABS.provider)}
          aria-hidden="true"
        >
          {t('DocumentInfo.provider', 'Sous-traitant')}
        </li>
        <li
          className={activeDocTab === TABS.comment ? 'active' : ''}
          onClick={handleTab(TABS.comment)}
          aria-hidden="true"
        >
          {t('DocumentInfo.comments', 'Commentaires')}
        </li>
      </ul>
      <div className="outlet">
        <ActiveTab active={activeDocTab} />
      </div>
    </div>
  );
};

export default DocumentInfo;
