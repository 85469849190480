import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import { Col, Dropdown } from 'react-bootstrap';
import SearchBox from '../SearchBox/SearchBox';
import { EnterprisesContext } from '../../contexts/enterprises.context';
import FilterRadio from '../FilterRadio/FilterRadio';
import i18n from '../../i18n';
import { getDocumentTypesOfAddworking } from '../../services/document.service';
import { AlertContext } from '../../contexts/alert.context';
import arrow from '../../assets/dropdown-arrow-grey.svg';
import { toKey } from '../../helpers/utils';
import cross from '../../assets/cross.svg';
import { country } from '../../helpers/constants';

const capitalizeFirstLetterAndRemoveDot = (string) => string.charAt(0).toUpperCase()
    + string
      .slice(1)
      .slice(0, string[string.length - 1] === '.' ? -1 : string.length - 1);

const setDay = (remove) => (
  <>
    {capitalizeFirstLetterAndRemoveDot(
      dayjs().subtract(remove, 'days').locale(i18n.language).format('ddd'),
    )}
    <br />
    {dayjs().subtract(remove, 'days').format('DD/MM')}
  </>
);
/**
 * Filtre is a component allowing your to filter your table results.
 * @version 1
 * @since 18/11/2021
 */

const Filter = () => {
  const { t } = useTranslation();
  const { fullEnterprisesSearch } = useContext(EnterprisesContext);
  const { filters } = useContext(EnterprisesContext);

  const handleFilterChange = (propertyName) => (value) => {
    fullEnterprisesSearch({ [propertyName]: value });
  };

  const countryOption = [
    {
      value: '',
      label: t('Filter.noCountry'),
    },
    ...Object.values(country).map((value) => (
      {
        value,
        label: t(`Country.${value}`),
      }
    )),
  ];

  const [countryFilter, setCountryFilter] = useState(countryOption[0]);

  const [documentTypes, setDocumentTypes] = useState([]);

  const { setNotif } = useContext(AlertContext);

  // eslint-disable-next-line no-unused-vars
  const [documentType, setDocumentType] = useState({});

  const getDocumentTypes = async () => {
    const response = await getDocumentTypesOfAddworking();
    response.displayNotif(setNotif);
    if (response.success) {
      setDocumentTypes(response.data);
    }
  };

  useEffect(() => {
    getDocumentTypes();
  }, []);

  const getDisplayName = () => {
    const displayNames = [];
    for (let i = 0; i < documentTypes.length; i += 1) {
      let result;
      if (documentTypes.at(i).display_name.includes('{')) {
        result = JSON.parse(documentTypes.at(i).display_name)?.fr;
      } else {
        result = documentTypes.at(i).display_name;
      }
      const obj = {
        value: documentTypes.at(i).id,
        label: result,
      };
      displayNames.push(obj);
    }
    displayNames.sort((a, b) => {
      const firstDocType = a.label.toLowerCase();
      const secondeDocType = b.label.toLowerCase();

      if (firstDocType < secondeDocType) {
        return -1;
      }
      if (firstDocType > secondeDocType) {
        return 1;
      }
      return 0;
    });
    return displayNames;
  };

  const [active, setActive] = useState(
    filters.documentTypeId
        || getDisplayName().find((doc) => !!doc.default)?.value
        || '',
  );
  const isActive = (name) => name === active;

  const handleChange = (doc) => {
    setActive(doc.value);
    fullEnterprisesSearch({ documentTypeId: doc.value });
  };

  const clearSearchField = () => {
    setActive(false);
    setCountryFilter(countryOption[0]);
    fullEnterprisesSearch({
      documentTypeId: '',
      country: '',
    });
  };

  const getLabelOfValue = (value) => {
    const result = getDisplayName().find((doc) => doc.value === value);
    return result?.label || '';
  };

  return (
    <Col xs={3} className="filtre-container padding-col">
      <h2>{t('Filter.title', 'Filtres')}</h2>

      {/* Last Upload Date label */}
      <div className="filtre-label">
        <div className="filtre-label">
          {t('Filter.uploaddate', "Date d'upload")}
        </div>
      </div>

      <FilterRadio
        onChangeValue={handleFilterChange('lastUpload')}
        propertyName="lastUpload"
        size="big"
        choices={[
          {
            name: 'all-last-upload',
            value: '',
            default: true,
            message: t('Filter.all', 'Tous'),
          },
          {
            name: 'day-0',
            value: '0',
            message: setDay(0),
          },
          {
            name: 'day-1',
            value: '1',
            message: setDay(1),
          },
          {
            name: 'day-2',
            value: '2',
            message: setDay(2),
          },
          {
            name: 'day-3',
            value: '3',
            message: setDay(3),
          },
          {
            name: 'day-4',
            value: '4',
            message: setDay(4),
          },
          {
            name: 'day-5',
            value: '5',
            message: setDay(5),
          },
          {
            name: 'day-6',
            value: '6',
            message: setDay(6),
          },
        ]}
      />

      <SearchBox
        label={t('Filter.vendorname', 'Raison sociale sous-traitant')}
        placeholder={t(
          'Filter.placeholderVendor',
          'Recherche une raison sociale sous-traitant',
        )}
        type="vendor"
        getValues={fullEnterprisesSearch}
      />

      <FilterRadio
        onChangeValue={handleFilterChange('statusKbis')}
        title={t('Filter.kbisvalid', 'Kbis valide ')}
        propertyName="statusKbis"
        choices={[
          {
            name: 'all-kbis',
            value: '',
            default: true,
            message: t('Filter.all', 'Tous'),
          },
          {
            name: 'yes-kbis',
            value: '1',
            message: t('Filter.yes', 'Oui'),
          },
          {
            name: 'no-kbis',
            value: '0',
            message: t('Filter.no', 'Non'),
          },
        ]}
      />

      <div className="filtre-label">
        <div className="filtre-label">
          {t('Filter.documentType', 'Types de documents')}
        </div>
      </div>
      <Dropdown>
        <Dropdown.Toggle
          variant="btn-sm"
          id="dropdown-basic"
          className="comment-input visibility-list d-flex"
        >
          <span className="select-doc-type">
            { getLabelOfValue(active) }
          </span>
          <span>
            { active ? (
              <img
                src={cross}
                className="search cursor-pointer"
                onClick={() => {
                  setDocumentType({});
                  clearSearchField();
                }}
                alt="delete"
                aria-hidden="true"
              />
            ) : (
              <img
                src={arrow}
                alt="arrow down"
                className="arrow-dropdown"
              />
            )}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {getDisplayName().map((doc) => (
            <Dropdown.Item
              onClick={() => {
                setDocumentType({
                  value: doc.value,
                  label: doc.label,
                });
                handleChange(doc);
              }}
              key={toKey(doc)}
              active={isActive(doc.value)}
            >
              {doc.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <div className="filtre-label">
        <div className="filtre-label">
          {t('Filter.countryProvider')}
        </div>
      </div>

      <Dropdown>
        <Dropdown.Toggle
          variant="btn-sm"
          id="dropdown-basic"
          className="comment-input visibility-list d-flex"
        >
          <span className="select-country">
            { countryFilter.label || '' }
          </span>
          <span>
            { active ? (
              <img
                src={cross}
                className="search cursor-pointer"
                onClick={clearSearchField}
                alt="delete"
                aria-hidden="true"
              />
            ) : (
              <img
                src={arrow}
                alt="arrow down"
                className="arrow-dropdown"
              />
            )}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {countryOption.map((option) => (
            <Dropdown.Item
              onClick={() => {
                setCountryFilter(option);
                fullEnterprisesSearch({ country: option.value });
              }}
              key={toKey(option)}
              active={option.value === countryFilter.value}
            >
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

    </Col>
  );
};

export default Filter;
