import React, {
  createContext,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  initCustomFields,
  initDocumentTypes,
  initSurveys,
} from '../views/ConnectionConfig/connectionConfig.builder';
import { ConnectionTaskContents, taskContents } from '../helpers/constants';
import { Status } from '../components/StatusTag/StatusTag';

export const ConnectionConfigContext = createContext();

const emptyConnectionConfig = {
  validators: [],
  steps: [
    {
      step: 1,
      tasks: [],
      conditions: [],
      display: true,
    },
  ],
  status: Status.Sketch,
};

const ConnectionConfigContextProvider = ({ children }) => {
  const [connectionConfig, setConnectionConfig] = useState(emptyConnectionConfig);
  const [members, setMembers] = useState([]);
  const [enterprise, setEnterprise] = useState({});
  const [documentTypes, setDocumentTypes] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [surveys, setSurveys] = useState([]);

  const isConnectionConfigContentDisabled = !connectionConfig?.customer_id
    || !connectionConfig?.title;

  const resetConnectionConfig = () => setConnectionConfig(emptyConnectionConfig);

  const initStates = async () => {
    const [
      _documentTypes,
      _customFields,
      _surveys,
    ] = await Promise.all([
      initDocumentTypes(connectionConfig),
      initCustomFields(connectionConfig),
      initSurveys(connectionConfig),
    ]);
    setDocumentTypes(_documentTypes);
    setCustomFields(_customFields);
    setSurveys(_surveys);
  };

  const isConnectionConfigSketch = connectionConfig?.status === Status.Sketch;

  const customTaskContents = useMemo(() => (
    taskContents.map((_task) => {
      if (
        (_task.value === ConnectionTaskContents.Form && !surveys.length)
        || (_task.value === ConnectionTaskContents.DocumentType && !documentTypes.length)
        || (_task.value === ConnectionTaskContents.CustomField && !customFields.length)
      ) {
        return { ..._task, disabled: true };
      }
      return _task;
    })
  ), [surveys, documentTypes, customFields]);

  const value = useMemo(() => ({
    connectionConfig,
    isConnectionConfigContentDisabled,
    members,
    enterprise,
    documentTypes,
    customFields,
    surveys,
    customTaskContents,
    isConnectionConfigSketch,
    resetConnectionConfig,
    setMembers,
    setEnterprise,
    setConnectionConfig,
    initStates,
  }), [
    connectionConfig,
    surveys,
    members,
    documentTypes,
    customFields,
    customTaskContents,
    enterprise,
    isConnectionConfigContentDisabled,
  ]);

  return (
    <ConnectionConfigContext.Provider value={value}>
      {children}
    </ConnectionConfigContext.Provider>
  );
};

ConnectionConfigContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ConnectionConfigContextProvider;
