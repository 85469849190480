import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react';
import {
  Switch,
  useParams,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProtectedRoute from '../../helpers/ProtectedRoute';
import SurveyForm from '../../components/SurveyForm/SurveyForm';
import {
  getSurvey,
  launchSurvey,
  stopSurvey,
} from '../../services/survey.service';
import { SurveyContext } from '../../contexts/survey.context';
import { AlertContext } from '../../contexts/alert.context';
import ActionButton from '../../components/ActionButton/ActionButton';
import { BadRequest } from '../../helpers/catalog.errors';
import StatusTag, { Status } from '../../components/StatusTag/StatusTag';
import greenCheck from '../../assets/green-check.svg';
import greyCross from '../../assets/grey-cross.svg';
import stateWait from '../../assets/stateWait.svg';
import SurveyGeneral from '../../components/SurveyGeneral/SurveyGeneral';
import SurveyResults from '../../components/SurveyResults/SurveyResults';
import AWFormPage from '../../components/AWFormPage/AWFormPage';

const Tabs = {
  Info: 'info',
  Survey: 'survey',
  Results: 'results',
};

const Checks = {
  Unvalid: 'unvalid',
  Valid: 'valid',
  Pending: 'pending',
};

const Survey = () => {
  const { t } = useTranslation();
  const { surveyId, enterpriseId } = useParams();
  const [creationMode, setCreationMode] = useState(true);
  const [tab, setTab] = useState(Tabs.Info);
  const [redirect, setRedirect] = useState();
  const { survey, setSurvey } = useContext(SurveyContext);
  const { setNotif } = useContext(AlertContext);
  const { pathname } = useLocation();

  const baseUrl = useMemo(() => {
    let result = '';
    if (enterpriseId) {
      result += `/customers/${enterpriseId}`;
      if (surveyId && !Object.values(Tabs).includes(surveyId)) {
        result += `/surveys/${surveyId}`;
      }
    }
    return result;
  }, [enterpriseId, surveyId]);

  const hasTab = useMemo(() => (
    Object.values(Tabs).some((tabItem) => pathname.endsWith(tabItem))
  ), [pathname]);

  useEffect(() => {
    const names = pathname.split('/');
    setTab(names[names.length - 1]);
    if (pathname.includes('/new')) {
      setCreationMode(true);
      setSurvey({});
    } else {
      setCreationMode(false);
      if (!hasTab && pathname.includes('/new')) {
        setRedirect(
          <Redirect to={`${baseUrl}/${Tabs.Survey}`} />,
        );
      }
    }
  }, [pathname]);

  const fetchSurvey = async () => {
    const surveyRes = await getSurvey(surveyId);
    surveyRes.displayNotif(setNotif);
    if (surveyRes.success) {
      setSurvey(surveyRes.data);
    } else if (!hasTab) {
      setRedirect(
        <Redirect to={`/surveys/customer/${enterpriseId}`} />,
      );
    }
  };

  const handleSurveyUpdate = (newSurvey) => {
    setSurvey(newSurvey);
  };

  useEffect(() => {
    if (!creationMode && hasTab) {
      fetchSurvey();
    }
  }, [creationMode]);

  useEffect(() => () => {
    if (enterpriseId) {
      setSurvey({});
    }
  }, [enterpriseId]);

  const launch = async () => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm(t('Survey.isOkToLaunch', 'Vous vous apprêter à valider le questionnaire, suite à cela, vous ne pourrez plus le modifier.'))) {
      const response = await launchSurvey(survey.id);
      if (response.success) {
        handleSurveyUpdate({ ...survey, ...response.data });
      } else if (response.hasError(BadRequest)) {
        setNotif({
          translated: true,
          message: t('Survey.badLaunch', "Le questionnaire n'est pas valide"),
          variant: 'danger',
        });
      }
    }
  };

  const stop = async () => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm(t('Survey.isOkToStop', 'Vous vous apprêter à arrêter votre questionnaire, ce choix est définitif.'))) {
      const response = await stopSurvey(survey.id);
      response.displayNotif(setNotif);
      if (response.success) {
        handleSurveyUpdate({ ...survey, ...response.data });
      }
    }
  };

  const validations = useMemo(() => {
    const checkFields = (fields) => {
      const hasTruthy = fields.some((field) => !!field);
      const hasFalsy = fields.some((field) => !field);
      if (hasTruthy && hasFalsy) {
        return Checks.Pending;
      }
      if (hasFalsy) {
        return Checks.Unvalid;
      }
      return Checks.Valid;
    };
    return {
      info: checkFields([
        survey?.label,
        survey?.description,
        survey?.country,
      ]),
      survey: checkFields([survey?.content?.length]),
    };
  }, [survey]);

  const icons = useMemo(() => {
    const getIcon = (state) => {
      switch (state) {
        case Checks.Valid:
          return greenCheck;
        case Checks.Pending:
          return stateWait;
        default:
          return greyCross;
      }
    };
    return {
      info: getIcon(validations.info),
      survey: getIcon(validations.survey),
    };
  }, [validations]);

  const leftTabs = [
    {
      linkTo: `${baseUrl}/${Tabs.Info}`,
      active: tab === Tabs.Info || creationMode,
      disabled: creationMode,
      icon: icons.info,
      content: t('Survey.info', 'Informations'),
    },
    {
      linkTo: `${baseUrl}/${Tabs.Survey}`,
      active: tab === Tabs.Survey,
      disabled: creationMode,
      icon: icons.survey,
      content: t('CreateSurvey.survey', 'Questionnaire'),
    },
    {
      linkTo: `${baseUrl}/${Tabs.Results}`,
      active: tab === Tabs.Results,
      disabled: creationMode,
      content: t('CreateSurvey.results', 'Résultats'),
    },
  ];

  return (
    <AWFormPage
      formTitle={survey?.label
        ? `${survey?.company?.name || ''} ${survey?.label || ''}`
        : t('Survey.new', 'Nouveau questionnaire')}
      previousPagePath={`/customers/${enterpriseId}/surveys`}
    >
      <AWFormPage.Side leftTabs={leftTabs}>
        <div className="mt-5">
          <div className="d-flex align-items-center">
            <label className="label m-0 px-3" htmlFor="surveyDesc">
              {t('Survey.status', 'Statut')}
            </label>
            <div>
              <StatusTag status={survey?.status || Status.Sketch} />
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="d-flex justify-content-center my-4">
            {
              survey?.status === Status.Launched ? (
                <ActionButton
                  backgroundColor="#3579f6"
                  color="white"
                  onClick={stop}
                >
                  {t('Status.stop', 'Arrêter')}
                </ActionButton>
              ) : ''
            }
            {
              survey?.status === Status.Sketch ? (
                <ActionButton
                  backgroundColor="#12957d"
                  color="white"
                  onClick={launch}
                  disabled={(
                    survey?.status !== Status.Sketch
                    || validations.info !== Checks.Valid
                    || validations.survey !== Checks.Valid
                  )}
                >
                  {t('Status.launch', 'Lancer')}
                </ActionButton>
              ) : ''
            }
          </div>
        </div>
      </AWFormPage.Side>
      <AWFormPage.Main>
        <Switch>
          <ProtectedRoute exact path="/customers/:enterpriseId/surveys/new">
            <SurveyGeneral
              creationMode
              survey={survey}
              onUpdate={handleSurveyUpdate}
            />
          </ProtectedRoute>

          <ProtectedRoute exact path={`/customers/:enterpriseId/surveys/:surveyId/${Tabs.Info}`}>
            <SurveyGeneral
              survey={survey}
              onUpdate={handleSurveyUpdate}
            />
          </ProtectedRoute>

          <ProtectedRoute exact path={`${baseUrl}/${Tabs.Survey}`}>
            <SurveyForm questions={survey.content} id={survey.id} />
          </ProtectedRoute>

          <ProtectedRoute exact path={`${baseUrl}/${Tabs.Results}`}>
            <SurveyResults />
          </ProtectedRoute>
          {redirect}
        </Switch>
      </AWFormPage.Main>
    </AWFormPage>
  );
};

export default Survey;
