import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../styles/css/style.css';
import Header from '../../components/Header/Header';
import DocumentsListTabs from '../../components/DocumentsListTabs/DocumentsListTabs';
import DocumentDisplay from '../../components/DocumentDisplay/DocumentDisplay';
import link from '../../assets/link.svg';
import DocumentInfoAction from '../../components/DocumentInfoAction/DocumentInfoAction';
import ReturnButton from '../../components/ReturnButton/ReturnButton';
import { DocumentsContext } from '../../contexts/documents.context';
import { EnterprisesContext } from '../../contexts/enterprises.context';
import { FileContext } from '../../contexts/file.context';
import { V1_PLATFORM } from '../../helpers/constants';
import societePNG from '../../assets/logo-societe-com.svg';
import infogreffPNG from '../../assets/infogreffe.svg';
import inpiPNG from '../../assets/logo_inpi.png';
import { toSiren } from '../../helpers/utils';
import { AlertContext } from '../../contexts/alert.context';
import { UserContext } from '../../contexts/user.context';

import {
  createEditingToken,
  getEnterprises,
  getEnterpriseLegalForm,
} from '../../services/enterprise.service';

const Vendor = () => {
  const {
    getDocumentsFromEnterprise,
    setAllDocuments,
    document,
    setDocument,
  } = useContext(DocumentsContext);
  const { user } = useContext(UserContext);
  const { getAllComments, setFile } = useContext(FileContext);
  const {
    enterprise,
    setEnterprise,
    getAllEnterprisesTokens,
  } = useContext(EnterprisesContext);
  const { setNotif } = useContext(AlertContext);
  const { enterpriseId } = useParams();
  const [legalForm, setLegalForm] = useState({});

  const createThenGetTokens = async () => {
    await createEditingToken(enterpriseId, user.email);
    getAllEnterprisesTokens();
  };

  const getEnterprise = async () => {
    if (enterpriseId) {
      const [enterpriseRes] = await Promise.all([
        getEnterprises({
          fields: 'id,identification_number,name,legal_form_id,registration_town,main_activity_code',
          search: {
            id: enterpriseId,
          },
        }),
        createThenGetTokens(),
      ]);
      enterpriseRes.displayNotif(setNotif);
      if (enterpriseRes.success && enterpriseRes.data?.length === 1) {
        setEnterprise(enterpriseRes.data[0]);
      }
    }
  };

  useEffect(async () => {
    getDocumentsFromEnterprise(enterpriseId);
    getEnterprise();
  }, [enterpriseId]);

  useEffect(() => {
    if (document) {
      getAllComments(document.document_id);
    }
  }, [document]);

  useEffect(
    () => () => {
      setEnterprise(null);
      setAllDocuments([]);
      setDocument(null);
      setFile({ url: '', mime: '' });
    },
    [],
  );

  const getLegalForm = async () => {
    if (enterprise?.legal_form_id) {
      const legalFormRes = await getEnterpriseLegalForm(
        enterprise.legal_form_id,
        {
          fields: 'name,display_name',
        },
      );
      legalFormRes.displayNotif(setNotif);
      if (legalFormRes.success) {
        setLegalForm(legalFormRes.data);
      }
    }
  };

  useEffect(() => {
    getLegalForm();
  }, [enterprise]);

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-9">
            <div className="d-flex">
              <div className="return-button-container">
                <ReturnButton />
              </div>
              <h1 className="m-0" title={legalForm?.display_name}>
                {`${legalForm?.name?.toUpperCase() || ''} - ${enterprise?.name}`}
              </h1>
              &nbsp;
              <div className="d-flex logos">
                <a
                  href={`${V1_PLATFORM}/enterprise/${enterpriseId}`}
                  target="_blank"
                  rel="noreferrer"
                  className="d-inline-flex justify-content-center align-items-center"
                >
                  <img
                    src={link}
                    className="link"
                    alt="link"
                    aria-hidden="true"
                  />
                </a>
                <a
                  href={`https://www.societe.com/cgi-bin/search?champs=${toSiren(enterprise?.identification_number)}`}
                  target="_blank"
                  rel="noreferrer"
                  className="d-inline-flex justify-content-center align-items-center"
                >
                  <img src={societePNG} alt="societe.com" title="societe.com" className="link" />
                </a>
                <a
                  href={`https://www.infogreffe.fr/recherche-entreprise-dirigeant/resultats-de-recherche?recherche=Entreprises&dirigeantPage=0&dirigeantPageSize=10&phrase=${toSiren(enterprise?.identification_number)}`}
                  target="_blank"
                  rel="noreferrer"
                  className="d-inline-flex justify-content-center align-items-center"
                >
                  <img src={infogreffPNG} alt="infogreffe" title="infogreffe" className="link" />
                </a>
                <a
                  href={`https://data.inpi.fr/entreprises/${
                    toSiren(enterprise?.identification_number)
                  }?q=${
                    toSiren(enterprise?.identification_number)
                  }#${
                    toSiren(enterprise?.identification_number)
                  }`}
                  target="_blank"
                  rel="noreferrer"
                  className="d-inline-flex justify-content-center align-items-center"
                >
                  <img src={inpiPNG} alt="inpi" title="inpi" className="link" />
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-3 padding-col-1">
                <DocumentsListTabs />
              </div>
              <div className="col-9 padding-col-2">
                <DocumentDisplay />
              </div>
            </div>
          </div>
          <div className="col-3 padding-col-3">
            <DocumentInfoAction />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vendor;
