import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FILE_TYPES, getFileType } from '../../helpers/utils';

const Display = React.memo(({ mime, url }) => {
  const { t } = useTranslation();

  if (!mime) return '';
  const type = getFileType(mime);
  switch (type) {
    case FILE_TYPES.PDF:
      return (
        <object
          data={url}
          type="application/pdf"
          className="document"
          aria-label="document pdf"
        />
      );
    case FILE_TYPES.IMAGE:
      return (
        <div className="document document-image">
          <img src={url} alt={`Document ${type.toLowerCase()}`} />
        </div>
      );
    default:
      return (
        <a className="download" href={url} download>
          <span>
            {
              t('DocumentDisplay.download', 'Télécharger le document')
            }
          </span>
        </a>
      );
  }
});
Display.propTypes = {
  mime: PropTypes.string,
  url: PropTypes.string,
};
Display.defaultProps = {
  mime: '',
  url: '',
};
export default Display;
