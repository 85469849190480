import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { formatDate } from '../../helpers/utils';

const ActionAuthor = React.memo(({
  user, action, date, reason,
}) => {
  const { t } = useTranslation();

  if (!action && !date) return '';
  return (
    <div className="doc-info__item ">
      <div className={`doc-info__title-${action === 'accepted' ? 'green' : 'red'}`}>
        {action === 'accepted'
          ? t(
            'DocumentInfoTab.acceptedAt',
            "Date de l'acceptation",
          ) : t(
            'DocumentInfoTab.rejectedAt',
            'Date du rejet',
          )}
      </div>
      <div className={`doc-info__content-${action === 'accepted' ? 'green' : 'red'}`}>
        {formatDate(date)}
        &nbsp;
        {!!Object.keys(user)?.length && `${t('DocumentInfoTab.by', 'par')} ${user?.firstname} ${user?.lastname}`}
        <br />
        {action === 'rejected' && `(${reason})`}
      </div>
    </div>
  );
});

ActionAuthor.propTypes = {
  user: PropTypes.shape().isRequired,
  action: PropTypes.string,
  date: PropTypes.string,
  reason: PropTypes.string,
};

ActionAuthor.defaultProps = {
  reason: '',
  action: '',
  date: '',
};

export default ActionAuthor;
