import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '../helpers/ProtectedRoute';
import CollectionItems from '../views/CollectionItems/CollectionItems';

const collectionRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <ProtectedRoute path={`${path}/:collectionId/items/:partnerId`} exact>
        <CollectionItems />
      </ProtectedRoute>
    </Switch>
  );
};

export default collectionRoutes;
