import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Tag from '../Tag/Tag';

export const Status = {
  Launched: 'launched',
  Stopped: 'stopped',
  Sketch: 'sketch',
  Validated: 'validated',
};

const StatusTag = ({ status }) => {
  const { t } = useTranslation();

  const color = useMemo(() => {
    switch (status) {
      case Status.Launched || Status.Validated:
        return '#12957d';
      case Status.Stopped:
        return '#3579f6';
      default:
        return '#a0a0a0';
    }
  }, [status]);

  return (
    <Tag color={color} label={t(`Status.${status}`)} />
  );
};

StatusTag.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusTag;
