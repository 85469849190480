import React, { useState, useContext } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import '../../styles/css/style.css';
import logo from '../../assets/logo_header.svg';
import arrow from '../../assets/arrow-dropdown.svg';
import { UserContext } from '../../contexts/user.context';

/**
 * Header is a component that contains Addworking logo on the left
 * and a nav bar on the right. Navbar contains username and language choices dropdown menu.
 * @version 1
 * @since 12/11/2021
 */

const Header = () => {
  // change page when click on Addworking logo
  const history = useHistory();
  const { pathname } = useLocation();
  const { logOut, user } = useContext(UserContext);
  const goHome = () => {
    history.push('/dashboard');
  };

  // Needed for translation
  const { t, i18n } = useTranslation();
  const lngs = {
    fr: { nativeName: 'Français' },
    en: { nativeName: 'English' },
  };

  // Change dropdown title on language selection
  const [dropdownTitle, setdropdownTitle] = useState(
    t('Component.Header.language', 'Langue'),
  );

  const signout = () => {
    logOut(pathname);
  };

  return (
    <div className="header-container navbar-dark">
      <div className="col-12">
        <Navbar expand="lg">
          <Navbar.Brand href="/dashboard">
            <img
              src={logo}
              alt="logo Addworking"
              className="logo-header"
              onClick={goHome}
              aria-hidden="true"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav ">
            <Nav className="ms-auto">
              <Nav className="nav-link px-4">
                <img
                  src={user?.picture}
                  alt="profile"
                  className="profile-picture"
                />
                {user && (
                  <span className="username">
                    {user.fullName}
                  </span>
                )}
              </Nav>
              <Nav.Link
                href="#home"
                className="nav-link px-4"
                onClick={signout}
              >
                {t('Component.Header.logout', 'Déconnexion')}
              </Nav.Link>
              <NavDropdown
                title={dropdownTitle}
                id="basic-nav-dropdown"
                className=" color-white "
              >
                {Object.keys(lngs).map((lng) => (
                  <NavDropdown.Item
                    key={lng}
                    style={{
                      fontWeight:
                        i18n.resolvedLanguage === lng ? 'bold' : 'normal',
                    }}
                    type="submit"
                    onClick={() => {
                      i18n.changeLanguage(lng);
                      setdropdownTitle(
                        lngs[lng].nativeName,
                      );
                    }}
                  >
                    {lngs[lng].nativeName}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <img src={arrow} alt="arrow down" />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;
