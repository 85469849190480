import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import AWSelect from '../../components/AWSelect/AWSelect';
import ActionButton from '../../components/ActionButton/ActionButton';
import AWForm from '../../components/AWForm/AWForm';
import { ConnectionConfigContext } from '../../contexts/connectionConfig.context';

const General = ({ baseUrl }) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const {
    connectionConfig,
    members,
    enterprise,
    isConnectionConfigContentDisabled,
    setConnectionConfig,
    isConnectionConfigSketch,
  } = useContext(ConnectionConfigContext);

  const handleTitleChange = ({ target: { value } }) => {
    if (value !== ' ') {
      setConnectionConfig({ ...connectionConfig, title: value });
    }
  };

  const handleValidatorsChange = (options) => {
    setConnectionConfig({
      ...connectionConfig,
      validators: options.map((opt) => ({ user_id: opt.value })),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    push(`${baseUrl}/content`);
  };

  return (
    <AWForm onSubmit={handleSubmit}>
      <h3 className="d-flex justify-content-center">
        {enterprise.name || t('ConnectionConfig.noEnterprise', 'Aucune entreprise trouvée')}
      </h3>
      <Form.Group className="mb-4">
        <Form.Label className="label" htmlFor="title">
          {t('ConnectionConfig.connectionConfigName', 'Nom du processus')}
        </Form.Label>
        <Form.Control
          id="title"
          value={connectionConfig?.title || ''}
          onChange={handleTitleChange}
          disabled={!isConnectionConfigSketch}
          className="roboto-regular"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label className="label" htmlFor="validators">
          {t('ConnectionConfig.validators', 'Validateur(s) de la mise en relation')}
        </Form.Label>
        <AWSelect
          id="validators"
          isMulti
          noNullOption
          options={members}
          isDisabled={!members.length || !isConnectionConfigSketch}
          noOptionsMessage={() => t('ConnectionConfig.anyValidators', 'Aucun validateur')}
          onChange={handleValidatorsChange}
          value={members.filter(
            (m) => connectionConfig.validators?.some((v) => v.user_id === m.value),
          )}
          placeholder=""
        />
      </Form.Group>
      <div className="mt-5 d-flex justify-content-center">
        <ActionButton
          type="submit"
          backgroundColor="#12957D"
          color="#FFFFFF"
          disabled={isConnectionConfigContentDisabled}
        >
          {t('ConnectionConfig.contentStep', 'Etape suivante')}
        </ActionButton>
      </div>
    </AWForm>
  );
};

General.propTypes = {
  baseUrl: PropTypes.string.isRequired,
};

export default General;
