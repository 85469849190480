import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { formatDate } from '../../helpers/utils';
import orangePen from '../../assets/icon_modif.svg';
import greenCheck from '../../assets/square-green-check.svg';
import redCross from '../../assets/square-red-cross.svg';

const DateInput = ({
  date,
  onSubmit,
  setAlert,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [newDate, setNewDate] = useState('');
  const HTML_DATE_FORMAT = 'YYYY-MM-DD';

  useEffect(() => {
    if (date) {
      setNewDate(formatDate(date, HTML_DATE_FORMAT));
      setIsEditMode(false);
    }
  }, [date]);

  const handleDate = (e) => {
    setNewDate(e?.target?.value || '');
  };

  const onChangeValidation = async () => {
    const isOk = await onSubmit(newDate);
    if (isOk) {
      setIsEditMode(false);
      setNewDate(newDate);
    }
  };

  const cancel = () => {
    setIsEditMode(false);
    setNewDate(formatDate(date, HTML_DATE_FORMAT));
    setAlert(false);
  };

  return (
    <div>
      {isEditMode ? (
        <span>
          <Form.Group controlId="date">
            <Form.Control
              type="date"
              name="date"
              placeholder="date"
              value={newDate}
              onChange={handleDate}
              className="date-input-small"
            />
            <button
              onClick={onChangeValidation}
              type="submit"
              className="no-button-style"
            >
              <img src={greenCheck} alt="change value" className="validate-icon" />
            </button>
            <button
              type="button"
              onClick={cancel}
              className="no-button-style"
              aria-label="cancel"
            >
              <img src={redCross} alt="change value" className="return-icon" />
            </button>
          </Form.Group>
        </span>
      )
        : (
          <span>
            {date ? formatDate(newDate) : 'N/A'}
            <button onClick={() => setIsEditMode(true)} type="button" className="no-button-style" aria-label="edit">
              <img src={orangePen} alt="change value" className="change-icon" />
            </button>
          </span>
        )}
    </div>
  );
};

DateInput.propTypes = {
  date: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

DateInput.defaultProps = {
  date: '',
};

export default DateInput;
