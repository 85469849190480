import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../../styles/css/style.css';
import Header from '../../components/Header/Header';
import DocumentsList from '../../components/AiComplianceChecker/DocumentsList';

const AiComplianceChecker = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <Container fluid className="full-screen-container">
        <Row>
          <Col xs={12}>
            <h1>
              {t('App.aiComplianceChecker')}
            </h1>
          </Col>
        </Row>
        <DocumentsList />
      </Container>
    </>
  );
};

export default AiComplianceChecker;
