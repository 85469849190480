import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as DocumentService from '../../services/document.service';
import Header from '../../components/Header/Header';
import {
  BE_IDENTIFICATION_NUMBER_REGEX,
  DE_IDENTIFICATION_NUMBER_REGEX,
  FR_IDENTIFICATION_NUMBER_REGEX,
  UUID_REGEX,
} from '../../helpers/patterns';
import { getEnterprise, getEnterprises } from '../../services/enterprise.service';
import { NotFound } from '../../helpers/catalog.errors';
import { AlertContext } from '../../contexts/alert.context';
import SearchForm from '../../components/Stats/SearchForm';
import Charts from '../../components/Stats/Charts';
import SidebarStats from '../../components/Stats/SidebarStats';

const DocumentStats = () => {
  const today = new Date();
  const thirtyDaysBefore = new Date();
  today.setDate(today.getDate());
  thirtyDaysBefore.setDate(today.getDate() - 30);
  const formatDate = (date) => date.toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(formatDate(thirtyDaysBefore));
  const [endDate, setEndDate] = useState(formatDate(today));
  const [dataChart, setDataChart] = useState([]);
  const [dataPieChart, setDataPieChart] = useState([]);
  const [enterprises, setEnterprises] = useState([]);
  const [enterprise, setEnterprise] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [showChart, setShowChart] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { setNotif } = useContext(AlertContext);

  const queryboyOptions = {
    fields: [
      'id',
      'name',
      'identification_number',
      'is_vendor',
      'is_customer',
      'created_at',
      'parent_id',
      'deleted_at',
      'is_headquarter',
      'no_payment_option',
    ],
    disableDefaultFilters: true,
    page_size: 100,
  };

  const handleSelectPeriod = (start, stop) => {
    setStartDate(formatDate(start));
    setEndDate(formatDate(stop));
  };
  const sortData = (data) => {
    // eslint-disable-next-line camelcase
    const docCounts = data.reduce((acc, { doccount, doctype_display_name }) => {
      // eslint-disable-next-line camelcase
      let display_name = doctype_display_name ? JSON.parse(doctype_display_name).fr : t('ContractState.unknown');
      // eslint-disable-next-line camelcase
      display_name = display_name.length > 100 ? `${display_name.slice(0, 100)}...` : display_name;
      const count = Number(doccount);
      if (!acc[display_name]) acc[display_name] = { display_name, value: 0 };
      acc[display_name].value += count;
      return acc;
    }, {});

    const sortedCounts = Object.values(docCounts)
      .sort((a, b) => b.value - a.value);

    const topData = sortedCounts.slice(0, 15);
    const restValue = sortedCounts.slice(15)
      .reduce((acc, { value }) => acc + value, 0);

    if (sortedCounts.length > 15) {
      topData.push({ display_name: 'Reste', value: restValue });
    }

    return topData;
  };

  useEffect(() => {
    if (searchTerm) {
      fetchEnterprises(searchTerm);
    }
  }, [searchTerm]);

  const fetchEnterprises = async (searchQuery) => {
    const matchings = [
      UUID_REGEX,
      FR_IDENTIFICATION_NUMBER_REGEX,
      DE_IDENTIFICATION_NUMBER_REGEX,
      BE_IDENTIFICATION_NUMBER_REGEX,
    ];
    let needSearch = false;
    if (matchings.some((m) => searchQuery.match(m))) {
      const res = await getEnterprise(searchQuery, queryboyOptions);
      setEnterprises([res.data]);
      if (res.hasError(NotFound)) needSearch = true;
    } else {
      needSearch = true;
    }
    if (needSearch) {
      const res = await getEnterprises(
        {
          ...queryboyOptions,
          search: { name: { $sw: searchQuery.toUpperCase().trim().replace(/\s/g, '_') } },
        },
      );
      if (res.success) {
        setEnterprises(res.data);
      } else {
        setEnterprises([]);
      }
    }
  };

  const handleSetEnterprise = (enterprise) => {
    setEnterprise(enterprise);
  };

  const handleReset = () => {
    setEnterprise(undefined);
  };

  const searchData = async () => {
    setLoading(true);
    const response = await DocumentService.search(
      'documents',
      {
        columns: ['updated_at', { created_at: { $date: 'day' } }, 'type_id', { id: { $count: 'docCount' } }, 'doctype.display_name', 'doctype.name', 'doctype.enterprise_id'],
        search: {
          created_at: { $gte: startDate, $lte: endDate },
          type_id: {
            $nnull: true,
            $eq: enterprise ? enterprise.id : undefined,
          },
        },
      },
      {
        orderBy: { updated_at: 'asc', docCount: 'DESC' },
        ignoreHydrate: true,
      },
    );

    if (response.success) {
      const data = processData(response.data, startDate, endDate);
      const dataSerialized = [];

      Object.keys(data).forEach((key) => {
        dataSerialized.push({
          name: key.length > 130 ? `${key.slice(0, 130)}...` : `${key}`,
          data: data[key],
          id: data[key].id,
        });
      });
      setDataChart(dataSerialized);
      setDataPieChart(sortData(response.data));
      setShowChart(true);
    } else if (response.code !== 404) {
      setDataChart([]);
      setDataPieChart([]);
      setNotif({
        type: 'error',
        title: t('Common.Error'),
        message: t('Stats.Errors.noData'),
      });
    }

    setLoading(false);
  };

  const processData = (data, startDate, endDate) => {
    const result = {};

    data.forEach((item) => {
      const displayName = item.doctype_display_name ? JSON.parse(item.doctype_display_name) : t('ContractState.unknown');
      const typeName = displayName.fr || t('ContractState.unknown');
      const enterprise = item.doctype_enterprise_id === 'f1494810-ed7a-406f-8aeb-7845c4105b01'
        ? 'ADDW' : `CUST-${item.doctype_enterprise_id}`;
      const key = `(${enterprise})_${typeName}`;

      if (!result[key]) {
        result[key] = [];
        let currentDate = new Date(startDate);
        while (currentDate <= new Date(endDate)) {
          result[key].push({
            id: item.doctype_enterprise_id,
            date: currentDate.getTime(),
            value: 0,
          });
          currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
        }
      }

      const { day } = item;
      const formattedDayTimestamp = new Date(day).toISOString().split('T')[0];
      const entry = result[key].find((entry) => new Date(entry.date).toISOString().split('T')[0] === formattedDayTimestamp);
      if (entry) {
        entry.value += Number(item.doccount);
      }
    });

    const totals = Object.keys(result).map((key) => {
      const totalValue = result[key].reduce((acc, entry) => acc + entry.value, 0);
      return { key, totalValue };
    });

    totals.sort((a, b) => b.totalValue - a.totalValue);

    const sortedResult = {};
    totals.forEach((item) => {
      sortedResult[item.key] = result[item.key];
    });

    return sortedResult;
  };

  return (
    <div>
      <div className="h-100 d-flex flex-column" style={{ backgroundColor: '#fafafa' }}>

        <Header />
        <div className="d-flex" style={{ minHeight: '100vh' }}>
          <SidebarStats />
          <div
            style={{ width: '100%' }}
          >
            <div className="mx-5 d-flex align-items-baseline">
              <h2 className="mb-4 me-auto">
                {t('Stats.Menu.documents')}
              </h2>
              <div className="ms-auto" style={{ width: '60%' }}>
                <SearchForm
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  setSearchTerm={setSearchTerm}
                  enterprises={enterprises}
                  resetEnterprises={handleReset}
                  handleClick={handleSetEnterprise}
                  onPeriodChange={handleSelectPeriod}
                  onSearch={searchData}
                />
              </div>
            </div>
            {loading ? (
              <div className="d-flex justify-content-center my-5">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <div>
                {showChart && (
                <Charts
                  dataChart={dataChart.slice(0, 15)}
                  dataPieChart={dataPieChart}
                  bigPieChart
                />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentStats;
