/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useRef, useContext, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { SurveyContext } from '../../contexts/survey.context';
import pen from '../../assets/icon_modif.svg';
import check from '../../assets/diskette.svg';

export const ItemTypes = {
  Question: 'question',
  Page: 'page',
};

const DraggableQuestion = ({
  id,
  text,
  index,
  onHover,
  page,
  onClick,
  onDelete,
  position,
  handleTitle,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const {
    survey,
  } = useContext(SurveyContext);

  const [title, setTitle] = useState('');

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.Question,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item) => {
      if (!ref.current) {
        return;
      }
      onHover(index);
      // eslint-disable-next-line no-param-reassign
      item.index = index;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: page ? ItemTypes.Page : ItemTypes.Question,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleClick = () => {
    if (!page) {
      onClick(id);
    }
  };

  const handleInputChange = (e) => {
    setTitle(e.target.value);
  };

  const addTitle = (index) => {
    if (title) {
      handleTitle({ position: index, value: title });
      setTitle('');
    }
  };

  const getValue = (index) => survey?.pageTitles?.find((t) => t.position === index)?.value || '';

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      className={page ? 'page-block' : 'question-block'}
    >
      {
        page && !!position ? (
          <div className="justify-content-between d-flex">
            <span>
              {`${t('DraggableQuestion.page', 'Page')} ${position} :`}
            </span>
            <input
              type="text"
              value={title || getValue(position - 1)}
              onChange={handleInputChange}
              className="question-title__input question-title"
              disabled={getValue(position - 1) && !title}
            />
            {
              getValue(position - 1) && !title ? (
                <button
                  type="button"
                  className="question-title__button question-title"
                  onClick={() => setTitle(getValue(position - 1))}
                >
                  <img src={pen} alt="modify" />
                </button>
              ) : (
                <button
                  type="submit"
                  className="question-title__button question-title"
                  onClick={() => addTitle(position - 1)}
                >
                  <img src={check} alt="save" className="question-title__save" />
                </button>
              )
            }
          </div>
        ) : (
          <div className="d-flex justify-content-between align-items-center question">
            <div className="flex-1" onClick={handleClick}>
              {text}
            </div>
            <div onClick={() => onDelete(id)} className="question-block__trash">
              <FontAwesomeIcon
                icon={faTrash}
                className="cursor-pointer"
              />
            </div>
          </div>
        )
      }
    </div>
  );
};

DraggableQuestion.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  index: PropTypes.number.isRequired,
  page: PropTypes.bool,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  onDelete: PropTypes.func,
  position: PropTypes.number,
  handleTitle: PropTypes.func,
};

DraggableQuestion.defaultProps = {
  page: false,
  onClick: () => { },
  onHover: () => { },
  onDelete: () => { },
  text: '',
  id: '',
  position: null,
  handleTitle: () => {},
};

export default DraggableQuestion;
