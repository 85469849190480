import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip/Tooltip';
import {
  getRGBValues,
  hexToRgb,
  isRGB,
  randString,
} from '../../helpers/utils';

/**
 * ActionButton is a button who allows to do an action depending of his type (ex: submit)
 * and his function OnClick..
 * @version 1
 * @since 7/10/2021
 */

const ActionButton = ({
  onClick,
  children,
  type,
  icon,
  disabled,
  buttonClassName = '',
  tooltip,
  backgroundColor,
  color,
}) => {
  const id = useMemo(() => randString(), []);
  const disabledBackground = useMemo(() => {
    let rgb;
    if (!backgroundColor) {
      rgb = [255, 255, 255];
    } else {
      rgb = !isRGB(backgroundColor)
        ? hexToRgb(backgroundColor) : getRGBValues(backgroundColor);
    }
    return `rgba(${rgb.join(',')}, 0.5)`;
  }, [backgroundColor]);

  return (
    <>
      <button
        className={`action-button ${buttonClassName} ${disabled ? 'action-button--disabled' : ''}`}
        // eslint-disable-next-line
        type={type}
        onClick={onClick}
        disabled={disabled}
        data-tip="react-tooltip"
        data-for={`tooltip-${id}`}
        style={{
          backgroundColor: disabled ? disabledBackground : backgroundColor,
          color,
        }}
      >
        {icon && <img src={icon} alt="icon" className="icon-button" />}
        {children}
      </button>
      {
        !!tooltip && (
          <Tooltip
            id={`tooltip-${id}`}
          >
            {tooltip}
          </Tooltip>
        )
      }
    </>
  );
};

ActionButton.propTypes = {
  /** Function used on button click */
  onClick: PropTypes.func,
  /** Text inside the button */
  children: PropTypes.node,
  /** Type of button (submit, button...) */
  type: PropTypes.string,
  buttonClassName: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  tooltip: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

ActionButton.defaultProps = {
  children: '',
  type: 'button',
  onClick: () => { },
  disabled: false,
  buttonClassName: '',
  icon: '',
  tooltip: '',
  backgroundColor: '#ffffff',
  color: '#272928',
};

export default ActionButton;
