import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import AWSelect from '../../components/AWSelect/AWSelect';
import ActionButton from '../../components/ActionButton/ActionButton';
import { ConnectionTaskContents, enterpriseInformations } from '../../helpers/constants';
import { ConnectionConfigContext } from '../../contexts/connectionConfig.context';

const Task = ({
  task,
  buildConnectionConfig,
}) => {
  const { t } = useTranslation();
  const {
    members,
    customTaskContents,
    surveys,
    documentTypes,
    customFields,
    setConnectionConfig,
    isConnectionConfigSketch,
  } = useContext(ConnectionConfigContext);

  const [currentTask, setCurrentTask] = useState(task);

  useEffect(() => {
    setCurrentTask(task);
  }, [task]);

  const getDefaultCustomerData = (connectionTaskContent) => {
    switch (connectionTaskContent) {
      case ConnectionTaskContents.CustomField:
        return customFields.length ? customFields[0].value : '';
      case ConnectionTaskContents.DocumentType:
        return documentTypes.length ? documentTypes[0].value : '';
      case ConnectionTaskContents.Form:
        return surveys.length ? surveys[0].value : '';
      default:
        return undefined;
    }
  };

  const handleOwnersChange = (options) => {
    setCurrentTask({
      ...currentTask,
      owners: options.map((v) => ({ user_id: v.value })),
    });
  };

  const handleDataChange = (options) => {
    const data = Array.isArray(options)
      ? options.map((v) => v.value).join(';')
      : options.value;
    setCurrentTask({
      ...currentTask,
      data,
    });
  };

  const handleContentChange = (option) => {
    setCurrentTask({
      ...currentTask,
      content: option.value,
      data: getDefaultCustomerData(option.value),
    });
  };

  const getTaskDetails = () => {
    if (currentTask) {
      switch (currentTask.content) {
        case ConnectionTaskContents.Form:
          return ({
            title: t('ConnectionConfig.survey', 'Questionnaire'),
            items: surveys,
          });
        case ConnectionTaskContents.Fields:
          return ({
            title: t('ConnectionConfig.enterpriseInformation', "Informations de l'entreprise"),
            items: enterpriseInformations,
            option: 'isMulti',
          });
        case ConnectionTaskContents.DocumentType:
          return ({
            title: t('ConnectionConfig.documentType', 'Type de document'),
            items: documentTypes,
            option: 'noLimit',
          });
        case ConnectionTaskContents.CustomField:
          return ({
            title: t('Task.customField', 'Champ personnalisé'),
            items: customFields,
            option: 'noLimit',
          });
        default:
          return '';
      }
    }
    return '';
  };

  const buildTaskDetail = () => {
    const taskDetail = getTaskDetails();
    if (taskDetail.option === 'isMulti') {
      const taskItems = currentTask.data?.split(';') || [];
      return (
        <Form.Group className="w-75 mx-2">
          <Form.Label className="label mb-3" htmlFor="data">
            {taskDetail.title}
          </Form.Label>
          <AWSelect
            isMulti
            noNullOption
            isDisabled={!isConnectionConfigSketch}
            onChange={handleDataChange}
            options={taskDetail.items}
            value={taskDetail.items.filter((i) => taskItems.includes(i.value))}
            className="mt-2"
          />
        </Form.Group>
      );
    }
    if (taskDetail.title) {
      return (
        <Form.Group className="w-75 mx-2">
          <Form.Label className="label" htmlFor="data">
            {taskDetail.title}
          </Form.Label>
          <DropdownMenu
            isDisabled={!isConnectionConfigSketch}
            onChange={handleDataChange}
            items={taskDetail.items}
            value={
              taskDetail.items.find((i) => i.value === currentTask.data) || taskDetail.items[0]
            }
            noLimit={taskDetail.option === 'noLimit'}
          />
        </Form.Group>
      );
    }
    return <> </>;
  };

  const addTask = () => {
    const clone = buildConnectionConfig();
    const tmpTask = currentTask;
    const _step = clone.steps.find((s) => s.step === tmpTask.stepNumber);
    tmpTask.position = _step.tasks.length + 1;
    delete tmpTask.display;
    const _task = _step.tasks.find((t) => t.id === tmpTask.id);
    if (_task) {
      _step.tasks = _step.tasks.map((t) => (t.id === tmpTask.id ? tmpTask : t));
    } else {
      _step.tasks.push(tmpTask);
    }
    setConnectionConfig(clone);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addTask(currentTask);
  };

  return (
    <Form className="connection-config__task" onSubmit={handleSubmit}>
      <div className="d-flex">
        <div>
          <Form.Group>
            <Form.Label className="label" htmlFor="content">
              {t('ConnectionConfig.taskType', 'Type de tâche')}
            </Form.Label>
            <DropdownMenu
              noLimit
              isDisabled={!isConnectionConfigSketch}
              onChange={handleContentChange}
              items={customTaskContents}
              value={customTaskContents.find((ct) => ct.value === currentTask.content)}
            />
          </Form.Group>
        </div>
        <div className="flex-1">
          {buildTaskDetail(currentTask)}
        </div>
      </div>
      <Form.Group>
        <Form.Label className="label" htmlFor="owners">
          {t('ConnectionConfig.enterpriseOwners', 'Utilisateur(s) autorisé(s)')}
        </Form.Label>
        <AWSelect
          id="owners"
          isMulti
          noNullOption
          options={members}
          isDisabled={!members.length || !isConnectionConfigSketch}
          noOptionsMessage={() => (
            t('ConnectionConfig.enterpriseAnyOwner', 'Aucun utilisateur')
          )}
          onChange={handleOwnersChange}
          value={currentTask.owners
            ? members.filter((m) => currentTask.owners.some((o) => o.user_id === m.value))
            : []}
          placeholder=""
        />
      </Form.Group>
      {
        isConnectionConfigSketch ? (
          <ActionButton
            type="submit"
            backgroundColor="#000000"
            color="#ffffff"
            buttonClassName="mt-3 d-flex justify-content-center ms-auto"
          >
            {t('ConnectionConfig.validateTask', 'Valider')}
          </ActionButton>
        ) : <> </>
      }
    </Form>
  );
};

Task.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string,
    content: PropTypes.string,
    stepNumber: PropTypes.number,
    owners: PropTypes.arrayOf(PropTypes.shape()),
    data: PropTypes.string,
  }).isRequired,
  buildConnectionConfig: PropTypes.func.isRequired,
};

export default Task;
