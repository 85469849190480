import React, {
  useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getSignedURL } from '../../services/file.service';
import { search } from '../../services/document.service';

const ItemValue = ({
  value: _value, field: _field, itemId, showPreview,
}) => {
  const { i18n, t } = useTranslation();
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);

  const FieldTypes = {
    String: 'string',
    Text: 'text',
    Date: 'date',
    Number: 'number',
    Amount: 'amount',
    File: 'file',
    Image: 'image',
    Proposals: 'proposals',
    Email: 'email',
    Choice: 'choice',
    Boolean: 'boolean',
    Partner: 'partner',
    Contract: 'contract',
    DocumentType: 'document-type',
  };

  async function getFileIdFromDocument(value, field) {
    const response = await search('documents', {
      columns: [
        'status',
        'rejection_comment',
        {
          fields: [
            'files.id',
            'files.mime_type',
          ],
          aggregation: 'selectSubObject',
        },
      ],
      search: {
        collection_item_id: itemId,
        type_id: field.properties.elementSystemId,
      },
    }, {});
    if (response.success) {
      return {
        value: response.data[0]?.files?.[0].id,
        text: t(`DocumentInfo.Status.${response.data[0]?.status}`),
      };
    }
    return {
      value: '',
      text: '',
    };
  }

  async function getPreviewHtml(value, field, getFileId = () => ({ value, text: undefined })) {
    const file = await getFileId(value, field);
    if (!file?.value) return console.error('Unable to load fileId from field:', field, 'value:', value);
    return (
      <div>
        <Button
          variant="outline-secondary"
          style={{ borderColor: 'transparent' }}
          onClick={async () => {
            const signedUrlResponse = await getSignedURL(file?.value);
            if (signedUrlResponse.success) {
              const { signedUrl } = signedUrlResponse.data;
              showPreview({ name: field.label.fr, signedUrl });
            }
            return undefined;
          }}
        >
          <FontAwesomeIcon
            icon={faEye}
            className={`pointer ${file?.text ? 'me-2' : ''}`}
          />
          {file?.text}
        </Button>
      </div>
    );
  }
  function getLabel(_i18n, label) {
    if (typeof label === 'string') return label;
    return label ? (
      label[_i18n.language.split('-')[0]] || label.en || label.fr || ''
    ) : '';
  }
  const modifiers = {
    [FieldTypes.Date]: (dateStr) => {
      if (dateStr) return dayjs(dateStr).locale(i18n.language).format('DD/MM/YYYY');
      return '';
    },
    [FieldTypes.Boolean]: (bool) => {
      if (typeof bool !== 'boolean') return bool;
      return bool ? 'Oui' : 'Non';
    },
    [FieldTypes.File]: (value, field) => (
      getPreviewHtml(value, field)
    ),
    [FieldTypes.Image]: (value, field) => (
      getPreviewHtml(value, field)
    ),
    [FieldTypes.DocumentType]: (value, field) => (
      getPreviewHtml(value, field, getFileIdFromDocument)
    ),
    [FieldTypes.Choice]: (value, field) => (
      getLabel(i18n, field?.properties?.values?.find((a) => a.value === value)?.label) || value
    ),
  };

  async function formatValue(collectionValue, field) {
    const value = collectionValue?.value?.data !== undefined
      ? collectionValue?.value?.data
      : collectionValue?.value;
    return modifiers[field.properties.type]
      ? modifiers[field.properties.type](value, field)
      : value;
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        setValue(await formatValue(_value, _field));
      } finally {
        setLoading(false);
      }
    })();
  }, [_value, _field]);
  return loading
    ? (<td><Spinner size="sm" animation="border" variant="secondary" /></td>)
    : (<td>{value}</td>);
};

ItemValue.propTypes = {
  value: PropTypes.shape().isRequired,
  field: PropTypes.shape({
    properties: PropTypes.shape({
      elementSystemId: PropTypes.string,
      label: PropTypes.shape({
        fr: PropTypes.string,
      }),
    }),
  }).isRequired,
  itemId: PropTypes.string.isRequired,
  showPreview: PropTypes.func.isRequired,
};

export default ItemValue;
