import React, {
  createContext,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

export const SurveyContext = createContext();

const SurveyContextProvider = ({ children }) => {
  const [question, setQuestionOnly] = useState(null);
  const [survey, setSurvey] = useState({});

  const setQuestion = (partialQuestion) => {
    setQuestionOnly({ ...(question || {}), ...partialQuestion });
  };

  const reset = () => {
    setQuestionOnly(null);
  };

  const value = useMemo(() => ({
    setQuestion,
    setSurvey,
    reset,
    question,
    survey,
  }), [question, survey]);

  return (
    <SurveyContext.Provider value={value}>
      {children}
    </SurveyContext.Provider>
  );
};

SurveyContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SurveyContextProvider;
