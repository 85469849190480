import React from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import chevron from '../../assets/chevron-left.svg';

import '../../styles/css/style.css';

/**
 * Return is a button who allows to go to previous page.
 * @version 1
 * @since 8/10/2021
 */

const ReturnButton = ({
  previousPagePath, children, callback, centeredChevron,
}) => {
  // Change page on click
  const history = useHistory();
  const changePage = async () => {
    await callback();
    history.push(previousPagePath);
  };

  return (
    <div className="d-flex ">
      <button
        type="button"
        onClick={() => changePage()}
        className={centeredChevron ? 'return-button centered-chevron' : 'return-button'}
      >
        <div className={centeredChevron ? 'button-wrap' : ''}>
          <img src={chevron} alt="chevron left" className={centeredChevron ? 'button-icon' : ''} />
          <div className="pl-2 d-inline-block return-button__text">
            {children}
          </div>
        </div>
      </button>
    </div>
  );
};

ReturnButton.propTypes = {
  /** Precise landing page path when clicking on return button */
  previousPagePath: PropTypes.string,
  children: PropTypes.string,
  callback: PropTypes.func,
  centeredChevron: PropTypes.bool,
};

ReturnButton.defaultProps = {
  previousPagePath: '/dashboard',
  children: '',
  callback: () => { },
  centeredChevron: false,
};

export default ReturnButton;
