import React from 'react';
import PropTypes from 'prop-types';
import {
  Image, OverlayTrigger, Tooltip,
} from 'react-bootstrap';

const UserTokenCircle = ({ marginleft, email }) => (
  <span className={`editing-badge ${email?.length ? '' : 'hidden'} ${marginleft ? 'margin-left-badge' : ''}`}>
    <OverlayTrigger
      placement="bottom"
      className="justify-content-md-center"
      overlay={(
        <Tooltip className="tooltip-name">
          {email}
        </Tooltip>
      )}
    >
      <Image
        roundedCircle
        src={`https://avatars.dicebear.com/api/initials/${
          email
        }.svg?r=50&fontSize=45`}
        alt="profile"
        className="profile-picture fa-stack-1x"
      />
    </OverlayTrigger>
  </span>

);

UserTokenCircle.defaultProps = {
  email: '',
  marginleft: false,
};

UserTokenCircle.propTypes = {
  email: PropTypes.string,
  marginleft: PropTypes.bool,
};

export default UserTokenCircle;
