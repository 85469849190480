import React from 'react';
import PropTypes from 'prop-types';
import { Container, Form, Spinner } from 'react-bootstrap';

const AWForm = ({
  loading,
  onSubmit,
  children,
  width,
}) => (
  <Container className="pb-4 d-flex justify-content-center aw-form-container">
    <Form className="px-5 rounded" onSubmit={onSubmit} style={{ width }}>
      {
        loading ? (
          <div className="h-90-vh d-flex justify-content-center align-items-center ">
            <Spinner animation="border" />
          </div>
        ) : (
          children
        )
      }
    </Form>
  </Container>
);

AWForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  width: PropTypes.string,
};

AWForm.defaultProps = {
  loading: false,
  onSubmit: () => { },
  children: '',
  width: '75%',
};

export default AWForm;
