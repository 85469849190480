import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Col,
  Row,
  Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FileContext } from '../../contexts/file.context';
import useLoadingPromise from '../../hooks/useLoadingPromise';
import List from '../List/List';
import AWLink from '../AWLink/AWLink';

const EnterpriseIbans = ({
  show,
  onClose,
  size,
  ibans,
}) => {
  const { t } = useTranslation();
  const { file, getDocumentFile } = useContext(FileContext);
  const [fileId, setFileId] = useState();
  const { waitWithLoad, isLoading } = useLoadingPromise();

  const handleClick = (_fileId) => () => {
    setFileId(_fileId);
    waitWithLoad(getDocumentFile(_fileId));
  };

  const columns = [
    {
      key: 'iban',
      label: t('Enterprise.Iban.iban'),
      width: 15,
    },
    {
      key: 'bic',
      label: t('Enterprise.Iban.bic'),
    },
    {
      key: 'status',
      label: t('Enterprise.Iban.status'),
    },
    {
      key: 'label',
      label: t('Enterprise.Iban.label'),
    },
    {
      key: 'created_at',
      label: t('Enterprise.Iban.created_at'),
      isDate: true,
    },
    {
      key: 'updated_at',
      label: t('Enterprise.Iban.updated_at'),
      isDate: true,
    },
    {
      key: 'deleted_at',
      label: t('Enterprise.Iban.deleted_at'),
      isDate: true,
    },
    {
      key: 'file_id',
      label: t('Enterprise.Iban.file_id'),
      render: (item) => (
        item.file_id ? (
          <AWLink
            onClick={handleClick(item?.file_id)}
          >
            {isLoading && item?.file_id && item.file_id === fileId ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" size="sm" color="black" />
              </div>
            ) : t('Enterprise.Iban.viewFile')}
          </AWLink>
        ) : '-'
      ),
    },
  ];

  useEffect(() => {
    if (file.url) {
      window.open(file.url, '_blank');
    }
  }, [file.url]);

  return (
    <Modal show={show} onHide={onClose} size={size} centered className="enterprise-ibans">
      <Modal.Header closeButton>
        <Modal.Title>{t('Enterprise.Iban.history')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs md="12">
            {
              ibans?.length > 0
                ? (
                  <List
                    columns={columns}
                    data={ibans}
                  />
                )
                : (
                  <h2>{t('Enterprise.Iban.noHistory')}</h2>
                )
            }
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

EnterpriseIbans.defaultProps = {
  size: 'xl',
  ibans: [],
};

EnterpriseIbans.propTypes = {
  ibans: PropTypes.arrayOf(PropTypes.shape()),
  size: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EnterpriseIbans;
