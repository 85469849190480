import React, { useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

import '../../styles/css/style.css';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo_icon.svg';
import { UserContext } from '../../contexts/user.context';

import ActionButton from '../../components/ActionButton/ActionButton';
import { currentSession } from '../../helpers/session';

const Login = React.memo(() => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (user) {
      (async () => {
        const session = await currentSession();
        if (session.isLogged) {
          setUser(session.user);
          history.push('/dashboard');
        }
      })();
    }
  }, []);

  const auth = async () => {
    Auth.federatedSignIn({
      provider: 'Google',
    });
  };

  return (
    <div className="container-fluid postion-relative">
      <div className="row">
        <div className="col-4 offset-4 text-center center-container">
          <img src={logo} alt="logo addworking" className="logo-welcome" />
          <div className="welcome-message">
            {t('Login.welcome', 'Welcome to Addworking Backoffice')}
          </div>

          <ActionButton
            onClick={auth}
            className="login-button"
          >
            {t('Login.login', 'Se connecter avec Google')}
          </ActionButton>
        </div>
      </div>
    </div>
  );
});

export default Login;
