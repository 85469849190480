import { safeFetch } from '../helpers/response-handler';
import { queryBoySearch } from '../helpers/utils';

const { REACT_APP_URL_COMPANY_API } = process.env;

export const getSurveys = (options) => safeFetch(
  `${REACT_APP_URL_COMPANY_API}/v1/company/support/surveys${queryBoySearch(options)}`,
);

export const getSurvey = (identifier) => safeFetch(
  `${REACT_APP_URL_COMPANY_API}/v1/company/support/surveys/${identifier}`,
);

export const createSurvey = (body) => safeFetch(
  `${REACT_APP_URL_COMPANY_API}/v1/company/support/surveys`,
  {
    method: 'POST',
    body: JSON.stringify(body),
  },
);

export const updateSurvey = (id, body) => safeFetch(
  `${REACT_APP_URL_COMPANY_API}/v1/company/support/surveys/${id}`,
  {
    method: 'PATCH',
    body: JSON.stringify(body),
  },
);

export const launchSurvey = (id) => safeFetch(
  `${REACT_APP_URL_COMPANY_API}/v1/company/support/surveys/${id}/launch`,
  {
    method: 'PATCH',
  },
);

export const stopSurvey = (id) => safeFetch(
  `${REACT_APP_URL_COMPANY_API}/v1/company/support/surveys/${id}/stop`,
  {
    method: 'PATCH',
  },
);

export const updateQuestionVisible = (surveyId, questions) => safeFetch(
  `${REACT_APP_URL_COMPANY_API}/v1/company/support/surveys/${surveyId}/questions/visible`,
  {
    method: 'PATCH',
    body: JSON.stringify(questions),
  },
);

export const deleteQuestion = (surveyId, key) => safeFetch(
  `${REACT_APP_URL_COMPANY_API}/v1/company/support/surveys/${surveyId}/questions/${key}`,
  {
    method: 'DELETE',
  },
);
