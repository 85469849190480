import {
  safeFetch,
} from '../helpers/response-handler';
import { queryToString } from '../helpers/utils';

const {
  REACT_APP_URL_INVOICE_API,
} = process.env;

const baseUrl = `${REACT_APP_URL_INVOICE_API}/v1/invoice/support`;

export const getInvoice = (id) => safeFetch(
  `${baseUrl}/invoices/${id}`,
);

export const getFees = (invoiceId, query) => safeFetch(
  `${baseUrl}/invoices/${invoiceId}/fees/${queryToString(query)}`,
);

export const resetAmountFees = (invoiceId) => safeFetch(
  `${baseUrl}/invoices/${invoiceId}/reset-amount`,
  {
    method: 'PATCH',
  },
);
