import React from 'react';
import {
  Sidebar,
  Menu,
  MenuItem,
} from 'react-pro-sidebar';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';

const SidebarStats = () => {
  const { t } = useTranslation();
  const redirectOnClick = (path) => {
    window.location.href = path;
  };

  return (
    <Sidebar
      collapsed
      defaultCollapsed
      backgroundColor="#272928"
      style={{ minHeight: '100vh' }}
    >
      <Menu
        style={{ marginTop: '50px' }}
      >

        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id="tooltip-line-chart">{t('General.contracts')}</Tooltip>}
        >
          <MenuItem
            onClick={() => redirectOnClick('/stats')}
          >
            <FontAwesomeIcon
              className="ml-10px"
              icon={faChartLine}
              color="#ffffff"
            />
          </MenuItem>
        </OverlayTrigger>

        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id="tooltip-line-chart">{t('General.documents')}</Tooltip>}
        >
          <MenuItem
            onClick={() => redirectOnClick('/stats/docs')}
          >
            <FontAwesomeIcon
              className="ml-10px"
              icon={faChartLine}
              color="#ffffff"
            />
          </MenuItem>
        </OverlayTrigger>

        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id="tooltip-line-chart">{t('General.iaDocuments')}</Tooltip>}
        >
          <MenuItem
            onClick={() => redirectOnClick('/stats/docs/ia')}
          >
            <FontAwesomeIcon
              className="ml-10px"
              icon={faChartLine}
              color="#ffffff"
            />
          </MenuItem>
        </OverlayTrigger>
      </Menu>
    </Sidebar>
  );
};

export default SidebarStats;
