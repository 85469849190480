import React, { useContext, useEffect, useState } from 'react';
import {
  Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as DocumentService from '../../services/document.service';
import Header from '../../components/Header/Header';
import {
  BE_IDENTIFICATION_NUMBER_REGEX,
  DE_IDENTIFICATION_NUMBER_REGEX,
  FR_IDENTIFICATION_NUMBER_REGEX,
  UUID_REGEX,
} from '../../helpers/patterns';
import { getEnterprise, getEnterprises } from '../../services/enterprise.service';
import { NotFound } from '../../helpers/catalog.errors';
import { AlertContext } from '../../contexts/alert.context';
import SearchForm from '../../components/Stats/SearchForm';
import TableStats from '../../components/Stats/TableStats';
import Charts from '../../components/Stats/Charts';
import SidebarStats from '../../components/Stats/SidebarStats';
/* eslint-disable camelcase */
const DocumentStatsIA = () => {
  const today = new Date();
  const thirtyDaysBefore = new Date();
  today.setDate(today.getDate());
  thirtyDaysBefore.setDate(today.getDate() - 30);
  const formatDate = (date) => date.toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(formatDate(thirtyDaysBefore));
  const [endDate, setEndDate] = useState(formatDate(today));
  const [dataChart, setDataChart] = useState([]);
  const [dataPieChart, setDataPieChart] = useState([]);
  const [dataStats, setDataStats] = useState([]);
  const [enterprises, setEnterprises] = useState([]);
  const [enterprise, setEnterprise] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [showChart, setShowChart] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { setNotif } = useContext(AlertContext);

  const queryboyOptions = {
    fields: [
      'id',
      'name',
      'identification_number',
      'is_vendor',
      'is_customer',
      'created_at',
      'parent_id',
      'deleted_at',
      'is_headquarter',
      'no_payment_option',
    ],
    disableDefaultFilters: true,
    page_size: 100,
  };

  const handleSelectPeriod = async (start, end) => {
    setLoading(true);
    setStartDate(formatDate(start));
    setEndDate(formatDate(end));
    await searchData();
  };

  useEffect(async () => {
    setLoading(true);
    await searchData();
  }, []);

  useEffect(async () => {
    setShowChart(true);
  }, [dataChart]);

  useEffect(() => {
    if (searchTerm) {
      fetchEnterprises(searchTerm);
    }
  }, [searchTerm]);

  const fetchEnterprises = async (searchQuery) => {
    const matchings = [
      UUID_REGEX,
      FR_IDENTIFICATION_NUMBER_REGEX,
      DE_IDENTIFICATION_NUMBER_REGEX,
      BE_IDENTIFICATION_NUMBER_REGEX,
    ];
    let needSearch = false;
    if (matchings.some((m) => searchQuery.match(m))) {
      const res = await getEnterprise(searchQuery, queryboyOptions);
      setEnterprises([res.data]);
      if (res.hasError(NotFound)) needSearch = true;
    } else {
      needSearch = true;
    }
    if (needSearch) {
      const res = await getEnterprises(
        {
          ...queryboyOptions,
          search: { name: { $sw: searchQuery.toUpperCase().trim().replace(/\s/g, '_') } },
        },
      );
      if (res.success) {
        setEnterprises(res.data);
      } else {
        setEnterprises([]);
      }
    }
  };

  const handleSetEnterprise = (enterprise) => {
    setEnterprise(enterprise);
  };

  const handleReset = () => {
    setEnterprise(undefined);
  };

  const aggregateDataByDay = (data) => {
    const aggregatedData = {};

    data.forEach((doc) => {
      const { name, dtdisplayname } = doc;
      if (!aggregatedData[name]) {
        aggregatedData[name] = {
          name: dtdisplayname ? JSON.parse(dtdisplayname).fr : name,
          processedbyia: Number(doc.processedbyia),
          processedbyhuman: Number(doc.processedbyhuman),
          notprocessedyet: Number(doc.notprocessedyet),
        };
      }
    });

    return Object.values(aggregatedData);
  };

  const searchData = async () => {
    setLoading(true);

    const responseStats = await DocumentService.getStatsOnDocumentTypes(
      {
        onlyMachine: true,
        regroupDocName: false,
      },
      {
        search: {
          enterpriseId: enterprise ? enterprise.id : undefined,
          updated_at: { $gte: startDate, $lte: endDate },
        },
      },
    );

    if (responseStats.success) {
      const groupedData = {
        processedByIA: {},
        processedByHuman: {},
        notProcessedYet: {},
      };

      Object.values(responseStats.data).forEach((item) => {
        const formattedDayTimestamp = new Date(item.day).getTime();

        if (!groupedData.processedByIA[formattedDayTimestamp]) {
          groupedData.processedByIA[formattedDayTimestamp] = 0;
          groupedData.processedByHuman[formattedDayTimestamp] = 0;
          groupedData.notProcessedYet[formattedDayTimestamp] = 0;
        }

        groupedData.processedByIA[formattedDayTimestamp] += Number(item.processedbyia);
        groupedData.processedByHuman[formattedDayTimestamp] += Number(item.processedbyhuman);
        groupedData.notProcessedYet[formattedDayTimestamp] += Number(item.notprocessedyet);
      });

      const datas = [
        {
          name: t('DocumentInfo.Stats.processedByIA'),
          data: Object.keys(groupedData.processedByIA).map((date) => ({
            date: Number(date),
            value: groupedData.processedByIA[date],
          })),
        },
        {
          name: t('DocumentInfo.Stats.processedByHuman'),
          data: Object.keys(groupedData.processedByHuman).map((date) => ({
            date: Number(date),
            value: groupedData.processedByHuman[date],
          })),
        },
        {
          name: t('DocumentInfo.Stats.notProcessedYet'),
          data: Object.keys(groupedData.notProcessedYet).map((date) => ({
            date: Number(date),
            value: groupedData.notProcessedYet[date],
          })),
        },
      ];

      setDataChart(datas);

      const pieData = [
        { display_name: t('DocumentInfo.Stats.processedByIA'), value: datas[0].data.reduce((sum, item) => sum + item.value, 0) },
        { display_name: t('DocumentInfo.Stats.processedByHuman'), value: datas[1].data.reduce((sum, item) => sum + item.value, 0) },
        { display_name: t('DocumentInfo.Stats.notProcessedYet'), value: datas[2].data.reduce((sum, item) => sum + item.value, 0) },
      ];

      const sortByValue = pieData.sort((a, b) => b.value - a.value);

      setDataPieChart(sortByValue);

      const responseTable = await DocumentService.getStatsOnDocumentTypes(
        {
          onlyMachine: true,
          regroupDocName: true,
        },
        {
          search: {
            enterpriseId: enterprise ? enterprise.id : undefined,
            updated_at: { $gte: startDate, $lte: endDate },
          },
        },
      );

      const result = responseTable.data.map((item) => {
        if (item.value !== 0) {
          return {
            ...item,
            name: item.name ? item.name : t('ContractState.unknown'),
          };
        }
        return null;
      });

      setLoading(false);
      setDataStats(aggregateDataByDay(result));
    } else {
      setNotif({
        type: 'error',
        title: t('Common.Error'),
        message: t('Stats.Errors.noData'),
      });
    }
    setLoading(false);
  };

  const generateId = () => Math.random().toString(36).substring(7);

  const colums = [
    { header: t('DocumentInfo.Stats.libelle'), accessor: 'name' },
    { header: t('DocumentInfo.Stats.docNotThreatedYet'), accessor: 'notprocessedyet' },
    { header: t('DocumentInfo.Stats.humanDocs'), accessor: 'processedbyhuman' },
    { header: t('DocumentInfo.Stats.iaDocs'), accessor: 'processedbyia' },
    { header: t('DocumentInfo.Stats.repartition'), accessor: 'repartition' },
  ];
  return (
    <div>
      <div className="h-100 d-flex flex-column" style={{ backgroundColor: '#fafafa' }}>

        <Header />
        <div className="d-flex" style={{ minHeight: '100vh' }}>
          <SidebarStats />
          <div
            style={{ width: '100%' }}
          >
            <div className="mx-5 d-flex align-items-baseline">
              <h2 className="mb-4 me-auto">
                {t('Stats.Menu.iaDocuments')}
              </h2>
              <div className="ms-auto">
                <SearchForm
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  setSearchTerm={setSearchTerm}
                  enterprises={enterprises}
                  resetEnterprises={handleReset}
                  handleClick={handleSetEnterprise}
                  onPeriodChange={handleSelectPeriod}
                  onSearch={searchData}
                  hideElements={['searchInput']}
                />
              </div>
            </div>
            {loading ? (
              <div className="d-flex justify-content-center my-5">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <>
                {showChart && (
                  <Charts
                    dataChart={dataChart}
                    dataPieChart={dataPieChart}
                    bigPieChart
                  />
                )}

                <div className="mx-5 my-3 bg-white border px-2">
                  <div className="d-flex flex-column align-items-center mt-5 mb-3">
                    <div className="w-100 mb-3">
                      <TableStats
                        columns={colums}
                        data={dataStats}
                        generateId={generateId}
                        itemsPerPage={25}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentStatsIA;
