import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '../helpers/ProtectedRoute';
import Groups from '../views/Groups/Groups';

const GroupsRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <ProtectedRoute path={path} exact>
        <Groups />
      </ProtectedRoute>
    </Switch>
  );
};

export default GroupsRoutes;
