import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import ContractsChart from '../../components/ContractsChart/ContractsChart';
import { findContracts, getContractsCount } from '../../services/contracts.service';
import { getEnterprise, getEnterprises } from '../../services/enterprise.service';
import AMPieChart from '../../components/AMPieChart/AMPieChart';
import {
  BE_IDENTIFICATION_NUMBER_REGEX,
  DE_IDENTIFICATION_NUMBER_REGEX,
  FR_IDENTIFICATION_NUMBER_REGEX,
  UUID_REGEX,
} from '../../helpers/patterns';
import { NotFound } from '../../helpers/catalog.errors';
import SidebarStats from '../../components/Stats/SidebarStats';
import SearchForm from '../../components/Stats/SearchForm';
import TableStats from '../../components/Stats/TableStats';

const Stats = () => {
  const { t } = useTranslation();
  const [dataChart, setDataChart] = useState([]);
  const [dataPieChart, setDataPieChart] = useState([]);
  const [dataStatePieChart, setStateDataPieChart] = useState([]);
  const [dataStatsTable, setDataStatsTable] = useState([]);
  const [enterprises, setEnterprises] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const today = new Date();
  const thirtyDaysBefore = new Date();
  thirtyDaysBefore.setDate(today.getDate() - 30);
  const formatDate = (date) => date.toISOString().split('T')[0];

  const [startDate, setStartDate] = useState(formatDate(thirtyDaysBefore));
  const [endDate, setEndDate] = useState(formatDate(today));
  const [enterpriseForContractsChart, setEnterpriseForContractsChart] = useState(undefined);

  useEffect(() => {
    fetchDataForContractsChart();
    fetchDataForTable();
    fetchDataForPieChartState();
  }, []);

  const handleSelectPeriod = (start, end) => {
    setStartDate(formatDate(start));
    setEndDate(formatDate(end));
  };

  const fetchDataForContractsChart = async () => {
    if (startDate && endDate) {
      const response = await getContractsCount({
        $or: {
          'enterprise.id': enterpriseForContractsChart?.id || undefined,
          'enterprise.parent_id': enterpriseForContractsChart?.id || undefined,
        },
        created_at: {
          $gte: startDate,
          $lte: endDate,
        },
      }, 'countByPeriod');
      if (response.success) {
        setDataChart(response.data.map((item) => ({
          date: new Date(item.day).getTime(),
          contracts: parseInt(item.contracts, 10),
        })));
      }
    }
  };

  const fetchDataForTable = async () => {
    const tableResp = await findContracts({
      columns: ['enterprise.name', 'enterprise.id', { id: { $count: 'contractCount' } }],
      search: {
        created_at: { $gte: startDate, $lte: endDate },
      },
    }, {
      groupBy: ['acc.enterprise_id'],
      orderBy: { 'enterprise.name': 'asc' },
      ignoreHydrate: true,
    });

    const { data } = tableResp;
    const sortedByContractsCount = data
      .map((item) => ({
        ...item,
        contracts: Number(item.contractcount),
      }))
      .sort((a, b) => Number(b.contractcount) - Number(a.contractcount));
    setDataStatsTable(sortedByContractsCount);
    const topData = sortedByContractsCount.slice(0, 10);
    const restContracts = data.slice(10)
      .reduce((acc, current) => acc + Number(current.contractcount), 0);
    if (data.length > 10) {
      topData.push({ category: 'Reste', contracts: restContracts });
    }
    setDataPieChart(topData);
  };

  const fetchDataForPieChartState = async () => {
    const stateResp = await findContracts({
      columns: ['global_state', { id: { $count: 'contractCount' } }],
      search: {
        created_at: { $gte: startDate, $lte: endDate },
      },
    }, {
      groupBy: ['global_state'],
      orderBy: { global_state: 'asc' },
      ignoreHydrate: true,
    });

    if (stateResp.success && stateResp.data.length > 0) {
      const data = stateResp.data.map((item) => ({
        category: item.global_state ? item.global_state : 'Non renseigné',
        contracts: item.contractcount,
      }));
      const sortTableByContractCount = data.sort((a, b) => b.contracts - a.contracts);
      setStateDataPieChart(sortTableByContractCount);
    }
  };

  const fetchEnterprises = async (searchQuery) => {
    const matchings = [
      UUID_REGEX,
      FR_IDENTIFICATION_NUMBER_REGEX,
      DE_IDENTIFICATION_NUMBER_REGEX,
      BE_IDENTIFICATION_NUMBER_REGEX,
    ];
    let needSearch = false;
    if (matchings.some((m) => searchQuery.match(m))) {
      const res = await getEnterprise(searchQuery, queryboyOptions);
      setEnterprises([res.data]);
      if (res.hasError(NotFound)) needSearch = true;
    } else {
      needSearch = true;
    }
    if (needSearch) {
      const res = await getEnterprises(
        {
          ...queryboyOptions,
          search: { name: { $sw: searchQuery.toUpperCase().trim().replace(/\s/g, '_') } },
        },
      );
      if (res.success) {
        setEnterprises(res.data);
      } else {
        setEnterprises([]);
      }
    }
  };

  const fetchData = async () => {
    await fetchDataForContractsChart();
    await fetchDataForTable();
    await fetchDataForPieChartState();
  };

  useEffect(() => {
    if (searchTerm) {
      fetchEnterprises(searchTerm);
    }
  }, [searchTerm]);
  const queryboyOptions = {
    fields: [
      'id',
      'name',
      'identification_number',
      'is_vendor',
      'is_customer',
      'created_at',
      'parent_id',
      'deleted_at',
      'is_headquarter',
      'no_payment_option',
    ],
    disableDefaultFilters: true,
    page_size: 100,
  };

  const handleSetEnterprise = (enterprise) => {
    setEnterpriseForContractsChart(enterprise);
    fetchDataForContractsChart();
  };

  const handleReset = () => {
    setEnterprises([]);
    setEnterpriseForContractsChart(undefined);
    setSearchTerm('');
  };

  const columns = [
    {
      header: t('Stats.TableContracts.entrepriseName'),
      accessor: 'enterprise_name',
    },
    {
      header: t('Stats.TableContracts.id'),
      accessor: 'enterprise_id',
    },
    {
      header: t('Stats.TableContracts.nbContracts'),
      accessor: 'contractcount',
    },
  ];
  const generateId = () => Math.random().toString(36).substring(7);

  return (
    <div>
      <div className="h-100 d-flex flex-column">
        <Header />
        <div className="d-flex">
          <SidebarStats />
          <div
            style={{ width: '100%' }}
          >
            <div className="mx-5 d-flex align-items-baseline">
              <h2 className="mb-4 me-auto">
                {t('Stats.Menu.contracts')}
              </h2>
              <div className="ms-auto" style={{ width: '60%' }}>
                <SearchForm
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  setSearchTerm={setSearchTerm}
                  enterprises={enterprises}
                  resetEnterprises={handleReset}
                  handleClick={handleSetEnterprise}
                  onPeriodChange={handleSelectPeriod}
                  onSearch={fetchData}
                />
              </div>
            </div>

            <div className="mx-5 my-3 bg-white border px-2">
              <div className="mx-3">
                <h2>{enterpriseForContractsChart?.name ? enterpriseForContractsChart.name : ''}</h2>
              </div>
              <div className="d-flex flex-column align-items-center mt-5 mb-3">
                <div className="w-100 mb-3 d-flex">
                  <ContractsChart dataChart={dataChart} />
                  <AMPieChart
                    data={dataStatePieChart}
                    valueField="contracts"
                    categoryField="category"
                    bigChart
                  />
                </div>
                {dataPieChart && (
                  <div className="w-100 mt-5">
                    <AMPieChart
                      data={dataPieChart}
                      valueField="contracts"
                      categoryField="enterprise_name"
                      bigChart
                    />
                  </div>
                )}

                <div>
                  <TableStats
                    columns={columns}
                    data={dataStatsTable}
                    generateId={generateId}
                    itemsPerPage={25}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Stats;
