/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../ActionButton/ActionButton';
import checkbox from '../../../assets/checkbox.svg';
import plus from '../../../assets/plus.svg';
import cross from '../../../assets/cross.svg';

const initialItem = {
  type: 'string',
  value: '',
};

const ArrayInput = ({
  onChange,
  items,
  disabled,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!items.length) {
      onChange([{ ...initialItem, key: 1 }, { ...initialItem, key: 2 }]);
    }
  }, []);

  const addItem = () => {
    onChange([...items, { ...initialItem, key: items.length + 1 }]);
  };

  const handleItems = (index) => ({ target: { value } }) => {
    onChange(items.map((el, i) => (
      i === index && value !== ' ' ? { ...el, value } : el
    )));
  };

  const deleteLine = (index) => {
    const itemsCopy = [...items];
    itemsCopy.splice(index, 1);
    onChange(itemsCopy);
  };

  return (
    <div>
      <div>
        <div className="separator mt-4" />
        <Form.Label className="label">
          {t('Input.answer', 'Réponses possibles')}
        </Form.Label>
        {items.map((item, index) => (
          <div className="d-flex">
            <div className="checkbox-icon mb-2">
              <img src={checkbox} alt="" />
            </div>
            <Form.Control
              key={item.key}
              type="text"
              className="form-input roboto-regular w-50 mt-2"
              placeholder={t('Input.placeholder-option', 'Rédigez votre réponse ici ')}
              value={item.value || ''}
              onChange={handleItems(index)}
              required
              disabled={disabled}
            />
            {items?.length > 2 && (
              <div className="checkbox-icon mb-2 cursor-pointer" onClick={() => deleteLine(index)}>
                <img src={cross} alt="delete" />
              </div>
            )}

          </div>

        ))}
      </div>
      <div className="mt-2">
        <ActionButton disabled={disabled} onClick={addItem} buttonClassName="add-option">
          <img src={plus} alt="+" className="add-option__icon" />
        </ActionButton>
      </div>
    </div>
  );
};

ArrayInput.propTypes = {
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      value: PropTypes.any,
    }),
  ),
  options: PropTypes.shape({
    match: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    required: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
};

ArrayInput.defaultProps = {
  onChange: () => { },
  options: {},
  items: [],
  disabled: false,
};

export default ArrayInput;
