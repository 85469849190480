import React, {
  useEffect,
  useState,
  useContext,
} from 'react';
import {
  useRouteMatch,
  Link,
  useParams,
} from 'react-router-dom';
import {
  Col,
  Row,
  Container,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import ReturnButton from '../../components/ReturnButton/ReturnButton';
import { AlertContext } from '../../contexts/alert.context';
import { getEnterprises } from '../../services/enterprise.service';
import { getSurveys as getSurveysService } from '../../services/survey.service';
import AWCard from '../../components/AWCard/AWCard';
import StateCaption from '../../components/StateCaption/StateCaption';
import { statusList } from '../../helpers/constants';

const CustomerSurveys = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { enterpriseId } = useParams();
  const [surveys, setSurveys] = useState([]);
  const { setNotif } = useContext(AlertContext);
  const [customer, setCustomer] = useState();

  const getEnterprise = async () => {
    if (enterpriseId) {
      const enterpriseRes = await getEnterprises({
        fields: 'name',
        search: {
          id: enterpriseId,
        },
      });
      enterpriseRes.displayNotif(setNotif);
      if (enterpriseRes.success && enterpriseRes.data?.length === 1) {
        setCustomer(enterpriseRes.data[0]);
      }
    }
  };

  useEffect(async () => {
    getEnterprise();
  }, [enterpriseId]);

  const getSurveys = async () => {
    const surveysRes = await getSurveysService({
      fields: 'label,description,status,slug,id',
      search: { enterpriseId },
    });
    surveysRes.displayNotif(setNotif);
    if (surveysRes.success) {
      setSurveys(surveysRes.data);
    }
  };

  useEffect(() => {
    getSurveys();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Row>
          <li className="d-flex mb-4 mt-3">
            <ReturnButton previousPagePath="/customers/surveys" />
            <h1 className="mb-3 mt-1">
              {t('CustomerSurveys.title', 'Questionnaires - {{customer}}', { customer: customer?.name })}
            </h1>
          </li>

          <Col md={4} lg={3}>
            <Link to={`${url}/new`}>
              <AWCard title={t('CustomerSurveys.addSurvey', 'Créer un questionnaire')}>
                <div className="d-flex flex-1 justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faPlus} size="2x" />
                </div>
              </AWCard>
            </Link>
          </Col>
          {surveys && surveys.length > 0 && surveys.map((survey) => (
            <Col key={survey.id} md={4} lg={3}>
              <Link to={`${url}/${survey.id}/info`}>
                <AWCard
                  title={survey.label}
                >
                  <p className="card-text">
                    {survey?.description}
                  </p>
                  <StateCaption
                    color={statusList[survey.status].color}
                    backgroundColor={statusList[survey.status].background}
                  >
                    {t(`Status.${survey.status}`)}
                  </StateCaption>
                </AWCard>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default CustomerSurveys;
