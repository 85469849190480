import React from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SearchInput from '../SearchBox/SearchInput';
import PeriodSelector from '../PeriodSelector/PeriodSelector';

const SearchForm = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setSearchTerm,
  onSearch,
  enterprises,
  resetEnterprises,
  handleClick,
  onPeriodChange,
  hideElements,
}) => {
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch();
  };

  return (
    <Form className="mb-4">
      <Row className="align-items-baseline justify-content-end mx-4">
        {!hideElements.includes('searchInput') && (
          <Col className="mb-3 mb-md-0">
            <SearchInput
              placeholder={t('Enterprise.placeholderSearch')}
              search={setSearchTerm}
              data={enterprises}
              onReset={() => resetEnterprises()}
              onClick={(ent) => handleClick(ent)}
            />
          </Col>
        )}
        {!hideElements.includes('datePickers') && (
          <Col xs="auto" className="d-flex align-items-baseline mb-3 mb-md-0">
            <span className="me-2">{t('Common.From')}</span>
            <Form.Control
              type="date"
              onChange={(e) => setStartDate(e.target.value)}
              value={startDate}
              className="me-2"
            />
            <span className="me-2">{t('Common.To')}</span>
            <Form.Control
              type="date"
              onChange={(e) => setEndDate(e.target.value)}
              value={endDate}
              className="me-2"
            />
          </Col>
        )}
        {!hideElements.includes('periodSelector') && (
          <Col xs="auto" className="mb-3 mb-md-0">
            <PeriodSelector onPeriodChange={onPeriodChange} />
          </Col>
        )}
        {!hideElements.includes('applyButton') && (
          <Col xs="auto">
            <Button onClick={handleSubmit} className="w-100">
              {t('Common.Apply')}
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  );
};

SearchForm.propTypes = {
  startDate: PropTypes.string.isRequired,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.string.isRequired,
  setEndDate: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  enterprises: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  resetEnterprises: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  onPeriodChange: PropTypes.func.isRequired,
  hideElements: PropTypes.arrayOf(PropTypes.string),
};

SearchForm.defaultProps = {
  hideElements: [],
};

export default SearchForm;
