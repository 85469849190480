import React, {
  createContext, useState, useContext, useMemo,
} from 'react';
import {
  getComplianceEnterprises,
  getEnterprisesCount,
  getEditingTokens,
} from '../services/enterprise.service';
import { VendorsListContext } from './vendorList.context';
import { AlertContext } from './alert.context';
import { getUsers } from '../services/user.service';

export const EnterprisesContext = createContext();

const defaultFilters = {
  lastUpload: '',
  statusKbis: '',
  statusVendors: '1',
  customerId: '',
  page: 0,
  order: 'uploadDesc',
  documentTypeId: '',
  country: '',
};

// eslint-disable-next-line react/prop-types
const EnterprisesContextProvider = ({ children }) => {
  const { setSearchEvent } = useContext(VendorsListContext);
  const { setNotif } = useContext(AlertContext);
  const [enterprises, setEnterprises] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [count, setCount] = useState({});
  const [loading, setLoading] = useState(false);
  const [filters, setFiltersOnly] = useState(defaultFilters);
  const [enterprise, setEnterprise] = useState({});

  const getAllEnterprisesTokens = async (currentUser = '') => {
    const tokensResponse = await getEditingTokens();
    tokensResponse.displayNotif(setNotif);
    if (tokensResponse.success && Array.isArray(tokensResponse.data)) {
      const userEmails = tokensResponse.data.map((user) => user.userEmail);
      // eslint-disable-next-line no-restricted-syntax
      let userNames = [];
      // get the fullnames for all the emails
      userNames = await getUsers({
        fields: 'firstname,lastname,email',
        search: {
          email: {
            $or: userEmails,
          },
        },
      });
      if (userNames.success) {
        // sort the tokens from oldest startedEditing time to most recent
        userNames.data.sort((a, b) => {
          const timeA = tokensResponse.data.filter(
            (t) => t.userEmail === a.email,
          )[0].startedEditingAt;
          const timeB = tokensResponse.data.filter(
            (t) => t.userEmail === b.email,
          )[0].startedEditingAt;
          return new Date(timeB).getTime() - new Date(timeA).getTime();
        });
        // push the fullname in it's enterprise_id's array
        const orderedTokens = userNames.data.reduce((acc, name) => {
          const token = tokensResponse.data.filter((user) => user.userEmail === name.email)[0];
          if (currentUser !== name.email) {
            const fullname = `${name.firstname} ${name.lastname}`;
            if (!Object.keys(acc).includes(token.enterpriseId)) {
              acc[token.enterpriseId] = [];
            }
            acc[token.enterpriseId].push(fullname);
          }
          return acc;
        }, {});
        setTokens(orderedTokens);
      }
    }
  };

  const getAllEnterprises = async (newFilters = {}, opts = {}) => {
    setLoading(true);
    const enterprisesResponse = await getComplianceEnterprises(
      { ...filters, ...newFilters },
    );
    enterprisesResponse.displayNotif(setNotif);
    if (enterprisesResponse.success) {
      setEnterprises(
        opts.reset
          ? enterprisesResponse.data
          : [...enterprises, ...enterprisesResponse.data],
      );
      if (opts.reset) setSearchEvent();
    }
    setLoading(false);
  };

  const getAllEnterprisesCount = async (newFilters = {}) => {
    const countResponse = await getEnterprisesCount(
      { ...filters, ...newFilters },
    );
    countResponse.displayNotif(setNotif);
    if (countResponse.success) {
      setCount(countResponse.data);
    }
  };

  const setFilters = (partialFilters) => {
    setFiltersOnly({ ...filters, ...partialFilters });
  };

  const fullEnterprisesSearch = (newFilter) => {
    const clone = JSON.parse(JSON.stringify(newFilter));
    if (!clone.page) {
      clone.page = 0;
    }
    setFilters(clone);
    getAllEnterprises(clone, { reset: true });
    getAllEnterprisesCount(clone);
  };

  const reset = () => {
    setFilters({ page: 0 });
    setEnterprises([]);
    setCount({});
    setTokens([]);
  };

  const value = useMemo(() => ({
    enterprises,
    tokens,
    getAllEnterprises,
    getAllEnterprisesCount,
    getAllEnterprisesTokens,
    count,
    loading,
    filters,
    setFilters,
    fullEnterprisesSearch,
    enterprise,
    setEnterprise,
    reset,
  }), [enterprises, tokens, enterprise, count, loading, filters]);

  return (
    <EnterprisesContext.Provider
      value={value}
    >
      {children}
    </EnterprisesContext.Provider>
  );
};

export default EnterprisesContextProvider;
