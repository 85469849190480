import React, {
  useEffect,
  useState,
  useContext,
} from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UUID_REGEX } from '../../helpers/patterns';
import useLoadingPromise from '../../hooks/useLoadingPromise';
import {
  forceUserConfirmation, getUsers, getEnterprisesByUser, anonymizeUser, getCognitoUserStatus,
} from '../../services/user.service';
import Header from '../../components/Header/Header';
import { AWColors, formatDate } from '../../helpers/utils';
import LabelValueLine from '../../components/LabelValueLine/LabelValueLine';
import ActionButton from '../../components/ActionButton/ActionButton';
import { AlertContext } from '../../contexts/alert.context';
import SearchInput from '../../components/SearchBox/SearchInput';
import AnonymizeUser from '../../components/AnonymizeUser/AnonymiseUser';
import { updateHasUser } from '../../services/enterprise.service';

const UNCONFIRMED = 'UNCONFIRMED';

const CognitoUserConfirmation = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState({});
  const { waitWithLoad, isLoading } = useLoadingPromise();
  const { setNotif } = useContext(AlertContext);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [show, setShow] = useState(false);
  const [enterprises, setEnterprises] = useState();
  const [error, setError] = useState(false);
  const [clear, setClear] = useState(false);

  const handleConfirm = async () => {
    const res = await waitWithLoad(forceUserConfirmation(user.email));
    res.displayNotif(setNotif);
    if (res.success) {
      setUser(res.data);
    }
  };

  const fetchUser = async (_search) => {
    setError(false);
    const res = await waitWithLoad(getUsers(
      {
        fields: ['firstname', 'lastname', 'email', 'created_at', 'id', 'last_login'],
        search: {
          ...UUID_REGEX.test(_search) ? { id: _search } : {},
          ...!UUID_REGEX.test(_search) ? { email: { $sw: _search } } : {},
        },
        page_size: 100,
      },
    ));
    const _users = res?.success && res?.data?.length ? res.data.map((user) => ({ ...user, name: `${user.email} (${user.id})` })) : [];
    setUsers(_users);
  };

  useEffect(() => {
    fetchUser(search);
  }, [search]);

  useEffect(() => {
    if (clear) setClear(false);
  }, [clear]);

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleChangeSwitch = async (entrepriseId, valid) => {
    const updatedEnterprises = enterprises.map((entreprise) => {
      if (entreprise.enterprise_id === entrepriseId) {
        return {
          ...entreprise,
          is_document_validator: valid,
        };
      }
      return entreprise; // Return the original object if the condition is not met
    });

    const updatedUser = await waitWithLoad(updateHasUser(user.id, entrepriseId, {
      is_document_validator: valid,
    }));

    if (updatedUser.success) {
      setEnterprises(updatedEnterprises);
    }
  };

  const handleReset = () => {
    setEnterprises([]);
    setUsers([]);
    setUser({});
    setError(false);
  };

  const handleClick = async (_user) => {
    const resCognito = await waitWithLoad(getCognitoUserStatus(_user.email));
    resCognito.displayNotif(setNotif);
    if (resCognito.success && _user) {
      setUser({ status: resCognito?.data?.status, ..._user });
      const res = await getEnterprisesByUser(
        _user.id,
        {
          fields: [
            'identification_number',
            'enterprise_id',
            'enterprise_name',
            'user_id',
            'is_admin',
            'is_operator',
            'deleted_at',
            'is_document_validator',
          ],
          page_size: 1000,
        },
      );
      if (res?.success && res?.data?.length) {
        setEnterprises(res.data);
      }
    } else { setError(true); }
  };

  const handleAnonymize = async () => {
    setError(false);
    const res = await anonymizeUser(user.id);
    if (res?.success) {
      setClear(true);
    } else {
      setError(true);
    }
    setShow(false);
  };

  return (
    <div className="h-100 d-flex flex-column">
      <Header />
      <div className="d-flex justify-content-center align-items-center mt-5">
        <h1>
          {t('User.management')}
        </h1>
      </div>
      <div className="d-flex justify-content-center h-100 mt-5">
        <div className="cognito-user">

          <SearchInput
            label={t('User.Fields.search')}
            placeholder={t('User.placeholderSearch')}
            search={handleSearch}
            data={users}
            onReset={handleReset}
            onClick={handleClick}
            clear={clear}
            className="w-100"
          />
          {
              error ? (
                <span className="d-flex justify-content-center">
                  {t('ApiErrors.Server')}
                </span>
              ) : ''
            }
          <h3 className="mt-3">
            {t('Survey.info')}
          </h3>
          <div className="cognito-user__user bg-white">
            <LabelValueLine className="mb-3" label={t('User.Fields.name')}>
              {user?.firstname || ''}
                &nbsp;
              {user?.lastname || ''}
            </LabelValueLine>
            <LabelValueLine className="mb-3" label={t('User.Fields.email')}>
              {user?.email || ''}
            </LabelValueLine>
            <LabelValueLine className="mb-3" label={t('User.Fields.id')}>
              {user?.id || ''}
            </LabelValueLine>
            <LabelValueLine className="mb-3" label={t('User.Fields.lastLogin')}>
              {user?.last_login ? formatDate(user?.last_login, 'DD/MM/YYYY HH:mm:ss') : ''}
            </LabelValueLine>
            <LabelValueLine className="mb-3" label={t('User.Fields.cognitoStatus')}>
              {user?.status || '' }
            </LabelValueLine>
            <LabelValueLine className="mb-3" label={t('User.Fields.dateRegistration')}>
              {user?.created_at ? formatDate(user?.created_at, 'DD/MM/YYYY HH:mm:ss') : '-'}
            </LabelValueLine>
          </div>
          <div className="d-flex mt-3 justify-content-center">
            {
              user?.status === UNCONFIRMED && (
                <ActionButton
                  backgroundColor={AWColors.Green}
                  color="white"
                  onClick={handleConfirm}
                  disabled={isLoading}
                >
                  {t('Button.confirm')}
                </ActionButton>
              )
            }
            { user?.id && (
            <AnonymizeUser
              user={user}
              setShow={setShow}
              show={show}
              handleSubmit={handleAnonymize}
            />
            )}

          </div>
          {(user?.id && enterprises?.length) && (
            <>
              <h3 className="mt-3">{t('Enterprise.enterprises')}</h3>
              {enterprises?.map((enterprise) => (
                <div className="cognito-user__user bg-white my-3" key={enterprise.enterprise_id}>
                  <LabelValueLine className="mb-3" label={t('Enterprise.Membership.id')}>
                    {enterprise?.enterprise_id || '-'}
                  </LabelValueLine>
                  <LabelValueLine className="mb-3" label={t('Enterprise.Membership.name')}>
                    {enterprise?.enterprise_name || '-'}
                  </LabelValueLine>
                  <LabelValueLine className="mb-3" label={t('Enterprise.Membership.siret')}>
                    {enterprise?.identification_number || ''}
                  </LabelValueLine>
                  <LabelValueLine className="mb-3" label={t('Enterprise.Membership.is_admin')}>
                    {enterprise?.is_admin ? t('Common.yes') : t('Common.no')}
                  </LabelValueLine>
                  <LabelValueLine className="mb-3" label={t('Enterprise.Membership.is_operator')}>
                    {enterprise?.is_operator ? t('Common.yes') : t('Common.no')}
                  </LabelValueLine>
                  <LabelValueLine className="mb-3" label={t('Enterprise.Membership.deleted_at')}>
                    {enterprise?.deleted_at === undefined ? t('Enterprise.Membership.not_deleted') : formatDate(enterprise?.deleted_at, 'DD/MM/YYYY HH:mm:ss')}
                  </LabelValueLine>
                  <LabelValueLine className="mb-3" label={t('Enterprise.Membership.is_document_validator')}>
                    <Form.Check
                      type="switch"
                      className="switch-pointer"
                      checked={enterprise.is_document_validator}
                      onChange={() => handleChangeSwitch(
                        enterprise.enterprise_id,
                        !enterprise.is_document_validator,
                      )}
                    />

                  </LabelValueLine>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CognitoUserConfirmation;
