import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'react-bootstrap';
import UserTokenCircle from '../DocumentInfoTab/UserTokenCircle';
import { DocumentsContext } from '../../contexts/documents.context';
import { EnterprisesContext } from '../../contexts/enterprises.context';
import { UserContext } from '../../contexts/user.context';
import { FileContext } from '../../contexts/file.context';
import { AlertContext } from '../../contexts/alert.context';

import StatusButton from './ActionButtons/StatusButton';
import ActionButton from '../ActionButton/ActionButton';
import FormReject from './ActionForms/FormReject';
import FormAccept from './ActionForms/FormAccept';

import {
  accept,
  precheck,
  removePrecheck,
  reject,
  updateDocument,
} from '../../services/document.service';
import { ACTION_STATUS } from '../../helpers/constants';
import ActionMessage from './ActionMessage';
import usePrevious from '../../hooks/usePrevious';
import * as Errors from '../../helpers/catalog.errors';
import PrecheckButton from './ActionButtons/PrecheckButton';

/**
 * DocumentAction is a component that contains 3 buttons to make an action on a document :
 * reject, validate, pre check.
 * @version 1
 * @since 13/12/2021
 */

const DocumentAction = () => {
  const { t } = useTranslation();
  const {
    document,
    getDocumentsFromEnterprise,
  } = useContext(DocumentsContext);
  const { enterprise, tokens } = useContext(EnterprisesContext);
  const { user } = useContext(UserContext);
  const { getAllComments } = useContext(FileContext);
  const { setNotif } = useContext(AlertContext);
  const [status, setStatus] = useState('');
  const [alert, setAlert] = useState({});
  const prevDocument = usePrevious(document);

  const handleErrors = (apiResponse, callback) => {
    if (apiResponse.success) {
      callback();
      setAlert({
        type: 'valid',
        message: t(
          'DocumentAction.validSubmit',
          'Votre document a bien été modifié',
        ),
      });
    } else if (
      apiResponse.hasError(Errors.Unauthorized)
      || apiResponse.hasError(Errors.Forbidden)
    ) {
      setAlert({
        type: 'error',
        message: t(
          'ApiErrors.UnauthorizedDocument',
          "Vous n'avez pas accès à la modification d'un document",
        ),
      });
    } else {
      apiResponse.displayNotif(setNotif);
      setAlert({
        type: 'error',
        message: t(
          'ApiErrors.Unknown',
          'Un problème inconnu est survenu',
        ),
      });
    }
  };

  useEffect(() => {
    if (prevDocument?.document_id !== document?.document_id) {
      setAlert({});
      setStatus('');
    }
  }, [document]);

  useEffect(() => {
    setAlert({});
  }, [status]);

  // PRECHECKING
  const precheckSubmit = async () => {
    const res = document.is_pre_check
      ? await removePrecheck(document?.document_id)
      : await precheck(document?.document_id);
    handleErrors(res, () => {
      getDocumentsFromEnterprise(enterprise.id);
    });
  };

  // ACCEPTANCE
  const submitAccept = async (date) => {
    const body = {
      company_id: enterprise.id,
      auth_user_email: user.email,
      valid_until: date,
    };
    if (body) {
      const res = await accept(document?.document_id, body);
      handleErrors(res, () => {
        getDocumentsFromEnterprise(enterprise.id);
      });
    }
  };

  // REJECTION
  const submitRefusal = async (reason) => {
    const body = {
      company_id: enterprise.id,
      auth_user_email: user.email,
      reason_for_rejection: reason.display_name,
      comment: {
        content: reason.message,
        commentable_type: 'document',
        commentable_id: document?.document_id,
        visibility: 'public',
      },
    };
    if (body) {
      const res = await reject(document?.document_id, body);
      handleErrors(res, () => {
        getAllComments(document?.document_id);
        getDocumentsFromEnterprise(enterprise.id);
      });
    }
  };

  // PUT BACK ON HOLD
  const putOnHold = async () => {
    const body = {
      status: 'pending',
    };
    if (body) {
      const apiResponse = await updateDocument(document?.document_id, body);
      apiResponse.displayNotif(setNotif);
      if (apiResponse.success) {
        getAllComments(document?.document_id);
        getDocumentsFromEnterprise(enterprise.id);
      }
      return apiResponse.success;
    }
    return null;
  };

  return (
    <>
      <Row>
        <Col md="auto">
          <h3>
            {t('DocumentAction.titre', 'Action sur le document')}
            {' '}
          </h3>
        </Col>
        <Col>
          <Row className="d-flex pt-3 ml-0 mr-0 list-badges justify-content-start flex-row-reverse">
            {tokens
            && enterprise
            && Object.keys(tokens).includes(enterprise.id)
              ? tokens[enterprise.id].map(
                (supportUser) => <UserTokenCircle key={supportUser} email={supportUser} />,
              ) : null}
          </Row>
        </Col>
      </Row>
      <div className="d-flex button-row">
        <StatusButton
          documentStatus={document?.status}
          type={ACTION_STATUS.pending}
          onChange={setStatus}
          status={status}
        />
        <PrecheckButton onChange={setStatus} hasChanged={status === ACTION_STATUS.precheck} />
        <StatusButton
          documentStatus={document?.status}
          type={ACTION_STATUS.validated}
          onChange={setStatus}
          status={status}
        />
        <StatusButton
          documentStatus={document?.status}
          type={ACTION_STATUS.rejected}
          onChange={setStatus}
          status={status}
        />
      </div>
      {alert?.message && (
        <ActionMessage
          type={alert?.type}
          message={alert?.message}
        />
      )}
      {status === ACTION_STATUS.precheck && (
        <div className="submit-button-container d-flex justify-content-end">
          <ActionButton
            type="button"
            buttonClassName="submit-button"
            onClick={precheckSubmit}
            id="precheck-validate"
          >
            {t('DocumentAction.button', 'Valider')}
          </ActionButton>
        </div>
      )}
      {status === ACTION_STATUS.validated && (
        <FormAccept onSubmit={submitAccept} />
      )}
      {status === ACTION_STATUS.rejected && (
        <FormReject onSubmit={submitRefusal} />
      )}
      {status === ACTION_STATUS.pending && (
      <div className="submit-button-container d-flex justify-content-end">
        <ActionButton
          type="button"
          buttonClassName="submit-button"
          onClick={putOnHold}
        >
          {t('DocumentAction.button', 'Valider')}
        </ActionButton>
      </div>
      )}
    </>
  );
};

export default DocumentAction;
