import React from 'react';
import PropTypes from 'prop-types';

const ErrorFormField = ({ errors, children, field }) => (
  (errors.includes(field)) ? (
    <small className="text-danger">
      {children}
    </small>
  ) : ''
);

ErrorFormField.propTypes = {
  children: PropTypes.node,
  errors: PropTypes.arrayOf(
    PropTypes.string,
  ),
  field: PropTypes.string.isRequired,
};

ErrorFormField.defaultProps = {
  errors: [],
  children: '',
};

export default ErrorFormField;
