import React from 'react';
import { Card } from 'react-bootstrap';
import { PropTypes } from 'prop-types';

const AWCard = ({ title, children }) => (
  <Card className="aw-card">
    <div className="card-body" aria-hidden="true">
      <h3 className="card-title">
        {title}
      </h3>
      <div className="d-flex flex-column justify-content-around flex-1">
        {children}
      </div>
    </div>
  </Card>
);

AWCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

AWCard.defaultProps = {
  title: '',
  children: <> </>,
};

export default AWCard;
