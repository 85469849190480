import React from 'react';
import PropTypes from 'prop-types';

import cross from '../../assets/alert-cross.svg';
import check from '../../assets/alert-check.svg';

const ActionMessage = ({ type, message }) => {
  if (!message) return '';
  return (
    <div
      className={
        `alert-comment alert-comment__${type === 'error' ? 'red' : 'green'} mt-2`
      }
    >
      <img
        src={type === 'error' ? cross : check}
        alt={type === 'error' ? 'cross' : 'check'}
        className="icon-message"
      />
      {message}
    </div>
  );
};

ActionMessage.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string,
};

ActionMessage.defaultProps = {
  message: '',
};

export default ActionMessage;
