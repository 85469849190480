import React, { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Tab = ({
  to,
  active,
  disabled,
  className,
  children,
  icon,
}) => {
  const classes = useMemo(() => (
    [
      'tab-li ',
      active ? 'tab-li--active' : '',
      disabled ? 'tab-li--disabled' : '',
      className,
    ].filter((x) => !!x).join(' ')
  ), [active, disabled]);

  const Comp = useCallback(() => (
    <Link className={!disabled ? classes : ''} to={to}>
      <li className="d-flex align-items-center">
        {
          icon ? (
            <div className="mr-3">
              <img src={icon} alt="tab-icon" className="tab-li__icon" />
            </div>
          ) : ''
        }
        {children}
      </li>
    </Link>
  ), [active, disabled, icon]);

  if (disabled) {
    return (
      <div className={classes}>
        <Comp />
      </div>
    );
  }
  return <Comp />;
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.node,
};

Tab.defaultProps = {
  active: true,
  disabled: false,
  className: '',
  icon: '',
};

export default Tab;
