import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/css/style.css';

/**
 * ColorPoint is a colored circle to put before a span as an indicator of the state.
 * @version 1
 * @since 10/11/2021
 */

const ColorPoint = ({ backgroundColor }) => (
  <div
    className="color-point"
    style={{ backgroundColor }}
  />
);

ColorPoint.propTypes = {
  /** Background color of the button */
  backgroundColor: PropTypes.string,
};

ColorPoint.defaultProps = {
  backgroundColor: '#66EBA7',
};

export default ColorPoint;
