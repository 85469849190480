import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../Header/Header';
import ReturnButton from '../ReturnButton/ReturnButton';
import Tab from '../Tab/Tab';

const AWFormPage = ({
  previousPagePath,
  formTitle,
  children,
}) => (
  <>
    <Header />
    <Container fluid>
      <Row>
        <div className="d-flex mb-4 mt-2">
          <ReturnButton previousPagePath={previousPagePath} />
          <h1 className="m-0">
            {formTitle}
          </h1>
        </div>
        {children}
      </Row>
    </Container>
  </>
);

const Side = ({
  leftTabs,
  children,
}) => (
  <Col xs={2} className="p-0 d-flex flex-column flex-1 bg-light-grey">
    {
      leftTabs.map((tab) => (
        <Tab
          key={tab.linkTo}
          to={tab.linkTo}
          active={tab.active}
          disabled={tab.disabled}
          icon={tab.icon}
          className={tab.className}
        >
          {tab.content}
        </Tab>
      ))
    }
    {children}
  </Col>
);

const Main = ({ children }) => (
  <Col xs={10} className="p-0">
    {children}
  </Col>
);

AWFormPage.Side = Side;
AWFormPage.Main = Main;

AWFormPage.propTypes = {
  previousPagePath: PropTypes.string.isRequired,
  formTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Side.propTypes = {
  leftTabs: PropTypes.arrayOf(PropTypes.shape({
    linkTo: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    content: PropTypes.node.isRequired,
    icon: PropTypes.string,
    className: PropTypes.string,
  })).isRequired,
  children: PropTypes.node,
};

Side.defaultProps = {
  children: '',
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AWFormPage;
