import React, { useEffect, useMemo, useState } from 'react';
import { cloneDeep, map } from 'lodash';
import { PropTypes } from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import trash from '../../assets/trash.svg';
import { KEYBOARD_VALUES, randString } from '../../helpers/utils';

const MultiStringInput = ({
  label,
  onChange,
  fireKey,
  defaultWords,
  itemLabel,
  disabled,
}) => {
  const { t } = useTranslation();
  const [words, setWords] = useState([]);
  const name = useMemo(() => label?.toLowerCase()?.replace(/\s/g, '-') || 'multi-string', [label]);
  const fireKeyTrad = useMemo(() => {
    const indexOfFireKey = Object.values(KEYBOARD_VALUES).indexOf(fireKey);
    return t(`Common.${Object.keys(KEYBOARD_VALUES)[indexOfFireKey]}`);
  }, [fireKey]);

  useEffect(() => {
    setWords(defaultWords);
  }, [defaultWords]);

  const handleValue = (e) => {
    const current = words.find((w) => (w.current));
    const word = current ? cloneDeep(current) : { value: '', current: true, id: randString() };
    word.value = e?.target?.value || '';
    setWords([...words.filter((w) => !w.current), word]);
  };

  const handleWords = (e) => {
    if (e.keyCode === fireKey) {
      const current = words.find((w) => w.current);
      if (current) {
        current.value = current.value.trim();
        setWords(
          [...words.filter((w) => !w.current), { ...cloneDeep(current), current: false }],
        );
      }
    }
  };

  const handleDelete = (id) => () => {
    setWords(words.filter((w) => w.id !== id && !!w.value));
  };

  useEffect(() => {
    onChange(map(words, 'value'));
  }, [words]);

  const value = useMemo(() => (
    words.find((w) => w.current)?.value || ''
  ), [words]);

  return (
    <Form.Group>
      {
        label ? (
          <Form.Label htmlFor={name}>
            {label}
          </Form.Label>
        ) : ''
      }
      <InputGroup>
        {
          words.filter((w) => !w.current).map((w) => (
            <InputGroup.Text key={w.id}>
              {w.value}
              &nbsp;
              <span
                aria-hidden
                onClick={!disabled ? handleDelete(w.id) : null}
                className={!disabled ? 'pointer' : ''}
              >
                <img src={trash} alt="delete" height="16" />
              </span>
            </InputGroup.Text>
          ))
        }
        <Form.Control
          type="text"
          value={value}
          disabled={disabled}
          onChange={handleValue}
          onKeyDown={handleWords}
          aria-describedby={name}
        />
      </InputGroup>
      {!disabled ? (

        <Form.Text id={name}>
          {t(
            'Input.clickToAdd',
            'Appuyer sur {{key}} pour ajouter {{itemLabel}}',
            { key: fireKeyTrad, itemLabel },
          )}
        </Form.Text>
      ) : <> </>}
    </Form.Group>
  );
};

MultiStringInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  fireKey: PropTypes.number.isRequired,
  itemLabel: PropTypes.string.isRequired,
  defaultWords: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    current: PropTypes.bool,
  })),
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

MultiStringInput.defaultProps = {
  defaultWords: [],
  label: '',
  disabled: false,
};

export default MultiStringInput;
