import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'react-bootstrap';
import { DocumentsContext } from '../../contexts/documents.context';
import { AlertContext } from '../../contexts/alert.context';
import { EnterprisesContext } from '../../contexts/enterprises.context';
import { formatDate, formatSignedAtDate } from '../../helpers/utils';
import { updateDocument } from '../../services/document.service';
import { getUserFromId } from '../../services/user.service';
import ActionAuthor from './ActionAuthor';
import DateInput from './DateInput';

const DocumentInfoTab = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState({});
  const { document, getDocumentsFromEnterprise, documentLoading } = useContext(DocumentsContext);
  const { setNotif } = useContext(AlertContext);
  const { enterprise } = useContext(EnterprisesContext);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      const userId = document?.accepted_by || document?.rejected_by;
      if (userId) {
        const userResponse = await getUserFromId(
          userId,
          { fields: 'firstname,lastname' },
        );
        userResponse.displayNotif(setNotif);
        if (userResponse.success) {
          setUser(userResponse.data);
        }
      } else {
        setUser({});
      }
    })();
    setError('');
  }, [document]);

  const documentAction = useMemo(() => {
    let result = {};
    if ((document?.accepted_at || document?.accepted_by) && document?.status === 'validated') {
      result = { action: 'accepted', date: document?.accepted_at };
    } else if ((document?.rejected_at || document?.rejected_by) && document?.status === 'rejected') {
      result = { action: 'rejected', date: document?.rejected_at };
    }
    result.reason = document?.reason_for_rejection;
    return result;
  }, [document]);

  const changeDate = (type) => async (newDate) => {
    setError('');
    const valid = type === 'start' ? 'valid_from' : 'valid_until';
    if (type === 'start' && newDate > document?.valid_until) {
      setError('start');
    } else if (type === 'end' && newDate < document?.valid_from) {
      setError('end');
    } else {
      const apiResponse = await updateDocument(document?.document_id, {
        [valid]: newDate,
      });
      if (apiResponse.success) {
        getDocumentsFromEnterprise(enterprise.id);
      }
      apiResponse.displayNotif(setNotif);
      // eslint-disable-next-line consistent-return
      return apiResponse.success;
    }
    return null;
  };

  return (
    <div className="doc-info">
      <div className="doc-info__item text-center tab-loading" hidden={document?.created_at}>
        <Spinner animation="grow" hidden={!documentLoading} />
      </div>
      <div hidden={!document?.created_at}>
        <ActionAuthor
          user={user}
          action={documentAction.action}
          date={documentAction.date}
          reason={documentAction.reason}
        />
        <div className="doc-info__item">
          <div className="doc-info__title">
            {t(
              'DocumentInfoTab.uploadDate',
              "Date d'upload par le sous-traitant",
            )}
          </div>
          <div className="doc-info__content">
            {document?.created_at
              ? formatDate(document.created_at)
              : 'N/A'}
          </div>
        </div>
        {document?.document_type_model_id && (
        <div className="doc-info__item">
          <div className="doc-info__title">
            {t(
              'DocumentInfoTab.signatoryInfo',
              ' Date de signature',
            )}
          </div>
          <div className="doc-info__content">
            {t('DocumentInfoTab.signed_at', ' Signé le ')}
            {document?.signed_at
              ? formatSignedAtDate(document.signed_at)
              : 'N/A'}
            {t('DocumentInfoTab.signed_by', ' par ')}
            {document?.signatory_name
              ? document.signatory_name
              : 'N/A'}
          </div>
        </div>
        )}

        <div className="doc-info__item">
          <div className="doc-info__title">
            {t(
              'DocumentInfoTab.startValidityDate',
              ' Date de début de validité',
            )}
          </div>
          <div className="doc-info__content">
            <DateInput
              date={document?.valid_from}
              type="start"
              onSubmit={changeDate('start')}
              setAlert={setError}
            />
            {error === 'start' && (
            <div className="alert_date">
              {t(
                'DocumentInfoTab.errorInDateStart',
                'La date de début de validité doit être antérieure à la date de fin',
              )}
            </div>
            )}
          </div>
        </div>
        <div className="doc-info__item">
          <div className="doc-info__title">
            {t(
              'DocumentInfoTab.endValidityDate',
              ' Date de fin de validité',
            )}
          </div>
          <div className="doc-info__content">
            <DateInput
              date={document?.valid_until}
              type="end"
              onSubmit={changeDate('end')}
              setAlert={setError}
            />
            {error === 'end' && (
            <div className="alert_date">
              {t(
                'DocumentInfoTab.errorInDateEnd',
                'La date de fin de validité doit être postérieure à la date de début',
              )}
            </div>
            )}
          </div>
        </div>
        <div className="doc-info__item">
          <div className="doc-info__title">
            {t(
              'DocumentInfoTab.validityPeriod',
              ' Période de validité',
            )}
          </div>
          <div className="doc-info__content">
            {document?.validity_period
              ? document.validity_period
              : 'N/A'}
          </div>
        </div>
      </div>
    </div>
  );
};
export default DocumentInfoTab;
