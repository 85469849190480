import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

const DialogModal = (props) => {
  const {
    show,
    onHide,
    onSubmit,
    size,
    heading,
    centered,
    btnText,
    btnColor,
    btnDisabled,
    children,
  } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size={size}
      aria-labelledby="dialog-modal"
      centered={centered}
    >
      <Modal.Header closeButton>
        <Modal.Title id="dialog-modal">
          {heading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="w-100"
          style={{ backgroundColor: btnColor, borderColor: 'transparent' }}
          disabled={btnDisabled}
          onClick={onSubmit}
        >
          {btnText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DialogModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
  size: PropTypes.string,
  heading: PropTypes.string,
  centered: PropTypes.bool,
  btnText: PropTypes.string,
  btnColor: PropTypes.string,
  btnDisabled: PropTypes.bool,
  children: PropTypes.node,
};

DialogModal.defaultProps = {
  show: false,
  onHide: () => { },
  onSubmit: () => { },
  size: 'md',
  heading: '',
  centered: true,
  btnDisabled: false,
  btnText: '',
  btnColor: '#12957d',
  children: PropTypes.element,
};

export default DialogModal;
