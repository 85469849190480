import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '../helpers/ProtectedRoute';
import Stats from '../views/Stats/Stats';
import DocumentStats from '../views/Stats/DocumentStats';
import DocumentStatsIA from '../views/Stats/DocumentStatsIA';

const StatsRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={`${path}/docs/ia`} exact>
        <DocumentStatsIA />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/docs`} exact>
        <DocumentStats />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/`} exact>
        <Stats />
      </ProtectedRoute>
    </Switch>
  );
};

export default StatsRoutes;
