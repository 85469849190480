import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import StateCaption from '../StateCaption/StateCaption';
import { FileContext } from '../../contexts/file.context';
import { formatDate } from '../../helpers/utils';

const CommentTab = () => {
  const { t } = useTranslation();

  const {
    comments,
    deleteComment,
  } = useContext(FileContext);

  return (
    <div className="comments-container">
      {!!comments?.length && comments.map((comment) => (
        <div className="comment">
          <div className="comment__info uppercase">
            <StateCaption
              color="#272928"
              backgroundColor="#fff"
            >
              {comment.visibility === 'public'
                ? t('CommentTab.public', 'Public')
                : comment.visibility === 'private'
                  ? t('CommentTab.private', 'Privé')
                  : t('CommentTab.protected', 'Protégé')}
            </StateCaption>
            <div className="comment__date">
              {formatDate(comment.created_at)}
            </div>
          </div>
          <div className="comment__content">{comment.content}</div>
          <div className="comment__info">
            <div className="comment__author">
              {`${comment.user_firstname} ${comment.user_lastname}`}
            </div>
            <button
              className="delete-button"
              onClick={() => deleteComment(comment.id)}
              type="submit"
            >
              {t('CommentTab.delete', 'Supprimer')}
            </button>
          </div>
        </div>
      ))}
      {!comments?.length && (
        <div className="nocomment">
          {t('CommentTab.nocomment', 'Pas de commentaire')}
        </div>
      )}
    </div>
  );
};
export default CommentTab;
