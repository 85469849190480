import React, {
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';
import { Spinner } from 'react-bootstrap';
import { getSurveys } from '../../services/survey.service';
import FilteredCustomers from '../../components/FilteredCustomers/FilteredCustomers';

const additionalColumns = [
  {
    label: i18n.t('CustomersSurveys.surveyCount', 'Nombre de questionnaires'),
    key: 'surveys',
  },
];

const CustomersSurveys = () => {
  const history = useHistory();

  const [surveys, setSurveys] = useState();

  const getAllSurveys = async () => {
    const surveysRes = await getSurveys({ fields: 'enterpriseId' });
    if (surveysRes.success && surveysRes.data.length) {
      setSurveys(surveysRes.data);
    } else {
      setSurveys([]);
    }
  };

  const getCustomerSurveys = async (customer) => {
    const response = await getSurveys({
      fields: 'enterpriseId',
      search: {
        enterpriseId: customer.id,
      },
    });
    if (response.success) {
      return { surveys: response.data };
    }
    return [];
  };

  useEffect(() => {
    getAllSurveys();
  }, []);

  const goToSurveyPage = (entity) => {
    history.push(`/customers/${entity.id}/surveys`);
  };

  if (!surveys) {
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  return (
    <FilteredCustomers
      onCustomerClick={goToSurveyPage}
      matchingIds={[...new Set(surveys.map((s) => s.enterpriseId))]}
      handleSearchResultClick={getCustomerSurveys}
      columns={additionalColumns}
      data={{ surveys }}
      sortByKey="surveys"
    />
  );
};

export default CustomersSurveys;
