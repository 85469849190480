import React from 'react';
import PropTypes from 'prop-types';

const Tag = ({ color, label, size }) => (
  <div
    className={`customTag ${size === 'tiny' ? 'customTag--tiny' : ''}`}
    style={{ backgroundColor: color }}
  >
    <p>{label}</p>
  </div>
);

Tag.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
};

Tag.defaultProps = {
  size: 'regular',
};

export default Tag;
