import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import Vendor from '../views/Vendor/Vendor';
import ComplianceDashboard from '../views/ComplianceDashboard/ComplianceDashboard';
import ProtectedRoute from '../helpers/ProtectedRoute';
import UrssafJson from '../views/UrssafJson/UrssafJson';

const ComplianceRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <ProtectedRoute path={`${path}`} exact>
        <ComplianceDashboard />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/provider/:enterpriseId/document/:documentId/json`}>
        <UrssafJson />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/provider/:enterpriseId`}>
        <Vendor />
      </ProtectedRoute>
    </Switch>
  );
};

export default ComplianceRoutes;
