import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const NumberInput = ({
  options,
  onOptionsChange,
  disabled,
}) => {
  const { t } = useTranslation();

  const handleOptions = (fieldName) => ({ target: { value } }) => {
    onOptionsChange({ ...options, [fieldName]: parseInt(value, 10) });
  };

  return (
    <>
      <div className="separator mt-4" />
      <div className="d-flex justify-content-between">
        <div>
          <Form.Label className="label">
            {t('Input.placeholder-min', 'Valeur minimum')}
          </Form.Label>
          <Form.Control
            type="number"
            className="form-input roboto-regular w-50"
            placeholder={t('Input.Min', 'Min ')}
            value={options.min || ''}
            onChange={handleOptions('min')}
            disabled={disabled}
          />
        </div>
        <div className="text-right">
          <Form.Label className="label">
            {t('Input.placeholder-max', 'Valeur maximum')}
          </Form.Label>
          <Form.Control
            type="number"
            className="form-input roboto-regular w-50 right-align"
            placeholder={t('Input.max', 'Max ')}
            value={options.max || ''}
            onChange={handleOptions('max')}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
};

NumberInput.propTypes = {
  onOptionsChange: PropTypes.func,
  options: PropTypes.shape({
    match: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    required: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
};

NumberInput.defaultProps = {
  onOptionsChange: () => { },
  options: {},
  disabled: false,
};

export default NumberInput;
