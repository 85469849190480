import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const AlertContext = createContext();

const AlertContextProvider = ({ children }) => {
  const [notif, setNotif] = useState({});

  const value = useMemo(() => ({
    notif,
    setNotif,
  }), [notif]);
  return (
    <AlertContext.Provider value={value}>
      {children}
    </AlertContext.Provider>
  );
};

AlertContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AlertContextProvider;
