import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip/Tooltip';

/**
 * DocumentActionButton is a button who allows to do an action on a document
 * and his function OnClick..
 * @version 1
 * @since 04/03/2022
 */

const DocumentActionButton = ({
  onClick,
  icon,
  tooltip,
  disabled,
}) => {
  const idRandom = (Math.random() + 1).toString(36).substring(7);

  return (
    <>
      <button
        className="document-action-button"
        type="button"
        onClick={onClick}
        data-tip="react-tooltip"
        data-for={`tooltip-${idRandom}`}
        disabled={disabled}
      >
        <img src={icon} alt="icon" className="icon-button" />
      </button>
      {
        !!tooltip && (
          <Tooltip
            id={`tooltip-${idRandom}`}
          >
            {tooltip}
          </Tooltip>
        )
      }
    </>
  );
};

DocumentActionButton.propTypes = {
  /** Function used on button click */
  onClick: PropTypes.func,
  icon: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
};

DocumentActionButton.defaultProps = {
  onClick: '',
  icon: '',
  tooltip: '',
  disabled: false,
};

export default DocumentActionButton;
