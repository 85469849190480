import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Row,
  Col,
  Form,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useParams, Redirect } from 'react-router-dom';

import ErrorFormField from '../ErrorFormField/ErrorFormField';
import { AlertContext } from '../../contexts/alert.context';
import {
  updateSurvey,
  createSurvey,
} from '../../services/survey.service';
import ActionButton from '../ActionButton/ActionButton';
import { Status } from '../StatusTag/StatusTag';
import AWForm from '../AWForm/AWForm';

const AnswererTypes = {
  Provider: 'provider',
  Customer: 'customer',
  All: 'all',
};

const SurveyGeneral = ({ onUpdate, survey: actualSurvey, creationMode }) => {
  const [survey, setSurvey] = useState(actualSurvey);
  const { setNotif } = useContext(AlertContext);
  const { t } = useTranslation();
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(!creationMode);
  const [redirect, setRedirect] = useState(<div />);
  const { enterpriseId } = useParams();

  const isEnabled = useMemo(() => (
    creationMode || (!creationMode && survey?.status === Status.Sketch)
  ), [creationMode, survey]);

  const handleFieldChange = (fieldName) => ({ target: { value } }) => {
    if (value !== ' ') {
      if (fieldName === 'companyName') {
        setSurvey({ ...survey, company: { ...survey?.company, name: value } });
      } else {
        setSurvey({ ...survey, [fieldName]: value });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentErrors = [];
    if (!survey.label) {
      currentErrors.push('label');
    }
    if (!survey.description) {
      currentErrors.push('description');
    }
    if (!survey.answerer) {
      survey.answerer = AnswererTypes.Provider;
    }
    if (currentErrors.length) {
      setErrors(currentErrors);
    } else if (creationMode) {
      const surveyRes = await createSurvey({ ...survey, enterpriseId });
      surveyRes.displayNotif(setNotif);
      if (surveyRes.success) {
        setRedirect(
          <Redirect to={`/customers/${enterpriseId}/surveys/${surveyRes.data.id}`} />,
        );
      }
    } else {
      setLoading(true);
      const surveyRes = await updateSurvey(survey?.id, survey);
      surveyRes.displayNotif(setNotif);
      if (surveyRes.success) {
        onUpdate({ ...survey, ...surveyRes.data });
        setNotif({
          translated: true,
          message: t('Survey.editSuccess', 'Le questionnaire a bien été sauvegardé'),
          variant: 'success',
        });
      }
    }
  };

  useEffect(() => {
    if (actualSurvey.id) {
      setLoading(false);
    }
    setSurvey(actualSurvey);
  }, [actualSurvey]);

  return (
    <>
      <AWForm loading={loading} onSubmit={handleSubmit}>
        <Row>
          <Col lg={9}>
            <Form.Group className="mb-4">
              <Form.Label className="label" htmlFor="businessName">
                {t('SurveyGeneral.surveyName', 'Intitulé du questionnaire')}
              </Form.Label>
              <Form.Control
                id="surveyName"
                value={survey.label || ''}
                onChange={handleFieldChange('label')}
                className="roboto-regular"
                disabled={!isEnabled}
              />
              <ErrorFormField field="label" errors={errors}>
                {t('SurveyGeneral.noSurveyName', "Veuillez renseigner l'intitulé du questionnaire")}
              </ErrorFormField>
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group className="mb-4">
              <Form.Label className="label" htmlFor="country">
                {t('SurveyGeneral.country', 'Pays cible')}
              </Form.Label>
              <Form.Select
                id="country"
                value={survey.country}
                onChange={handleFieldChange('country')}
                className="roboto-regular"
                disabled={!isEnabled}
              >
                <option value="fr">{t('SurveyGeneral.france', 'France')}</option>
                <option value="de">{t('SurveyGeneral.germany', 'Allemagne')}</option>
                <option value="en">{t('SurveyGeneral.england', 'Angleterre')}</option>
              </Form.Select>
              <ErrorFormField field="country" errors={errors}>
                {t('SurveyGeneral.noCountry', 'Veuillez sélectionner le pays cible')}
              </ErrorFormField>
            </Form.Group>
          </Col>
          <Col xl={12}>
            <Form.Group className="mb-4">
              <Form.Label className="label" htmlFor="surveyDesc">
                {t('SurveyGeneral.surveyDescription', 'Description du questionnaire')}
              </Form.Label>
              <Form.Control
                as="textarea"
                id="description"
                value={survey.description || ''}
                rows={4}
                onChange={handleFieldChange('description')}
                disabled={!isEnabled}
                className="roboto-regular"
              />
              <ErrorFormField field="description" errors={errors}>
                {t('SurveyGeneral.noSurveyDesc', 'Veuillez décrire brièvement le questionnaire')}
              </ErrorFormField>
            </Form.Group>
          </Col>
          <Col lg={{ span: 4, offset: 4 }}>
            <Form.Group className="mb-4">
              <Form.Label className="label" htmlFor="surveyAnswerer">
                {t('SurveyGeneral.answerer', 'Destinataire répondant')}
              </Form.Label>
              <Form.Select
                id="answerer"
                value={survey.answerer}
                onChange={handleFieldChange('answerer')}
                className="roboto-regular"
                disabled={!isEnabled}
              >
                {
                  Object.values(AnswererTypes).map((type) => (
                    <option key={type} value={type}>{t(`SurveyGeneral.Answerers.${type}`)}</option>
                  ))
                }
              </Form.Select>
            </Form.Group>
          </Col>
          <div className="d-flex justify-content-center">
            <ActionButton
              disabled={
                !survey.label
                || (survey?.status && survey?.status !== Status.Sketch)
              }
              type="submit"
              buttonClassName={`w-25 ${!survey.label && 'opacity-50'}`}
              backgroundColor="#12957d"
              color="white"
            >
              {t('SurveyGeneral.submit', 'Enregistrer')}
            </ActionButton>
          </div>
        </Row>
      </AWForm>
      {redirect}
    </>
  );
};

SurveyGeneral.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    country: PropTypes.string,
    description: PropTypes.string,
  }),
  onUpdate: PropTypes.func.isRequired,
  creationMode: PropTypes.bool,
};

SurveyGeneral.defaultProps = {
  survey: {
    country: 'fr',
    label: '',
    description: '',
  },
  creationMode: false,
};

export default SurveyGeneral;
