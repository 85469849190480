import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container, Table, Modal,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { actionItem, getCollection, getItemsFromPartner } from '../../services/collections.service';
import ItemValue from './ItemValue';

const CollectionItems = () => {
  const { i18n } = useTranslation();
  const [collection, setCollection] = useState();
  const [items, setItems] = useState([]);
  const [preview, setPreview] = useState();
  const showPreview = (data) => {
    setPreview(data);
  };
  const { collectionId, partnerId } = useParams();
  const initCollection = async () => {
    const response = await getCollection(collectionId);
    if (response.success) setCollection(response.data);
  };
  const initItems = async () => {
    if (!collectionId) return undefined;
    const response = await getItemsFromPartner(collectionId, partnerId);
    if (response.success) setItems(response.data);
    return undefined;
  };

  useEffect(() => {
    initCollection();
  }, []);

  useEffect(() => {
    initItems();
  }, [collectionId]);

  const headers = useMemo(() => {
    const _headers = new Map();
    items.forEach((item) => {
      item.values.forEach((value) => {
        _headers.set(value.field.id, value.field);
      });
    });
    return [..._headers].sort(([, { position: a }], [, { position: b }]) => a - b);
  });

  function getCollectionLabel(_i18n, label) {
    if (typeof label === 'string') return label;
    return label ? (
      label[_i18n.language.split('-')[0]] || label.en || label.fr || ''
    ) : '';
  }

  async function refuseItem(item) {
    await actionItem(item.id, 'contredire_l_ia');
    initItems();
  }
  return (
    <>
      <Header />
      {collection
        ? (
          <Container className="collections-items-root">
            <Modal
              show={preview}
              centered
              fullscreen
              onHide={() => setPreview(null)}
            >
              <Modal.Header closeButton>
                <p>{preview?.name}</p>
              </Modal.Header>
              <Modal.Body>
                <object data={preview?.signedUrl} style={{ width: '100%', height: '100%', objectFit: 'contain' }}>
                  preview?.name
                </object>
              </Modal.Body>
            </Modal>
            <h1>
              {getCollectionLabel(i18n, collection.label)}
              {' '}
              {items?.length && (
                <>
                  (
                  {items?.length}
                  {' '}
                  {items?.length > 1 ? 'éléments' : 'élément' }
                  )
                </>
              )}
            </h1>
            <div>
              <Table responsive className="table-list">
                <thead>
                  <tr>
                    {headers.map(([key, field]) => (
                      <th key={key}>
                        {field.label.fr}
                      </th>
                    ))}
                    <th>Item id</th>
                    {collectionId === 'fde1b3bd-48c4-4dae-839d-b7ee3446b090' && (
                      <th>Contredire l&apos;IA</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {items.map((item) => (
                    <tr key={item.id}>
                      {headers.map(([headerId, field]) => {
                        const value = item.values.find((a) => a.field.id === headerId);
                        return (
                          <ItemValue
                            key={`${item.id}-${field.id}`}
                            value={value}
                            field={field}
                            itemId={item.id}
                            showPreview={showPreview}
                          />
                        );
                      })}
                      <td>{item.id}</td>
                      {collectionId === 'fde1b3bd-48c4-4dae-839d-b7ee3446b090' && (
                        <td><button type="button" onClick={() => refuseItem(item)}>Contredire</button></td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

          </Container>
        )
        : ''}
    </>
  );
};

export default CollectionItems;
