import dayjs from 'dayjs';
import analyze from 'rgbaster';
import { UUID_REGEX, RGB_REGEX } from './patterns';

export const FILE_TYPES = {
  IMAGE: 'IMAGE',
  PDF: 'PDF',
  DOCUMENT: 'DOCUMENT',
};

export const KEYBOARD_VALUES = {
  SpaceKey: 32,
  EnterKey: 13,
};

export const alphaSort = (fieldName) => (a, b) => (a[fieldName] > b[fieldName] ? 1 : -1);

export const randString = () => (Math.random() + 1).toString(36).substring(7);

export const toKey = (entity, fieldName = 'id') => (
  `${entity[fieldName] || randString()}_${entity.name || randString()}_${randString()}`
);

export const toSiren = (siret) => {
  if (!siret) return '';
  return siret.substr(0, siret.length - 5);
};

export const formatDate = (iso, format) => dayjs(iso).format(format || 'DD/MM/YYYY');
export const compareDatesWithoutTime = (
  iso1,
  iso2,
  format,
) => formatDate(iso1, format) === formatDate(iso2, format);

// To solve the issue of docusign sending a date with timezone to postgres database,
// and postgre saving date without timezone
export const formatSignedAtDate = (dateStr) => {
  const date = new Date(dateStr);
  const result = new Date(date.toISOString().slice(0, -1));
  return dayjs(result).format('DD/MM/YYYY');
};

export const getFileType = (mime) => {
  if (mime === 'application/pdf') {
    return FILE_TYPES.PDF;
  }
  if (mime.startsWith('image/')) {
    return FILE_TYPES.IMAGE;
  }
  return FILE_TYPES.DOCUMENT;
};

export const queryToString = (query) => (
  Object.keys(query).length ? `?${new URLSearchParams(query).toString()}` : ''
);

export const hexToRgb = (hex) => {
  const hexDigits = hex.slice(1); // Remove starting #
  if (hexDigits.length !== 6) {
    throw new Error('Only six-digit hex colors are allowed.');
  }

  const aRgbHex = hexDigits.match(/.{1,2}/g);
  const aRgb = [
    parseInt(aRgbHex[0], 16),
    parseInt(aRgbHex[1], 16),
    parseInt(aRgbHex[2], 16),
  ];
  return aRgb;
};

export const isRGB = (rgb) => !!(rgb.replace(/\s/g, '').match(RGB_REGEX));

export const getRGBValues = (rgb) => {
  const match = rgb.replace(/\s/g, '').match(RGB_REGEX);
  if (match) {
    return match[1].split(',');
  }
  throw new Error(`${rgb} is not a rgb format`);
};

// Accept string format : 'rgb(255, 255, 0)'
export const rgbToHex = (rgb) => {
  const colors = getRGBValues(rgb);
  const hexDigits = colors.splice(0, 3).map((color) => {
    const hex = parseInt(color, 10).toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  }).join('');
  return `#${hexDigits}`;
};

export const getImageMainColor = async (image) => {
  const result = await analyze(
    image,
    {
      ignore: ['rgb(255,255,255)', 'rgb(0,0,0)'], // Ignore black and white
      scale: 0.6, // Reduce image size for performance
    },
  );
  return rgbToHex(result[0].color);
};

export const queryBoySearch = (options) => {
  const result = {};
  if (options.search && Object.keys(options.search)?.length) {
    result.search = JSON.stringify(options.search);
  }
  if (options.fields) {
    result.fields = Array.isArray(options.fields)
      ? options.fields.join(',')
      : options.fields.replace(/\s/g, '');
  }
  return queryToString({
    ...options,
    ...result,
  });
};

export const removeFakeIds = (x) => {
  if (new RegExp(UUID_REGEX).test(x.id)) return x;
  const { id, ...withoutId } = x;
  return withoutId;
};

export const capitalize = (string) => (
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase());

export const slugify = (str) => (
  str.toLowerCase().trim().replace(/[\s|-]+/g, '_')
);

export const AWColors = {
  Green: '#12957D',
  LightGreen: '#f0fef6',
  Red: '#FF0000',
  Orange: '#E1C22A',
  Blue: '#00C2FF',
  Grey: '#3A3A3A',
  MediumGrey: '#ced4da',
  LightGrey: '#EEEEEE',
  Yellow: '#F9BA4F',
  Tomato: '#F9604F',
  Black: '#272928',
};

export const getMultiLangLabel = (_i18n, label) => {
  if (typeof label === 'string') return label;
  return label ? (
    label[_i18n.language.split('-')[0]] || label.en || label.fr || ''
  ) : '';
};
