import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EnterprisesContext } from '../../contexts/enterprises.context';
import { AlertContext } from '../../contexts/alert.context';
import {
  getEnterpriseAddress,
  getEnterpriseRepresentatives,
  getCustomers as getCustomersService,
} from '../../services/enterprise.service';
import { toKey } from '../../helpers/utils';

const VendorTab = () => {
  const [loading, setLoading] = useState(true);
  const { setNotif } = useContext(AlertContext);
  const { t } = useTranslation();
  const { enterprise } = useContext(EnterprisesContext);
  const [legalRepresentatives, setLegalRepresentatives] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    if (customers?.length
      || addresses?.length
      || legalRepresentatives?.length
      || customers?.isError
      || addresses?.isError
      || legalRepresentatives?.isError
    ) {
      setLoading(false);
    }
  }, [customers, addresses, legalRepresentatives]);

  const getCustomers = async () => {
    try {
      const customerRes = await getCustomersService(
        enterprise.identification_number,
        {
          fields: 'customer_name',
        },
      );
      customerRes.displayNotif(setNotif);
      if (customerRes.success) {
        setCustomers(customerRes.data);
      }
    } catch {
      setCustomers([{ isError: true }]);
    }
  };

  const getLegalRepresentatives = async () => {
    try {
      const representativesRes = await getEnterpriseRepresentatives(enterprise.id);
      representativesRes.displayNotif(setNotif);
      if (representativesRes.success) {
        setLegalRepresentatives(representativesRes.data);
      }
    } catch {
      setLegalRepresentatives([{ isError: true }]);
    }
  };

  const getAddresses = async () => {
    try {
      const addressesRes = await getEnterpriseAddress(
        enterprise.identification_number,
        {
          fields: 'address,additionnal_address,zipcode,town,country',
        },
      );
      addressesRes.displayNotif(setNotif);
      if (addressesRes.success) {
        setAddresses(addressesRes.data);
      }
    } catch {
      setAddresses([{ isError: true }]);
    }
  };

  useEffect(() => {
    setLoading(true);
    getLegalRepresentatives();
    getAddresses();
    getCustomers();
  }, [enterprise]);

  return (
    <div className="doc-info">
      <div className="doc-info__item text-center tab-loading" hidden={enterprise.identification_number}>
        <Spinner animation="grow" hidden={!loading} />
      </div>
      <div hidden={!enterprise.identification_number || loading}>
        <div className="doc-info__item">
          <div className="doc-info__title">
            {t('VendorTab.identificationnumber', 'Numéro de SIRET')}
          </div>
          <div className="doc-info__content">
            {enterprise.identification_number}
          </div>
        </div>
        <div className="doc-info__item">
          <div className="doc-info__title">
            {t(
              'VendorTab.registration_town',
              "Ville d'immatriculation",
            )}
          </div>
          <div className="doc-info__content">
            {enterprise.registration_town}
          </div>
        </div>
        <div className="doc-info__item">
          <div className="doc-info__title">
            {t('VendorTab.main_activity_code', 'Code APE')}
          </div>
          <div className="doc-info__content">
            {enterprise.main_activity_code}
          </div>
        </div>
        <div className="doc-info__item">
          <div className="doc-info__title">
            {t('VendorTab.address', 'Adresse')}
          </div>
          {
            addresses.map((address) => (
              <div key={toKey(address)} className="doc-info__content">
                {address.address}
                &nbsp;
                {address.additionnal_address ? `${address.additionnal_address} ` : ''}
                {address.zipcode}
                &nbsp;
                {address.town}
              </div>
            ))
          }
        </div>
        <div className="doc-info__item">
          <div className="doc-info__title">
            {t('VendorTab.legalRepresentativeId', 'Représentant Légal')}
          </div>
          {
            legalRepresentatives.map((legalRepresentative) => (
              <div key={toKey(legalRepresentative)} className="doc-info__content">
                {`${legalRepresentative?.firstname} ${legalRepresentative?.lastname}${
                  legalRepresentative?.job_title ? `, ${legalRepresentative.job_title}` : ''
                }`}
              </div>
            ))
          }
        </div>
        <div className="doc-info__item">
          <div className="doc-info__title">
            {t('VendorTab.clientsRelatedToServiceProvider', 'Clients rattachés au sous-traitant')}
          </div>
          {customers.map((customer) => (
            <div className="doc-info__content" key={customer}>
              {customer.customer_name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VendorTab;
