import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import DocumentActionButton from '../../DocumentActionButton/DocumentActionButton';

import precheckOff from '../../../assets/btnPreCheckedOff.svg';
import precheckClick from '../../../assets/btnPreCheckedClicked.svg';
import precheckOn from '../../../assets/btnPreCheckedOn.svg';
import { DocumentsContext } from '../../../contexts/documents.context';
import { ACTION_STATUS } from '../../../helpers/constants';

const PrecheckButton = ({ onChange, hasChanged }) => {
  const { t } = useTranslation();
  const { document } = useContext(DocumentsContext);

  const handleClick = () => {
    onChange(ACTION_STATUS.precheck);
  };

  const icon = useMemo(() => {
    // This condition prevent button blink when submitted
    if (document?.is_pre_check) {
      return precheckOn;
    }

    if (hasChanged) {
      return document?.is_pre_check ? precheckOff : precheckClick;
    }
    return precheckOff;
  }, [document, hasChanged]);

  return (
    <DocumentActionButton
      icon={icon}
      action="precheck"
      onClick={handleClick}
      tooltip={
        document?.is_pre_check
          ? t('DocumentAction.removePrecheck')
          : t('DocumentAction.addPrecheck')
      }
    />
  );
};

PrecheckButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  hasChanged: PropTypes.bool.isRequired,
};

export default PrecheckButton;
