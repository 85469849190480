import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { Dropdown } from 'react-bootstrap';

import ActionButton from '../ActionButton/ActionButton';
import arrow from '../../assets/dropdown-arrow-grey.svg';
import cross from '../../assets/alert-cross.svg';
import check from '../../assets/alert-check.svg';
import { DocumentsContext } from '../../contexts/documents.context';
import { FileContext } from '../../contexts/file.context';

/**
 * Comment is a Component allowing you to leave a comment and a visibility option.
 * @version 1
 * @since 10/12/2021
 */

const Comment = () => {
  const { t } = useTranslation();
  const [commentVisibility, setCommentVisibility] = useState('protected');
  const [comment, setComment] = useState('');
  const placeholder = t('Comment.placeholder', 'Ecrivez votre texte ici ');
  const { document } = useContext(DocumentsContext);
  const { postComment, alertPostComment, setAlertPostComment } = useContext(FileContext);
  const { REACT_APP_VERSION } = process.env;

  const getVisibilityText = (visibility) => {
    switch (visibility) {
      case 'protected':
        return t('Comment.protected', 'Protégé');
      case 'public':
        return t('Comment.public', 'Public');
      case 'private':
        return t('Comment.private', 'Privé');
      default:
        return t('Comment.protected', 'Protégé');
    }
  };

  const submitComment = () => {
    if (comment === '' || commentVisibility === null) {
      setAlertPostComment({
        type: 'error',
        message: t('Comment.missingFields', 'Veuillez renseigner tous les champs svp'),
      });
    } else {
      const commentInfo = {
        commentable_id: document.document_id,
        commentable_type: 'App\\Models\\Addworking\\Enterprise\\Document',
        content: comment,
        visibility: commentVisibility,
      };
      postComment(commentInfo);
      setCommentVisibility('protected');
      setComment('');
    }
  };

  useEffect(() => (
    setAlertPostComment({})
  ), [document]);

  const handleComment = (e) => {
    setComment(e.target?.value);
    setAlertPostComment({});
  };

  return (
    <div>
      <h3>{t('Comment.title', 'Ajouter un commentaire')}</h3>
      <Form className="mb-4">
        <Form.Label className="label">
          {t('Comment.comment', 'Commentaire *')}
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          className="text-input comment-input"
          placeholder={placeholder}
          value={comment}
          onChange={handleComment}
        />
        <Form.Label className="label">
          {t('Comment.visibility', 'Visibilité *')}
        </Form.Label>
        <Dropdown>
          <Dropdown.Toggle
            variant="btn-sm"
            id="dropdown-basic"
            className="comment-input visibility-list d-flex"
          >
            <span>
              {getVisibilityText(commentVisibility)}
            </span>
            <span>
              <img
                src={arrow}
                alt="arrow down"
                className="arrow-dropdown"
              />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setCommentVisibility('public');
              }}
            >
              {getVisibilityText('public')}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setCommentVisibility('private');
              }}
            >
              {getVisibilityText('private')}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setCommentVisibility('protected');
              }}
            >
              {getVisibilityText('protected')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className="d-flex justify-content-end">
          {!!alertPostComment.type && (
            <div className={`alert-comment alert-comment__${alertPostComment.type === 'error' ? 'red' : 'green'} mt-2`}>
              <img
                src={alertPostComment.type === 'error' ? cross : check}
                alt={alertPostComment.type === 'error' ? 'cross' : 'check'}
              />
              {alertPostComment.message}
            </div>
          )}
          <ActionButton
            type="button"
            buttonClassName="submit-button"
            onClick={submitComment}
            backgroundColor="#000000"
            color="#FFFFFF"
          >
            {t('Comment.button', 'Valider')}
          </ActionButton>
        </div>
        <br />
        <div className="doc-info__content d-flex justify-content-end">
          {`V: ${REACT_APP_VERSION}`}
        </div>
      </Form>

    </div>
  );
};
export default Comment;
