import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';

import { useTranslation } from 'react-i18next';
import { Form, Dropdown } from 'react-bootstrap';
import arrow from '../../assets/dropdown-arrow-grey.svg';

import ActionButton from '../ActionButton/ActionButton';
import ArrayInput from './Inputs/ArrayInput';
import QuestionType from '../QuestionType/QuestionType';
import { FORMATS, TYPES } from '../../helpers/constants';
import NumberInput from './Inputs/NumberInput';
import { SurveyContext } from '../../contexts/survey.context';
import Tooltip from '../Tooltip/Tooltip';
import info from '../../assets/info.svg';
import { Status } from '../StatusTag/StatusTag';

/**
 * Input is a Component to add a question in a questionnaire.
 * @version 1
 * @since 11/04/2022
 */

const QuestionInput = ({ onSubmit, disabled }) => {
  const { t } = useTranslation();
  const {
    question, setQuestion, reset, survey,
  } = useContext(SurveyContext);
  const [alerts, setAlerts] = useState([]);
  const checkErrors = () => {
    const errors = [];
    if (question.format === FORMATS.File && question?.options) {
      handleChange('options')({
        min: null,
        max: null,
      });
    }
    if (!question.title) {
      errors.push(t('Alert.missingFields', 'Merci de renseigner tous les champs requis'));
    }
    if (!question.type) {
      errors.push(t('Alert.missingType', 'Merci de sélectionner un format de réponse'));
    }
    if (question.type === TYPES.Number && question.options?.min > question.options?.max) {
      errors.push(t('Alert.minAboveMax', 'La valeur min doit être inférieure à la valeur max'));
    }
    if (question.type === TYPES.String && question.options?.min > question.options?.max) {
      errors.push(t('Alert.minAboveMax', 'La valeur min doit être inférieure à la valeur max'));
    }
    if (question.type === TYPES.Array) {
      if (question.items?.length < 2) {
        errors.push(t('Alert.minOptions', 'Merci de renseigner au moins 2 options'));
      }
      if (
        [FORMATS.Multi, FORMATS.Checkbox].includes(question.format)
        && (
          question.options?.min
          && question.options?.max
          && question.options?.min > question.options?.max
        )
      ) {
        errors.push(t('Alert.minChoiceAboveMax', 'Le nombre de réponse maximum doit être supérieur au nombre de réponse minimum'));
      }
      const values = question.items.map((el) => el.value.toLowerCase());
      if (uniq(values).length < values.length) {
        errors.push(t('Alert.dupplicate', 'Veuillez supprimer les doublons dans les réponses proposées'));
      }
    }
    return errors;
  };

  const questionsType = useMemo(() => ([
    {
      type: TYPES.Array,
    },
    {
      type: TYPES.String,
    },
    {
      type: TYPES.String,
      format: FORMATS.Date,
    },
    {
      type: TYPES.Boolean,
    },
    {
      type: TYPES.String,
      format: FORMATS.File,
    },
    {
      type: TYPES.Number,
    },
  ]), []);

  const isQuestionTypeActive = useCallback((questionType) => {
    if (question?.type === TYPES.Array) {
      return question?.type === questionType.type;
    }
    return question?.type === questionType.type
      && question?.format === questionType.format;
  }, [question]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = checkErrors();
    if (errors.length) {
      setAlerts(errors);
    } else {
      onSubmit();
    }
  };

  const addInput = (type, format) => () => {
    setQuestion({
      type,
      format,
      options: {
        ...(question.options || {}), min: undefined, max: undefined,
      },
    });
  };

  const handleChange = (fieldName) => (value) => {
    if (
      (typeof value === 'string' && value !== ' ')
      || (typeof value !== 'string')
    ) {
      setQuestion({ [fieldName]: value });
    }
  };

  const handleInputChange = (fieldName) => ({ target: { value } }) => {
    if (value !== ' ') setQuestion({ [fieldName]: value });
  };

  const handleFormat = (format) => () => {
    setQuestion({ format });
  };

  const handleRequired = () => {
    setQuestion({ options: { ...question.options, required: !question.options?.required } });
  };

  useEffect(() => {
    setAlerts([]);
  }, [question]);

  useEffect(() => () => {
    reset(null);
  }, []);

  const handleOptions = (optionName) => ({ target: { value } }) => {
    handleChange('options')({
      ...question.options,
      [optionName]: value,
    });
  };

  const getDropdownTitle = (format) => {
    switch (format) {
      case FORMATS.Multi:
        return t('Input.dropdownlistmultiple', 'Choix multiple : Liste');
      case FORMATS.Checkbox:
        return t('Input.checkbox', 'Choix multiple : Checkbox');
      case FORMATS.Select:
        return t('Input.dropdownlistonechoice', 'Choix unique : Liste');
      default:
        return t('Input.radio', 'Choix unique : Bouton radio');
    }
  };

  return (
    <div className="input m-4">
      <Form className="mb-4 mt-4" onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between">
          <div className="long-label">
            <Form.Label className="label">
              {t('Input.title', 'Libellé de la question')}
            </Form.Label>
            <Form.Control
              type="text"
              className="form-input roboto-regular bg-green"
              placeholder={t('Input.placeholder', 'Rédigez votre question ici ')}
              value={question?.title || ''}
              onChange={handleInputChange('title')}
              required
              disabled={disabled}
            />
          </div>
          <div className="short-label">
            <Form.Label className="label">
              <div className="d-flex">
                {t('Input.shortTitle', 'Libellé court')}
                <img src={info} alt="info" className="info-icon" data-tip="react-tooltip" data-for="short-label" />
              </div>
            </Form.Label>
            <Form.Control
              type="text"
              className="form-input roboto-regular"
              placeholder={t('Input.shortTitle', 'Libellé court')}
              value={question?.shortTitle || ''}
              onChange={handleInputChange('shortTitle')}
              maxLength="20"
              required
              disabled={disabled}
            />
            <Tooltip
              id="short-label"
            >
              {t('Input.shortTitleTooltip', 'Libellé utilisé comme titre de colonne dans le tableau des réponses')}
            </Tooltip>
          </div>
        </div>
        <div className="separator mt-4" />

        <div className="d-flex justify-content-between">
          <div>
            <Form.Label className="label">
              {t('Input.answerformat', 'Format de réponse')}
            </Form.Label>
            <div className="survey__questions">

              {
                questionsType.map((questionType) => (
                  <QuestionType
                    key={`${questionType.type}-${questionType.format}`}
                    type={questionType.type}
                    format={questionType.format}
                    disabled={survey.status !== Status.Sketch}
                    onClick={addInput(questionType.type, questionType.format)}
                    isActive={isQuestionTypeActive(questionType)}
                  />
                ))
              }
            </div>
          </div>

          <div>
            {question?.type === TYPES.Array && (
              <div className="d-flex justify-content-between">
                <div className="dropdown-format">
                  <Form.Label className="label">
                    {t('Input.formatoptions', 'Mise en forme')}
                  </Form.Label>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="btn-sm"
                      id="dropdown-basic"
                      className="comment-input visibility-list d-flex"
                      disabled={disabled}
                    >
                      <span>
                        {getDropdownTitle(question?.format)}
                      </span>
                      <span>
                        <img
                          src={arrow}
                          alt="arrow down"
                          className="arrow-dropdown"
                        />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={handleFormat()}
                      >
                        {getDropdownTitle()}

                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleFormat(FORMATS.Select)}
                      >
                        {getDropdownTitle(FORMATS.Select)}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleFormat(FORMATS.Checkbox)}
                      >
                        {getDropdownTitle(FORMATS.Checkbox)}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleFormat(FORMATS.Multi)}
                      >
                        {getDropdownTitle(FORMATS.Multi)}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            )}
          </div>
        </div>

        {
          (question?.format === FORMATS.Multi || question?.format === FORMATS.Checkbox)
          && (
            <>
              <div className="separator" />
              <div className="d-flex justify-content-between">
                <div>
                  <Form.Label className="label">
                    {t('Input.minAnswer', 'Nombre min de choix')}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    className="form-input mb-2 w-50"
                    placeholder={t('Input.min', 'Minimum ')}
                    value={question?.options?.min || ''}
                    onChange={handleOptions('min')}
                    disabled={disabled}
                  />
                </div>
                <div className="text-right">
                  <Form.Label className="label">
                    {t('Input.maxAnswer', 'Nombre max de choix')}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    className="form-input mb-2 w-50 right-align roboto-regular"
                    placeholder={t('Input.choices-max', 'Maximum ')}
                    value={question?.options?.max || ''}
                    onChange={handleOptions('max')}
                    disabled={disabled}
                  />
                </div>
              </div>
            </>
          )
        }
        {
          question?.type === TYPES.Array ? (
            <ArrayInput
              items={question?.items}
              options={question?.options}
              onChange={handleChange('items')}
              onOptionsChange={handleChange('options')}
              format={question?.format}
              disabled={disabled}
            />
          ) : ''
        }

        {
          (question?.type === TYPES.Number) ? (
            <NumberInput
              options={question?.options}
              onOptionsChange={handleChange('options')}
              disabled={disabled}
            />
          ) : ''
        }

        {
          (question?.type === TYPES.String && !question?.format) ? (
            <div className="text-right w-50 right-align">
              <div className="separator mt-4 " />
              <Form.Label className="label text-right">
                {t('Input.max-characters', 'Nombre maximum de caractères')}
              </Form.Label>
              <Form.Control
                type="number"
                className="form-input w-25 right-align roboto-regular"
                placeholder={t('Input.maximum', 'Maximum ')}
                value={question?.options?.max || ''}
                onChange={handleOptions('max')}
                disabled={disabled}
              />
            </div>
          ) : ''
        }

        {
          (question?.type === TYPES.String && question?.format === FORMATS.Date) ? (
            <>
              <div className="separator mt-4" />
              <div className="d-flex justify-content-between">
                <div>
                  <Form.Label className="label">
                    {t('Input.min-date', 'Date minimum')}
                  </Form.Label>
                  <Form.Control
                    type="date"
                    className="form-input roboto-regular"
                    value={question?.options?.min || ''}
                    onChange={handleOptions('min')}
                    disabled={disabled}
                  />
                </div>
                <div className="text-right">
                  <Form.Label className="label">
                    {t('Input.max-date', 'Date maximum')}
                  </Form.Label>
                  <Form.Control
                    type="date"
                    className="form-input roboto-regular"
                    value={question?.options?.max || ''}
                    onChange={handleOptions('max')}
                    disabled={disabled}
                  />
                </div>

              </div>
            </>
          ) : ''
        }

        <div className="d-flex justify-content-end">
          <Form.Check
            type="switch"
            label={t('Input.isRequired', 'Obligatoire ?')}
            onChange={handleRequired}
            className="switch-required"
            checked={!!question?.options?.required}
            disabled={disabled}
          />
        </div>

        {
          alerts.map((alert) => (
            <div className="alert-comment__red text-right" key={alert.toLowerCase().replace(/\s/g, '')}>
              {alert}
            </div>
          ))
        }
        <div className="d-flex justify-content-between mt-4 pb-4">
          <ActionButton
            type="button"
            onClick={reset}
            buttonClassName="submit-button"
            backgroundColor="#000000"
            color="#ffffff"
          >
            {t('Input.cancel', 'Annuler')}
          </ActionButton>
          <ActionButton
            type="submit"
            buttonClassName="submit-button"
            backgroundColor="#000000"
            color="#ffffff"
            disabled={disabled}
          >
            {t('Input.validate', 'Valider')}
          </ActionButton>
        </div>
      </Form>

    </div>
  );
};

QuestionInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

QuestionInput.defaultProps = {
  disabled: false,
};

export default QuestionInput;
