import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Row, Container, Table,
} from 'react-bootstrap';
import Header from '../../components/Header/Header';
import { createCollectionDocType, deleteCollectionDocType, getCollectionDoctype } from '../../services/collections.service';
import { AlertContext } from '../../contexts/alert.context';
import DialogModal from '../../components/DialogModal/DialogModal';

const DoctypesCollections = () => {
  const { t } = useTranslation();
  const { setNotif } = useContext(AlertContext);

  const [doctypeCollections, setDoctypeCollections] = useState([]);
  const [show, setShow] = useState(false);
  const [doctypeIdToInsert, setDoctypeIdToInsert] = useState('');
  const [collectionIdToInsert, setCollectionIdToInsert] = useState('');

  const getDoctypeCollections = async () => {
    const response = await getCollectionDoctype();
    if (response.success) {
      setDoctypeCollections(response.data);
    }
  };
  const deleteDoctypeCollections = async (id) => {
    await deleteCollectionDocType(id);
    await getDoctypeCollections();
  };

  useEffect(() => {
    getDoctypeCollections();
  }, []);
  const columns = [
    { title: t('DocTypeCollection.docTypeName'), key: 'docTypeName' },
    { title: t('DocTypeCollection.collectionName'), key: 'collectionName' },
    { title: t('DocTypeCollection.delete'), key: 'delete' },
  ];
  async function notify(response, successMessage) {
    const translateError = {
      COLAPI017: 'La relation existe déjà',
    };
    if (!response.success) {
      setNotif({
        message: translateError[response?.data?.customCode]
        || response?.data?.message
        || 'Erreur inconnue',
      });
    } else if (successMessage) {
      setNotif({
        message: successMessage,
        variant: 'success',
      });
    }
  }
  async function addLine() {
    setShow(false);
    notify(await createCollectionDocType({
      collection_id: collectionIdToInsert,
      document_type_id: doctypeIdToInsert,
    }));
    await getDoctypeCollections();
  }
  return (
    <>
      <Header />
      <Container>
        <Row>

          <DialogModal
            show={show}
            onHide={() => setShow(false)}
            heading={t('DocTypeCollection.add_link')}
            btnText={t('Button.confirm')}
            onSubmit={() => addLine()}
            btnDisabled={!doctypeIdToInsert || !collectionIdToInsert}
          >
            <form>
              <div className="form-group">
                <label className="text-secondary" htmlFor="doctypeIdToInsert">{t('DocTypeCollection.document_type_id')}</label>
                <input
                  id="doctypeIdToInsert"
                  className="form-control"
                  type="text"
                  placeholder={`${t('DocTypeCollection.document_type_id')}...`}
                  value={doctypeIdToInsert}
                  onChange={(e) => setDoctypeIdToInsert(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="text-secondary" htmlFor="collectionIdToInsert">{t('DocTypeCollection.collection_id')}</label>
                <input
                  id="collectionIdToInsert"
                  className="form-control"
                  type="text"
                  value={collectionIdToInsert}
                  placeholder={`${t('DocTypeCollection.collection_id')}...`}
                  onChange={(e) => setCollectionIdToInsert(e.target.value)}
                />
              </div>
            </form>

          </DialogModal>
          <Col xs={12} className="doctypes-collections-root">
            <div className="heading">
              <h1>{t('DocTypeCollection.title')}</h1>
              <div>
                <button className="btn btn-primary" type="button" onClick={() => setShow(true)}>
                  Ajouter une relation
                </button>
              </div>
            </div>
            <Table responsive className="table-list">
              <thead>
                <tr>
                  {columns.map(({ title, key }) => (
                    <th
                      key={key}
                      style={{ backgroundColor: '#fff' }}
                    >
                      {title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {doctypeCollections.map((doc) => (
                  <tr key={`${doc.doctype?.id}-${doc.collection?.id}`}>
                    <td>
                      {JSON.parse(doc.doctype?.display_name || '{}')?.fr}
                    </td>
                    <td>
                      {doc.collection?.label?.fr}
                    </td>
                    <td>
                      <button type="button" onClick={() => deleteDoctypeCollections(doc.id)}>{t('DocTypeCollection.delete')}</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DoctypesCollections;
