import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import { getEnterprisesWithDifferencesNotAccepted, getStatsEnterprisesWithDifferencesNotAccepted } from '../../services/enterprise.service';

const Enterprises = () => {
  const { t } = useTranslation();
  const fieldTranslation = {
    activity_id: t('Enterprise.activity_id'),
    identification_number: t('Enterprise.identification_number'),
    name: t('Enterprise.name'),
    website: t('Enterprise.website'),
    main_activity_code: t('Enterprise.main_activity_code'),
    is_headquarter: t('Enterprise.is_headquarter'),
    registration_town: t('Enterprise.registration_town'),
    registration_date: t('Enterprise.registration_date'),
    share_capital: t('Enterprise.share_capital'),
    creation_date: t('Enterprise.creation_date'),
    logo_id: t('Enterprise.logo'),
    legal_form: t('Enterprise.legal_form'),
    legal_form_id: t('Enterprise.legal_form_id'),
    tax_identification_number: t('Enterprise.tax_identification_number'),
    latitude: t('Enterprise.latitude'),
    longitude: t('Enterprise.longitude'),
    cessation_date: t('Enterprise.cessation_date'),
    has_website: t('Enterprise.has_website'),
    additionnal_address: t('Enterprise.additionnal_address'),
    country: t('Enterprise.country'),
    address: t('Enterprise.address'),
    zipcode: t('Enterprise.zipcode'),
    town: t('Enterprise.town'),
    year: t('Enterprise.year'),
    amount: t('Enterprise.amount'),
    activity: t('Enterprise.activity'),
    employees_count: t('Enterprise.employees_count'),
  };
  const [error, setError] = useState();
  const [enterprises, setEnterprises] = useState();
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const history = useHistory();
  function displayError(data, messageToDisplay) {
    setError(messageToDisplay);
    throw data;
  }

  /** @param {import('../../helpers/response-handler').ApiResponse | null} data */
  function throwErrorOnAPIReject(data) {
    if (data.success) return data;
    return Promise.reject(data);
  }

  async function reload() {
    setError(null);
    const { data: enterprisesDifferences } = await getEnterprisesWithDifferencesNotAccepted({
      limit: 100,
      q: search,
    })
      .then(throwErrorOnAPIReject)
      .catch((err) => {
        displayError(err, t('Enterprise.Upgrades.error_get_differences'));
        throw err;
      });
    setEnterprises(enterprisesDifferences);

    const { data: totalEnterprises } = await getStatsEnterprisesWithDifferencesNotAccepted()
      .then(throwErrorOnAPIReject)
      .catch((err) => {
        displayError(err, t('Enterprise.Upgrades.error_get_differences'));
        throw err;
      });
    setTotal(totalEnterprises);
  }
  useEffect(() => {
    reload();
  }, [search]);
  function goToEnterprise(enterpriseId) {
    history.push(`/enterprises/${enterpriseId}`);
  }
  return !error ? (
    <div className="container-fluid enterprise-root">
      <div className="row">
        <Header />
        <div className="main-container">
          <h1>Les entreprises ayant des modifications</h1>
          <input onInput={(ev) => setSearch(ev.target.value)} placeholder="Rechercher une entreprise..." />
          <div className="table-container">
            <table className="table">
              <thead className="table-content">
                <tr>
                  <th className="width-enterprise table-titles vertical-align-middle">
                    {t('Enterprise.Upgrades.identification_number')}
                  </th>
                  <th className="width-enterprise table-titles vertical-align-middle">
                    {t('Enterprise.Upgrades.name')}
                  </th>
                  <th className="width-nbFields table-titles">
                    {t('Enterprise.Upgrades.fields_to_review')}
                  </th>
                </tr>
              </thead>
              <tbody
                id="row-container-vendor-list"
              >
                {enterprises?.length ? enterprises?.map((vendor) => (
                  <tr
                    key={vendor?._id || ''}
                    className="table-row hover-green"
                    onClick={() => goToEnterprise(vendor._id)}
                  >
                    <td className="width-enterprise light-grey">
                      {vendor.enterprise?.identification_number}
                    </td>
                    <td className="width-enterprise light-grey">
                      {vendor.enterprise?.name}
                    </td>
                    <td className="width-nbFields light-grey">
                      <div>
                        {t('Enterprise.Upgrades.fields')}
                        :
                        {' '}
                        {vendor.fields?.map((f) => fieldTranslation[f] || f)?.join(', ')}
                      </div>
                      <br />
                      <div>
                        {vendor?.fields?.length}
                        {' '}
                        {t('Enterprise.Upgrades.fields_to_review')}
                      </div>
                    </td>
                  </tr>
                )) : (
                  <tr>
                    <td className="light-grey center">
                      {t('Enterprise.Upgrades.nothing_to_review')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="stats">
            <div className="card-stat">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="cursor-pointer"
              />
              Total
              {' '}
              {total}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <Header />
      <div className="error">
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="cursor-pointer"
        />
        {error}
      </div>
    </div>
  );
};

export default Enterprises;
