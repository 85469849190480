import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Dropdown } from 'react-bootstrap';

import { DocumentsContext } from '../../../contexts/documents.context';
import { FileContext } from '../../../contexts/file.context';
import ActionButton from '../../ActionButton/ActionButton';
import arrow from '../../../assets/dropdown-arrow-grey.svg';
import ActionMessage from '../ActionMessage';
import usePrevious from '../../../hooks/usePrevious';

const FormReject = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { document } = useContext(DocumentsContext);
  const [formError, setFormError] = useState('');
  const { getDocumentRefusalReasons, refusalReasons } = useContext(FileContext);
  const [reason, setReason] = useState({});
  const prevDocument = usePrevious(document);

  useEffect(() => {
    if (prevDocument?.document_id !== document?.document_id) {
      getDocumentRefusalReasons(document?.type_id);
    }
  }, [document]);

  const selectReason = (refusalReason) => () => {
    setReason(refusalReason);
  };

  const handleMessageChange = (e) => {
    setReason({
      ...reason,
      message: e?.target?.value,
    });
  };

  const submitReject = async () => {
    if (!reason.display_name || !reason.message) {
      setFormError(
        t(
          'DocumentAction.alertmissingfield',
          'Veuillez remplir tous les champs ',
        ),
      );
    } else {
      onSubmit(reason);
    }
  };

  return (
    <Form className=" mt-4">
      <Form.Label className="label">
        {t('DocumentAction.reasonrefusal', 'Raison du rejet *')}
      </Form.Label>
      <Dropdown>
        <Dropdown.Toggle
          variant="btn-sm"
          id="dropdown-basic"
          className="comment-input visibility-list d-flex"
        >
          <span>
            {
              reason?.display_name
                || t('DocumentAction.reasonchoice', 'Choisir la raison dans la liste')
            }
          </span>
          <span>
            <img src={arrow} alt="arrow down" className="arrow-dropdown" />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {refusalReasons.map((refusalReason) => (
            <Dropdown.Item
              onClick={selectReason(refusalReason)}
            >
              {refusalReason.display_name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Form.Label className="label">
        {t('DocumentAction.comment', 'Commentaire *')}
      </Form.Label>
      <Form.Control
        as="textarea"
        rows={5}
        className="text-input comment-input"
        placeholder={t(
          'DocumentAction.textarearejection',
          "Saisie manuelle d'une raison",
        )}
        value={reason.message}
        onChange={handleMessageChange}
      />
      <div
        className={
          `d-flex ${formError ? 'justify-content-between' : 'justify-content-end'}`
        }
      >
        {
          formError && (
            <ActionMessage message={formError} type="error" />
          )
        }
        <ActionButton
          type="button"
          buttonClassName="submit-button"
          onClick={submitReject}
        >
          {t('Comment.button', 'Valider')}
        </ActionButton>
      </div>
    </Form>
  );
};

FormReject.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default FormReject;
