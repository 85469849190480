import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Auth } from 'aws-amplify';
import './styles/css/style.css';
import Routes from './routes/backofficeRoutes';
import CombinedContextProvider from './contexts/combined.context';
import AddworkingAlert from './components/AddworkingAlert/AddworkingAlert';

const {
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_USERPOOLID,
  REACT_APP_AWS_USERPOOLWEBCLIENTID,
  REACT_APP_AWS_COGNITO,
  REACT_APP_HOST_URL,
} = process.env;

Auth.configure({
  region: REACT_APP_AWS_REGION,
  userPoolId: REACT_APP_AWS_USERPOOLID,
  userPoolWebClientId: REACT_APP_AWS_USERPOOLWEBCLIENTID,
  oauth: {
    domain: REACT_APP_AWS_COGNITO,
    redirectSignIn: `${REACT_APP_HOST_URL}/dashboard`,
    redirectSignOut: REACT_APP_HOST_URL,
    responseType: 'token',
  },
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
};

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize(tagManagerArgs);
}

const App = () => (
  <CombinedContextProvider>
    <DndProvider backend={HTML5Backend}>
      <AddworkingAlert />
      <Router>
        <div className="h-100-vh">
          <Routes />
        </div>
      </Router>
    </DndProvider>
  </CombinedContextProvider>
);

export default App;
