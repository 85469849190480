import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AnimatedButton = ({ children, icon }) => (
  <button type="button" className="animated-button">
    <svg xmlns="http://www.w3.org/2000/svg" className="arr-2" viewBox="0 0 24 24">
      {icon ? (
        <FontAwesomeIcon icon={icon} />
      ) : (
        <path
          d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
        />
      )}
    </svg>
    <span className="text">{children}</span>
    <span className="circle" />
    <svg xmlns="http://www.w3.org/2000/svg" className="arr-1" viewBox="0 0 24 24">
      <path
        d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
      />
    </svg>
  </button>
);

AnimatedButton.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.any,
};

AnimatedButton.defaultProps = {
  icon: undefined,
};

export default AnimatedButton;
