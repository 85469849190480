import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '../helpers/ProtectedRoute';
import CustomerConnectionConfigs from '../views/ConnectionConfigs/CustomerConnectionConfigs';
import CustomersConnectionConfigs from '../views/ConnectionConfigs/CustomersConnectionConfigs';
import CustomersSurveys from '../views/CustomersSurveys/CustomersSurveys';
import CustomerSurveys from '../views/CustomerSurveys/CustomerSurveys';
import ConnectionConfig from '../views/ConnectionConfig/ConnectionConfig';
import Survey from '../views/Survey/Survey';

const CustomerListRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <ProtectedRoute path={`${path}/surveys`} exact>
        <CustomersSurveys />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/:enterpriseId/surveys`} exact>
        <CustomerSurveys />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/:enterpriseId/surveys/new`}>
        <Survey />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/:enterpriseId/surveys/:surveyId`}>
        <Survey />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/configs`} exact>
        <CustomersConnectionConfigs />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/:enterpriseId/configs`} exact>
        <CustomerConnectionConfigs />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/:enterpriseId/configs/new`}>
        <ConnectionConfig />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/:enterpriseId/configs/:configId`}>
        <ConnectionConfig />
      </ProtectedRoute>
    </Switch>
  );
};

export default CustomerListRoutes;
