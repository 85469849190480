import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import PropTypes from 'prop-types';

const AMPieChart = ({
  data, valueField, categoryField, handleSelectOnGraph, showLegend, bigChart = false,
}) => {
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    const root = am5.Root.new(chartRef.current);
    root.setThemes([am5themesAnimated.new(root)]);

    const container = root.container.children.push(am5.Container.new(root, {
      layout: root.horizontalLayout,
      width: am5.percent(100),
      height: am5.percent(100),
    }));

    const chart = container.children.push(am5percent.PieChart.new(root, {
      layout: root.verticalLayout,
      width: am5.percent(50),
      clickTarget: 'none',
    }));

    const series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField,
      categoryField,
    }));

    series.slices.template.setAll({
      tooltipText: '{category}: {value}',
      cursorOverStyle: 'pointer',
      active: false,
    });

    series.slices.template.states.create('active', { property: 'scale', value: 1.1 });

    series.slices.template.events.on('click', (ev) => {
      const slice = ev.target;
      const isActive = slice.get('active');
      series.slices.each((item) => {
        item.set('active', false);
        item.set('scale', 1);
      });

      setTimeout(() => {
        if (!isActive) {
          slice.set('active', true);
          slice.set('scale', 1.1);
          const enterpriseId = slice.dataItem.dataContext.enterprise_id;
          handleSelectOnGraph(enterpriseId);
        } else {
          slice.set('active', false);
          slice.set('scale', 1.0);
          handleSelectOnGraph(undefined);
        }
      }, 10);
    });

    series.data.setAll(data);
    series.labels.template.set('forceHidden', true);
    series.ticks.template.set('forceHidden', true);
    if (showLegend) {
      const legend = container.children.push(am5.Legend.new(root, {
        width: am5.percent(bigChart ? 50 : 20),
        centerY: am5.percent(0),
        layout: root.verticalLayout,
        clickTarget: 'none',
      }));

      legend.data.setAll(series.dataItems);
      legend.markers.template.setAll({
        width: 20,
        height: 20,
        strokeOpacity: 0,
        cornerRadiusTL: 50,
        cornerRadiusTR: 50,
        cornerRadiusBL: 50,
        cornerRadiusBR: 50,
      });

      legend.labels.template.setAll({
        text: '[bold]{category}[/]: {value}',
      });
      legend.labels.template.set('cursorOverStyle', 'default');
      legend.labels.template.set('cursorStyle', 'default');
      legend.labels.template.set('cursorDownStyle', 'default');
    }

    return () => {
      root.dispose();
    };
  }, [data, valueField, categoryField, handleSelectOnGraph, showLegend]);

  return <div ref={chartRef} style={{ width: '100%', height: bigChart ? '500px' : '65px' }} />;
};

AMPieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  })),
  valueField: PropTypes.string,
  categoryField: PropTypes.string,
  showLegend: PropTypes.bool,
  handleSelectOnGraph: PropTypes.func,
  bigChart: PropTypes.bool,
};

AMPieChart.defaultProps = {
  data: [
    { category: 'Category 1', value: 100 },
    { category: 'Category 2', value: 200 },
    { category: 'Category 3', value: 300 },
  ],
  valueField: 'value',
  categoryField: 'category',
  showLegend: true,
  bigChart: false,
  handleSelectOnGraph: () => {},
};

export default AMPieChart;
