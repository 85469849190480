import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const MAX_SIZE = (1024 * 1000) * 5; // 5Mo
const MIME_ACCEPT = ['image/*', 'application/pdf'];
const MIME_REGEXP = new RegExp(MIME_ACCEPT.join('|').replace('*', '.*'));

const UploadFile = ({ onErrorMessage, onSubmit }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState();

  const handleFile = (e) => {
    const files = e?.target?.files;
    if (e?.target && files?.length) {
      if (files[0].size > MAX_SIZE) {
        onErrorMessage(
          t(
            'File.Errors.maxSize',
            'Fichier trop lourd, max: {{max}}',
            {
              max: '5Mo',
            },
          ),
        );
        e.target.value = '';
      } else if (!files[0].type.match(MIME_REGEXP)) {
        onErrorMessage(
          t(
            'File.Errors.wrongMime',
            'Type de fichier invalide, types valide : {{types}}',
            {
              types: MIME_ACCEPT.join(', '),
            },
          ),
        );
        e.target.value = '';
      } else {
        setFile(files[0]);
      }
    }
  };

  const handleSubmit = () => {
    if (file && file instanceof File) {
      onSubmit(file);
    }
  };

  return (
    <div className="bo-file">
      <div className="bo-file-upload">
        {
          file && file instanceof File ? (
            <div className="bo-file-upload__text">{file.name}</div>
          ) : (
            <div className="bo-file-upload__text">
              {
                t(
                  'File.select',
                  'Glisser / déposer votre fichier ici ou cliquer pour parcourir votre ordinateur',
                )
              }
            </div>
          )
        }
        <input type="file" onChange={handleFile} accept={MIME_ACCEPT.join(', ')} />
      </div>
      {
        file && file instanceof File && (
          <div className="bo-file-submit">
            <Button className="bo-file-submit__button" onClick={handleSubmit}>{t('Button.confirm', 'Confirmer')}</Button>
          </div>
        )
      }
    </div>
  );
};

UploadFile.propTypes = {
  /** Error event when file is not valid
   * @param {string} type can be max_file or wrong_mime
  */
  onErrorMessage: PropTypes.func,
  /** Submit event
   * @param {File} file the uploaded file
   */
  onSubmit: PropTypes.func,
};

UploadFile.defaultProps = {
  onErrorMessage: () => {},
  onSubmit: () => {},
};

export default UploadFile;
