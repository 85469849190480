import 'react-json-pretty/themes/monikai.css';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Spinner } from 'react-bootstrap';
import JSONPretty from 'react-json-pretty';
import { useParams } from 'react-router-dom';
import { JSONPrettyMon } from 'react-json-pretty/dist/monikai';
import { useTranslation } from 'react-i18next';
import * as DocumentService from '../../services/document.service';
import Header from '../../components/Header/Header';
import { AlertContext } from '../../contexts/alert.context';
import ActionButton from '../../components/ActionButton/ActionButton';
import { getEnterprises } from '../../services/enterprise.service';
import { EnterprisesContext } from '../../contexts/enterprises.context';

const UrssafJson = () => {
  const { t } = useTranslation();
  const { setNotif } = useContext(AlertContext);
  const { setEnterprise, enterprise } = useContext(EnterprisesContext);
  const { documentId, enterpriseId } = useParams();
  const [data, setData] = useState(null);
  const [documentApi, setDocumentApi] = useState(null);

  const getJsonFileForDocument = async () => {
    const [enterpriseRes, documentRes] = await Promise.all([
      getEnterprises({
        fields: 'identification_number',
        search: {
          id: enterpriseId,
        },
      }),
      DocumentService.getLastJsonFile(documentId),
    ]);
    enterpriseRes.displayNotif(setNotif);
    documentRes.displayNotif(setNotif);
    if (enterpriseRes.success && enterpriseRes.data?.length) {
      setEnterprise(enterpriseRes.data[0]);
    }
    if (documentRes.success) {
      setData(documentRes.data);
    }
  };

  useEffect(() => {
    getJsonFileForDocument();
  }, []);

  const replayAlgoCallApi = async () => {
    setDocumentApi('loading');
    if (enterprise?.identification_number && documentId) {
      const response = await DocumentService.getJsonFileOfDocument({
        identificationNumber: enterprise.identification_number,
        fileId: documentId,
      });
      response.displayNotif(setNotif);
      if (response.success) {
        setDocumentApi(response.data);
      }
    }
  };

  return (
    <Container fluid>
      <Row>
        <Header />
        <div className="margin-top-20">
          <ActionButton
            onClick={replayAlgoCallApi}
            type="button"
            className="pull-right"
          >
            {t('DocumentDisplay.retry', 'Rejouer')}
          </ActionButton>
        </div>
        <div className="row margin-top-20">
          <div className="col-lg-6">
            <div className="">
              {data ? (
                <JSONPretty
                  id="json-pretty"
                  data={data}
                  theme={JSONPrettyMon}
                />
              ) : (
                <Spinner animation="border" />
              )}
            </div>
          </div>
          <div className="col-lg-6">
            {documentApi ? (
              documentApi === 'loading' ? (
                <Spinner animation="border" />
              ) : (
                <JSONPretty
                  id="json-pretty"
                  data={documentApi}
                  theme={JSONPrettyMon}
                />
              )
            ) : null}
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default UrssafJson;
