import {
  getEnterpriseMembers,
  getEnterprises,
  getCustomFields as getCustomFieldsService,
  getConnectionConfig,
} from '../../services/enterprise.service';
import {
  getDocumentTypesOfAddworking,
  getDocumentTypesOfCustomer,
} from '../../services/document.service';
import { getSurveys } from '../../services/survey.service';
import { getUsers } from '../../services/user.service';
import { alphaSort } from '../../helpers/utils';
import { FORMATS } from '../../helpers/constants';

const PAGE_SIZE = 50;

const parse = (json, field) => {
  try {
    const parsed = JSON.parse(json);
    return parsed[field] || parsed;
  } catch (e) {
    if (e.name === 'SyntaxError') {
      return json;
    }
    throw e;
  }
};

export const initDocumentTypes = async (config) => {
  let documentTypeAddworking = [];
  let documentTypeCustomer = [];
  const buildDocumentType = (types) => (acc, documentType) => (
    types.includes(documentType.type)
      ? [
        ...acc,
        {
          value: documentType.id,
          label: parse(documentType.display_name, 'fr'),
        },
      ] : acc
  );
  // eslint-disable-next-line camelcase
  const { customer_id } = config;
  const [legalRes, businessRes] = await Promise.all([
    getDocumentTypesOfAddworking({ customer_id }),
    getDocumentTypesOfCustomer(customer_id),
  ]);
  if (legalRes.success) {
    documentTypeAddworking = legalRes.data.reduce(buildDocumentType(['legal']), []);
  }
  if (businessRes.success) {
    documentTypeCustomer = businessRes.data.reduce(buildDocumentType(['business', 'legal']), []);
  }
  return [...documentTypeAddworking, ...documentTypeCustomer].sort(alphaSort('label'));
};

export const initCustomFields = async (config) => {
  const res = await getCustomFieldsService(config.customer_id);
  if (res.success && res.data?.length) {
    const result = res.data
      .map((field) => ({ label: field.title, value: field.id, references: field.references }))
      .sort(alphaSort('title'));
    return result;
  }
  return [];
};

export const initSurveys = async (config) => {
  const res = await getSurveys({
    search: {
      enterpriseId: config.customer_id,
    },
  });
  if (res.success && res.data?.length) {
    return res.data.map((survey) => ({
      value: survey.id,
      label: survey.label,
      content: survey.content
        .flat()
        .filter((q) => q.format !== FORMATS.File || q.format !== FORMATS.Date)
        .map((c) => ({
          value: c.key,
          label: c.title,
          options: c.options,
          type: c.type,
          items: c.items,
        })),
    }));
  }
  return [];
};

export const initMembers = async (enterprise) => {
  const membersRes = await getEnterpriseMembers(
    enterprise.identification_number,
    {
      fields: 'user_id',
      page_size: PAGE_SIZE,
    },
  );
  if (membersRes.success && membersRes?.data?.length) {
    const usersRes = await getUsers({
      fields: 'id,firstname,lastname,email',
      search: {
        id: {
          $or: membersRes.data.map((member) => member.user_id),
        },
      },
      page_size: PAGE_SIZE,
    });
    if (usersRes.success && usersRes?.data?.length) {
      return (usersRes.data.map(({
        id, firstname, lastname, email,
      }) => ({ label: ` ${firstname} ${lastname} (${email})`, value: id })));
    }
  }
  return [];
};

export const initEnterprise = async (enterpriseId) => {
  const enterpriseRes = await getEnterprises({
    fields: 'id,identification_number,name',
    search: {
      id: enterpriseId,
    },
  });
  if (enterpriseRes.success && enterpriseRes?.data?.length) {
    return {
      identification_number: enterpriseRes.data[0].identification_number,
      name: enterpriseRes.data[0].name,
      customer_id: enterpriseRes.data[0].id,
    };
  }
  return {};
};

export const initConnectionConfig = async (configId) => {
  const res = await getConnectionConfig(configId);
  if (res.success) {
    const config = res.data;
    config.steps = config.steps.sort((a, b) => a.step - b.step);
    config.steps = config.steps.map((s) => ({
      ...s,
      tasks: s.tasks.sort((a, b) => a.position - b.position),
    }));
    return config;
  }
  return null;
};
