/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';

const GroupHeading = (props) => (
  <div>
    <components.GroupHeading {...props} />
  </div>
);

const CustomSelect = ({
  options,
  noNullOption,
  placeholder,
  stayOpen,
  isMulti,
  isSearchable,
  onChange,
  value,
  isDisabled,
  height,
  group,
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? '1px solid #12957D' : provided.border,
      '&:hover': {
        border: state.isFocused ? '1px solid #12957D' : provided.border,
      },
      height,
      minHeight: height,
      borderRadius: '10px',
      boxShadow: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      '&:hover': {
        backgroundColor: state.isSelected ? '#12957D' : '#f0fef6',
      },
      '&:active': {
        backgroundColor: '#12957D',
      },
      backgroundColor: state.isSelected ? '#12957D' : 'white',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      width: 0,
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      border: 'solid 1px #ced4da',
      borderRadius: '6px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#3A3A3A',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#3A3A3A',
      ':hover': {
        color: 'black',
      },
    }),
  };

  const selectProps = {
    styles: customStyles,
    placeholder,
    isMulti,
    isSearchable,
    onChange,
    menuPlacement: 'auto',
    isDisabled,
  };
  if (group?.length) {
    selectProps.components = { GroupHeading };
    selectProps.options = noNullOption ? group : [{ label: '-', value: '' }, ...(group || [])];
  } else {
    selectProps.options = noNullOption ? options : [{ label: '-', value: '' }, ...(options || [])];
    if (value) selectProps.value = value;
    if (stayOpen) selectProps.closeMenuOnSelect = false;
  }
  return <Select {...selectProps} />;
};

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.node,
  })),
  noNullOption: PropTypes.bool,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  stayOpen: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  height: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.node,
    }),
    PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.node,
    })),
  ]),
  group: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.node,
    })),
  })),
};

CustomSelect.defaultProps = {
  placeholder: '',
  onChange: () => { },
  noNullOption: false,
  isMulti: false,
  isSearchable: false,
  isDisabled: false,
  stayOpen: false,
  value: undefined,
  group: [],
  options: [],
  height: '100%',
};

export default CustomSelect;
