import { safeFetch, handleResponse } from '../helpers/response-handler';

const { REACT_APP_URL_COMPLIANCE_API, REACT_APP_URL_FILE_API } = process.env;

// eslint-disable-next-line
export const getComments = (documentId) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/comments?commentable_id=${documentId}`,
);

export const createComment = (comment) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/comments`,
  {
    method: 'POST',
    body: JSON.stringify(comment),
  },
);

export const deleteComment = (commentId) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/comments/${commentId}`,
  {
    method: 'DELETE',
  },
);

export const getDocumentFile = (fileId) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/files/${fileId}/signed-url`,
);

export const getRefusalReasons = (documentType) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/document-types/${documentType}/refusal-reasons`,
);

export const getUploadSignedURL = (mime) => (
  safeFetch(
    `${REACT_APP_URL_FILE_API}/v1/file/support/files/upload-signed-url?mime=${mime}`,
  )
);
export const getSignedURL = (fileId) => (
  safeFetch(
    `${REACT_APP_URL_FILE_API}/v1/file/support/files/${fileId}/signed-url`,
  )
);

export const uploadToS3 = async (signedUrl, file) => (
  handleResponse(
    fetch(
      signedUrl,
      {
        method: 'PUT',
        body: file,
      },
    ),
  )
);
