import React, {
  createContext, useState, useEffect, useMemo,
} from 'react';
import { Auth } from 'aws-amplify';
import { getUserFromSession } from '../helpers/session';

export const UserContext = createContext();

// eslint-disable-next-line react/prop-types
const UserContextProvider = ({ children }) => {
  const [user, setUserOnly] = useState(JSON.parse(localStorage.getItem('user')));
  const [redirection, setRedirectionOnly] = useState(localStorage.getItem('redirection'));

  const setUser = (givenUser) => {
    localStorage.setItem('user', JSON.stringify(givenUser));
    setUserOnly(givenUser);
  };

  const logOut = (pathname) => {
    setUserOnly(null);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.setItem('redirection', pathname);
    Auth.signOut();
  };

  useEffect(() => {
    if (!user) {
      setUserOnly(JSON.parse(localStorage.getItem('user')));
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      const session = await Auth.currentSession();
      setUser(getUserFromSession(session));
    })();
  }, []);

  const setRedirection = (path) => {
    setRedirectionOnly(path);
    localStorage.setItem('redirection', path);
  };

  const getRedirection = () => new Promise((resolve) => {
    resolve(redirection);
    setRedirectionOnly(null);
    localStorage.removeItem('redirection');
  });

  const value = useMemo(() => ({
    user,
    setUser,
    logOut,
    setRedirection,
    getRedirection,
  }), [user]);

  return (
    <UserContext.Provider
      value={value}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
