/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import '../../styles/css/style.css';
import UserTokenCircle from '../DocumentInfoTab/UserTokenCircle';
import StateCaption from '../StateCaption/StateCaption';
import ColorPoint from '../ColorPoint/ColorPoint';
import doubleArrowDesc from '../../assets/double-arrow-asc.svg';
import doubleArrowAsc from '../../assets/double-arrow-desc.svg';
import doubleArrow from '../../assets/double-arrow.svg';
import { deleteEditingToken } from '../../services/enterprise.service';
import { EnterprisesContext } from '../../contexts/enterprises.context';
import { VendorsListContext } from '../../contexts/vendorList.context';
import { UserContext } from '../../contexts/user.context';
import { formatDate, toKey } from '../../helpers/utils';
import Tooltip from '../Tooltip/Tooltip';

const COLUMNS_NAMES = {
  vendor: 'vendor',
  customer: 'customer',
  upload: 'upload',
  document: 'document',
};

/**
 * VendorsList is a component that contains a list of vendors.
 * @version 1
 * @since 10/11/2021
 */

const VendorsList = () => {
  const { t } = useTranslation();
  const {
    getAllEnterprises,
    getAllEnterprisesCount,
    getAllEnterprisesTokens,
    enterprises,
    setFilters,
    filters,
    reset,
    count,
    loading,
    tokens,
  } = useContext(EnterprisesContext);
  const { searchEvent } = useContext(VendorsListContext);
  const { user } = useContext(UserContext);
  const tableRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    getAllEnterprisesTokens(user.email);
    setFilters({ vendorId: '' });
    getAllEnterprises({ vendorId: '' });
    getAllEnterprisesCount({ vendorId: '' });
    deleteEditingToken(user.email);
  }, []);

  useEffect(() => {
    tableRef.current?.scrollTo(0, 0);
  }, [searchEvent]);

  const doSearch = (filters, opts = {}) => {
    getAllEnterprisesTokens(user.email);
    getAllEnterprises(filters, opts);
    getAllEnterprisesCount(filters);
    deleteEditingToken(user.email);
  };

  const scrollPaginate = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 2;
    if (
      bottom
      && !loading
      && parseInt(count?.total_vendors_count, 10) !== enterprises.length
    ) {
      const filtersPage = { page: filters.page + 1 };
      setFilters(filtersPage);
      doSearch(filtersPage);
    }
  };

  const changeOrder = (orderType) => () => {
    const order = filters.order === `${orderType}Desc`
      ? `${orderType}Asc`
      : `${orderType}Desc`;
    setFilters({ order, page: 0 });
    doSearch({ order, page: 0 }, { reset: true });
  };

  const handleClick = (e) => {
    e.preventDefault();
    const link = e.target?.closest('a');
    reset();
    history.push(link?.href?.replace(/https?:\/\/(\w*[.|:][a-zA-Z0-9]*)*/, ''));
  };

  return (
    <div className="col-9 padding-col">
      <div className="h1">
        {t('VendorsList.title', 'Liste de sous-traitants')}
      </div>
      <div>
        {enterprises.length ? (
          <div className="table-container">
            <table className="table">
              <thead className="table-content">
                <tr>
                  <th className="width-state table-titles vertical-align-middle">
                    {t('VendorsList.state', 'Etat')}
                  </th>
                  <th className="width-current-editor" />
                  <th className="width-vendor table-titles vertical-align-middle">
                    {t('VendorsList.name', 'Raison sociale sous-traitant')}
                    <img
                      src={
                        filters.order === 'vendorDesc'
                          ? doubleArrowDesc
                          : filters.order === 'vendorAsc'
                            ? doubleArrowAsc
                            : doubleArrow
                      }
                      alt="filter"
                      onClick={changeOrder(COLUMNS_NAMES.vendor)}
                      className="cursor-pointer"
                      aria-hidden="true"
                    />
                  </th>
                  <th className="width-customer table-titles vertical-align-middle">
                    {t('VendorsList.client', 'Raison sociale client')}
                  </th>
                  <th className="width-date table-titles vertical-align-middle">
                    {t('VendorsList.lastupload', "Date d'upload")}
                    <img
                      src={
                        filters.order === 'uploadDesc'
                          ? doubleArrowDesc
                          : filters.order === 'uploadAsc'
                            ? doubleArrowAsc
                            : doubleArrow
                      }
                      alt="filter"
                      onClick={changeOrder(COLUMNS_NAMES.upload)}
                      className="cursor-pointer"
                      aria-hidden="true"
                    />
                  </th>
                  <th className="width-kbis table-titles  vertical-align-middle">
                    {t('VendorsList.kbis', 'Kbis valide')}
                  </th>
                  <th className="width-documents table-titles text-right vertical-align-middle">
                    {t('VendorsList.pending', 'En attente')}
                    <img
                      src={
                        filters.order === 'documentDesc'
                          ? doubleArrowDesc
                          : filters.order === 'documentAsc'
                            ? doubleArrowAsc
                            : doubleArrow
                      }
                      alt="filter"
                      onClick={changeOrder(COLUMNS_NAMES.document)}
                      className="cursor-pointer"
                      aria-hidden="true"
                    />
                  </th>
                </tr>
              </thead>
              <thead className="table-count">
                <tr className="total-line table-row">
                  <th className="width-state" />
                  <th className="width-current-editor" />
                  <th className="width-vendor ">
                    <div className="total-number">
                      {count.total_vendors_count}
                    </div>
                    <div className="total-text">
                      {t('VendorsList.vendorstotal', 'Sous-traitants')}
                    </div>
                  </th>
                  <th className="width-customer ">
                    <div className="total-number">
                      {count.total_customers_count}
                    </div>
                    <div className="total-text">
                      {t('VendorsList.clienttotal', 'Clients')}
                    </div>
                  </th>
                  <th className="width-date " />
                  <th className="width-kbis " />
                  <th className="width-documents text-right">
                    <div className="total-number">
                      {count.total_waiting_documents_count}
                    </div>
                    <div className="total-text">
                      {t('VendorsList.documenttotal', 'Documents')}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody
                id="row-container-vendor-list"
                onScroll={scrollPaginate}
                ref={tableRef}
              >
                {/*
                  The a tag around tr is not a good practice
                  but we need it to use right-click 'open in a new tab',
                  usefull for conformity team (open to better ideas)
                */}
                {enterprises.map((vendor) => (
                  <tr key={toKey(vendor)} className="table-row hover-green">
                    <td className="width-state ">
                      <a
                        href={`/compliance/provider/${vendor.id}`}
                        onClick={handleClick}
                        className="row-link"
                      >
                        {vendor.enabled ? (
                          <StateCaption>
                            {t('VendorsList.actif', 'Actif')}
                          </StateCaption>
                        ) : (
                          <StateCaption
                            color="#727272"
                            backgroundColor="rgba(114, 114, 114, 0.05)"
                          >
                            {t('VendorsList.inactive', 'Inactif')}
                          </StateCaption>
                        )}
                      </a>
                    </td>
                    <td className="width-current-editor pt-2 justify-content-center">
                      <UserTokenCircle
                        marginleft
                        email={tokens && Object.keys(tokens).includes(vendor.id)
                          ? tokens[vendor.id][tokens[vendor.id].length - 1]
                          : null}
                      />
                    </td>
                    <td className="width-vendor dark-grey-bold ellipsis">
                      <a
                        href={`/compliance/provider/${vendor.id}`}
                        onClick={handleClick}
                        className="row-link"
                      >
                        {vendor.name}
                      </a>
                    </td>
                    <td
                      className="width-customer dark-grey-bold ellipsis"
                      data-tip="react-tooltip"
                      data-for={vendor.name}
                    >
                      <a
                        href={`/compliance/provider/${vendor.id}`}
                        onClick={handleClick}
                        className="row-link"
                      >
                        {vendor.customers && vendor.customers_count === '1' ? (
                          vendor.customers.name
                        ) : (
                          <>
                            {`${vendor.customers_count} ${t(
                              'VendorsList.customers',
                              'client{{s}}',
                              {
                                s:
                                  parseInt(vendor.customers_count, 10) > 1
                                    ? 's'
                                    : '',
                              },
                            )}`}
                            <Tooltip
                              place="bottom"
                              id={vendor.name}
                              className="customer-name"
                            >
                              {vendor.customers.name}
                            </Tooltip>
                          </>
                        )}
                      </a>
                    </td>
                    <td className="width-date light-grey">
                      <a
                        href={`/compliance/provider/${vendor.id}`}
                        onClick={handleClick}
                        className="row-link"
                      >
                        {formatDate(vendor.last_upload.slice(0, 10))}
                      </a>
                    </td>
                    <td className="width-kbis light-grey">
                      <a
                        href={`/compliance/provider/${vendor.id}`}
                        onClick={handleClick}
                        className="row-link align-items-center"
                      >
                        {vendor.one_validated_kbis && (
                          <>
                            <ColorPoint backgroundColor="#66EBA7" />
                            {t('VendorsList.kbisvalidated', 'Oui')}
                          </>
                        )}
                        {!vendor.one_validated_kbis && (
                          <>
                            <ColorPoint backgroundColor="#E34A30" />
                            {t('VendorsList.kbispending', 'Non')}
                          </>
                        )}
                      </a>
                    </td>
                    <td className="width-documents light-grey text-right">
                      <a
                        href={`/compliance/provider/${vendor.id}`}
                        onClick={handleClick}
                        className="row-link flex-row-reverse"
                      >
                        {vendor.waiting_documents_count}
                        &nbsp;
                        {`(${vendor.need_pre_check})`}
                        &nbsp;
                        {t('VendorsList.document', 'document{{s}}', {
                          s:
                            parseInt(vendor.waiting_documents_count, 10) > 1
                              ? 's'
                              : '',
                        })}
                      </a>
                    </td>
                  </tr>
                  // </a>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center ">
            <div className="text-small">
              <span>
                {t(
                  'VendorsList.nodocuments',
                  'Aucun document en attente de validation',
                )}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VendorsList;
