import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationDE from './locales/de';
import translationEN from './locales/en';
import translationFR from './locales/fr';

// eslint-disable-next-line
const { NODE_ENV } = process?.env;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: !!(NODE_ENV.match(/dev(elopment)?/)),
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: { translation: translationEN },
      de: { translation: translationDE },
      fr: { translation: translationFR },
    },
  });

export default i18n;
