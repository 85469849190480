import React, { useState, useContext, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import searchIcon from '../../assets/search.svg';
import cross from '../../assets/cross.svg';
import { EnterprisesContext } from '../../contexts/enterprises.context';
import { toKey } from '../../helpers/utils';
import { search } from '../../services/enterprise.service';
import { AlertContext } from '../../contexts/alert.context';

/**
 * SearchBox is a component that allows you to search for a specific
 * chain of character inside a table. You can use an API to fetch your table,
 *  you just need a request URL to do so.
 * @version 1
 * @since 29/11/2021
 */

const SearchBox = ({
  label, placeholder, type, onClick,
}) => {
  const [selected, setSelected] = useState({});
  const [value, setValue] = useState('');
  const [result, setResult] = useState([]);
  const { t } = useTranslation();
  const { fullEnterprisesSearch } = useContext(EnterprisesContext);
  const { setNotif } = useContext(AlertContext);

  const hasResult = () => Object.keys(result).length || !value;

  const hasSelected = () => !!selected.id;

  /**
   * This function is used to handle the typing speed of the user
   * First, it's a 'callback' hook with no dependencies, it means that the function instance
   * will be the same whatever the count of render
   * Second, the callback takes usually a function as first arg but here,
   * it's a debounce function (from lodash) that will execute its "content"
   * only if it don't be called during the given delay (second arg of debounce).
   */
  const searchOnChange = useCallback(
    debounce(async (val) => {
      if (val) {
        const cleanValue = val.length === 1 ? val.replace(/\s/g, '-').replace("'", '"') : val;
        const searchResponse = await search({
          search: cleanValue, target: type,
        });
        searchResponse.displayNotif(setNotif);
        if (searchResponse.success) {
          setResult(searchResponse.data);
        }
      } else if (!val) {
        clearSearchField();
      }
    }, 300),
    [],
  );

  const changeValue = async (e) => {
    setValue(e?.target?.value);
    await searchOnChange(e?.target?.value);
    setSelected(true);
  };

  const clearSearchField = () => {
    const filters = {
      customerId: '',
      vendorId: '',
    };
    setValue('');
    setSelected({});
    fullEnterprisesSearch(filters);
  };

  const handleClick = (item) => () => {
    setValue(item.name);
    const filter = { [`${type}Id`]: item.id };
    setSelected(item);
    fullEnterprisesSearch(filter);
    onClick(item);
  };

  return (
    <div className="label-and-option">
      <div className="filtre-label">{label}</div>
      <div className="search-and-results">
        <div className="search-container">
          <input
            type="search"
            id="seachVendorId"
            className="search-input"
            placeholder={placeholder}
            onChange={changeValue}
            value={value}
            autoComplete="off"
          />
          {!value ? (
            <img src={searchIcon} className="search" alt="search" />
          ) : (
            <img
              src={cross}
              className="search cursor-pointer"
              onClick={clearSearchField}
              alt="delete"
              aria-hidden="true"
            />
          )}
        </div>
        <div className="results-container">
          {!hasResult() && (
            <div className="dropdown__noresult">
              <div>{t('Searchbox.noresults', 'Pas de résultat')}</div>
            </div>
          )}
          {value && hasResult() && !hasSelected() && (
            <div className="dropdown__container">
              {result.map((item) => (
                <div
                  key={toKey(item)}
                  className="dropdown__item"
                  onClick={handleClick(item)}
                  aria-hidden="true"
                >
                  {item.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBox;

SearchBox.propTypes = {
  /** Label above the input */
  label: PropTypes.string,
  /** Placeholder inside the input */
  placeholder: PropTypes.string,
  /** vendor or customer value */
  type: PropTypes.string.isRequired,
  /* value passed to parent when click */
  onClick: PropTypes.func,
};

SearchBox.defaultProps = {
  /** Label above the input */
  label: '',
  /** Placeholder inside the input */
  placeholder: '',

  onClick: () => { },
};
