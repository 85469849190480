/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ActionButton from '../ActionButton/ActionButton';
import DialogModal from '../DialogModal/DialogModal';
import { AWColors } from '../../helpers/utils';

const AnonymizeUser = ({
  user, setShow, show, handleSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ActionButton
        className="action-button"
        type="button"
        backgroundColor={AWColors.Green}
        color="white"
        onClick={() => setShow(true)}
        children={t('User.anonymize')}
      />
      <DialogModal
        show={show}
        onHide={() => setShow(false)}
        heading={t('User.ModalAnonymization.title')}
        children={<p>{t('User.ModalAnonymization.content', { email: user?.email || '' })}</p>}
        btnText={t('Button.confirm')}
        onSubmit={handleSubmit}
      />
    </>
  );
};

AnonymizeUser.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
  setShow: PropTypes.func,
  show: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

AnonymizeUser.defaultProps = {
  setShow: () => {},
  show: false,
  user: {},
};

export default AnonymizeUser;
