import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import debounce from 'debounce';
import Header from '../../components/Header/Header';
import { getEvents } from '../../services/broadcast.service';
import RowEvent from './RowEvent';
import './EventLists.scss';

const {
  REACT_APP_URL_BROADCAST_API,
} = process.env;

const EventLists = () => {
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const [searchInProgress, setSearchInProgress] = useState([]);
  const [searchDateStart, setSearchDateStart] = useState(dayjs().startOf('hour').format('YYYY-MM-DDTHH:mm'));
  const [searchDateEnd, setSearchDateEnd] = useState(dayjs().format('YYYY-MM-DDTHH:mm'));
  const [searchType, setSearchType] = useState('');
  const [total, setTotal] = useState('');
  const [limit] = useState(20);
  const [page, setPage] = useState(0);
  const [searchEventName, setSearchEventName] = useState('');

  const nbMaxPage = useMemo(() => Math.ceil(total / limit), [total, limit]);
  useEffect(async () => {
    setPage(0);
    reload();
  }, [searchDateStart, searchDateEnd, searchType, searchEventName]);

  useEffect(async () => {
    reload();
  }, [page]);
  async function reload() {
    setEvents([]);
    setSearchInProgress(true);
    try {
      const { data: { logs, total } } = await getEvents({
        start: dayjs(searchDateStart).toISOString(),
        end: dayjs(searchDateEnd).toISOString(),
        type: searchType,
        event: searchEventName,
        page,
        limit,
      });
      setEvents(logs);
      setTotal(total);
    } catch (error) {
      console.error(error);
    }
    setSearchInProgress(false);
  }

  const previous = () => {
    setSearchDateStart(dayjs(searchDateStart).subtract(1, 'day').format('YYYY-MM-DDTHH:mm'));
    setSearchDateEnd(dayjs(searchDateEnd).subtract(1, 'day').format('YYYY-MM-DDTHH:mm'));
  };
  const next = () => {
    setSearchDateStart(dayjs(searchDateStart).add(1, 'day').format('YYYY-MM-DDTHH:mm'));
    setSearchDateEnd(dayjs(searchDateEnd).add(1, 'day').format('YYYY-MM-DDTHH:mm'));
  };
  const debouncedEventName = debounce((value) => {
    setSearchEventName(value);
  }, 250);

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col xs={12} className="broadcast-root">
            <h1>
              {t('Broadcast.title')}
            </h1>
            <div className="header">
              <div>
                <button type="button" onClick={previous}>
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="cursor-pointer"
                  />
                </button>
                <input type="datetime-local" onInput={(ev) => setSearchDateStart(ev.target.value)} value={searchDateStart} />
                {' => '}
                <input type="datetime-local" onInput={(ev) => setSearchDateEnd(ev.target.value)} value={searchDateEnd} />
                <button type="button" onClick={next}>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="cursor-pointer"
                  />
                </button>
              </div>
              <div className="column">
                <div className="searchType-container">
                  <div>
                    <input type="radio" checked={searchType === ''} name="searchType" onClick={() => setSearchType('')} />
                    <div className="label">{t('Broadcast.all')}</div>
                  </div>
                  <div>
                    <input type="radio" checked={searchType === 'success'} name="searchType" onClick={() => setSearchType('success')} />
                    <div className="label">{t('Broadcast.success')}</div>
                  </div>
                  <div>
                    <input type="radio" checked={searchType === 'error'} name="searchType" onClick={() => setSearchType('error')} />
                    <div className="label">{t('Broadcast.errors')}</div>
                  </div>
                </div>
                <div>
                  <input type="text" onInput={(ev) => debouncedEventName(ev.target.value)} defaultValue={searchEventName} placeholder={t('Broadcast.searchPlaceholder')} />
                </div>
              </div>
            </div>
            <div>
              {!searchInProgress && events?.length ? (
                <>
                  <div className="card">
                    {(
                        events.map(({ requestId, events: _events }) => {
                          const firstEventEnd = _events?.[0]?.[1];
                          return (
                            <div className={`events ${firstEventEnd?.executionResult?.type || 'in-progress'}`}>
                              <div className="event-date">
                                {dayjs(firstEventEnd?.date).format('YYYY-MM-DD HH:mm:ss')}
                              </div>
                              <div className="event-infos">
                                {_events.map(([start, end]) => (
                                  <div key={start.id} className="event-line">
                                    <div className="event-infos">
                                      <RowEvent start={start} end={end} />
                                    </div>
                                  </div>
                                ))}
                              </div>

                              <div className="see-more">
                                <a href={`${REACT_APP_URL_BROADCAST_API}/v1/broadcast/explain/${requestId}`} target="__blank">{t('Broadcast.seeMore')}</a>
                              </div>
                            </div>
                          );
                        })
                    )}
                  </div>
                  <div className="toolbar">
                    <div className="pages">
                      <button type="button" className="page" onClick={() => setPage(page - 1)} disabled={page <= 0}>
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          className="cursor-pointer"
                        />
                      </button>
                      {Array(nbMaxPage || 0).fill().map((a, i) => (
                        <button type="button" className={i === page ? 'page active' : 'page'} onClick={() => setPage(i)}>
                          {i + 1}
                        </button>
                      ))}
                      <button type="button" className="page" onClick={() => setPage(page + 1)} disabled={page + 1 >= nbMaxPage}>
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="cursor-pointer"
                        />
                      </button>
                    </div>
                    {t('Broadcast.total')}
                    :
                    {' '}
                    {total}
                  </div>
                </>
              ) : ''}
              {!searchInProgress && !events?.length ? (
                <div>Aucune données</div>
              ) : ''}
              {searchInProgress ? (
                <div>Recherche en cours</div>
              ) : ''}

            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EventLists;
