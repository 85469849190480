import React, {
  useState,
  useMemo,
  useEffect,
  useContext,
} from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { PropTypes } from 'prop-types';
import {
  ConditionTypesList,
  ConditionTypes,
  TYPES,
  ConditionModes,
} from '../../../helpers/constants';
import AWSelect from '../../../components/AWSelect/AWSelect';
import MultiStringInput from '../../../components/MultiStringInput/MultiStringInput';
import { KEYBOARD_VALUES, randString } from '../../../helpers/utils';
import { ConnectionConfigContext } from '../../../contexts/connectionConfig.context';

const Fields = {
  Type: 'conditionType',
  Element: 'conditionElement',
  SubElement: 'conditionSubElement',
  Value: 'conditionValue',
  Mode: 'conditionMode',
};

const Boolean = {
  True: 'true',
  False: 'false',
};

const ConditionForm = ({ condition }) => {
  const { t } = useTranslation();
  const {
    customFields,
    surveys,
    connectionConfig,
    setConnectionConfig,
    isConnectionConfigSketch,
  } = useContext(ConnectionConfigContext);
  const [elements, setElements] = useState([]);
  const [subElements, setSubElements] = useState([]);
  const [values, setValues] = useState([]);

  const initElements = () => {
    if (condition.type === ConditionTypes.CustomField) {
      return customFields;
    }
    if (condition.type === ConditionTypes.Form) {
      return surveys;
    }
    return [];
  };

  const initSubElements = (elements) => {
    if (condition.type === ConditionTypes.Form) {
      return elements.find((e) => e.value === condition.element_id)?.content || [];
    }
    return [];
  };

  const getSubElementValues = (_subElements, matchId, initMode = false) => {
    const sub = _subElements.find((se) => se.value === matchId);
    if (sub) {
      if (!(sub.type === TYPES.Number || sub.type === TYPES.String)) {
        return sub.items
          ? sub.items.map((i) => ({ label: i.value, value: i.value }))
          : [
            { label: t('Common.Yes', 'Oui'), value: Boolean.True },
            { label: t('Common.No', 'Non'), value: Boolean.False },
          ];
      }
      if (condition.condition_values?.length && initMode) {
        return condition.condition_values.map((v) => (
          { id: v.id || randString(), value: v.content, current: false }));
      }
    }
    return [];
  };

  const initValues = (elements, subElements) => {
    if (condition.type === ConditionTypes.CustomField && condition.element_id) {
      return elements.find((e) => e.value === condition.element_id)?.references?.map(
        (ref) => ({ label: ref.content, value: ref.id }),
      ) || [];
    }
    if (condition.type === ConditionTypes.Form && condition.sub_element_id) {
      return getSubElementValues(subElements, condition.sub_element_id, true);
    }
    return [];
  };

  useEffect(() => {
    const _elements = initElements();
    const _subElements = initSubElements(_elements);
    setElements(_elements);
    setSubElements(_subElements);
    setValues(initValues(_elements, _subElements));
  }, [customFields, surveys]);

  const subOptions = useMemo(() => (
    subElements.map((se) => ({ label: se.label, value: se.value }))
  ), [elements, subElements]);

  const buildConditionValue = (value) => ({
    condition_id: condition.id,
    content: value,
  });

  const handleConditionChange = (id, fieldName, value) => {
    const clone = cloneDeep(connectionConfig);
    const { step, condition } = clone.steps
      .reduce((acc, step) => {
        const _condition = step.conditions.find((c) => c.id === id);
        return _condition ? { step, condition: _condition } : acc;
      }, {});
    step.display = true;

    switch (fieldName) {
      case Fields.Type:
        condition.type = value;
        delete condition.element_id;
        delete condition.sub_element_id;
        delete condition.condition_values;
        break;

      case Fields.Element:
        condition.element_id = value;
        delete condition.sub_element_id;
        delete condition.condition_values;
        break;

      case Fields.SubElement:
        condition.sub_element_id = value;
        delete condition.condition_values;
        break;

      case Fields.Value:
        condition.condition_values = Array.isArray(value)
          ? value.map((opt) => buildConditionValue(opt.value || opt))
          : [buildConditionValue(value.value || value)];
        break;

      case Fields.Mode:
        condition.mode = value;
        break;

      default:
        break;
    }
    setConnectionConfig(clone);
  };

  const handleTypeChange = (option) => {
    setSubElements([]);
    setValues([]);
    if (option.value === ConditionTypes.CustomField) {
      setElements(customFields);
    } else if (option.value === ConditionTypes.Form) {
      setElements(surveys);
    }
  };

  const handleElementChange = (option) => {
    if (condition.type === ConditionTypes.CustomField) {
      setSubElements([]);
      const customField = elements.find((opt) => opt.value === option.value);
      setValues(customField.references.map((ref) => ({ label: ref.content, value: ref.id })));
    }
    if (condition.type === ConditionTypes.Form) {
      const survey = surveys.find((s) => s.value === option.value);
      setSubElements(survey.content);
    }
  };

  const handleSubElementChange = (option) => {
    const _values = getSubElementValues(subElements, option.value);
    setValues(_values);
  };

  const handleChange = (id, field) => async (option) => {
    switch (field) {
      case Fields.Type:
        handleTypeChange(option);
        break;

      case Fields.Element:
        handleElementChange(option);
        break;

      case Fields.SubElement:
        handleSubElementChange(option);
        break;

      default:
        break;
    }
    handleConditionChange(id, field, Array.isArray(option) ? option : option.value);
  };

  const modes = useMemo(() => (
    Object.values(ConditionModes).map((value) => ({
      label: t(`ConnectionConfig.Condition.Mode.${value}`),
      value,
    }))
  ), []);

  const valuesInput = useMemo(() => {
    const sub = subElements.find((e) => e.value === condition.sub_element_id);
    if (sub && (sub.type === TYPES.Number || sub.type === TYPES.String)) {
      return (
        <MultiStringInput
          onChange={handleChange(condition.id, Fields.Value)}
          fireKey={KEYBOARD_VALUES.EnterKey}
          disabled={!isConnectionConfigSketch}
          itemLabel={t('Common.AValue', 'une valeur')}
          defaultWords={values}
        />
      );
    }
    return (
      <AWSelect
        isMulti
        noNullOption
        isDisabled={!isConnectionConfigSketch}
        onChange={handleChange(condition.id, Fields.Value)}
        options={values}
        value={condition.condition_values
          ? values?.filter((v) => condition.condition_values.some((cv) => cv.content === v.value))
          : []}
        placeholder=""
      />
    );
  }, [subElements, condition]);

  return (
    <div className="connection-config__condition">
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label className="label" htmlFor={Fields.Type}>
              {t('ConnectionConfig.Condition.type', 'Type de condition')}
            </Form.Label>
            <AWSelect
              noLimit
              noNullOption
              isDisabled={!isConnectionConfigSketch}
              onChange={handleChange(condition.id, Fields.Type)}
              options={ConditionTypesList}
              value={ConditionTypesList.find((c) => c.value === condition.type)}
              placeholder=""
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label className="label" htmlFor={Fields.Element}>
              {t('ConnectionConfig.Condition.element', 'Element à vérifier')}
            </Form.Label>
            <AWSelect
              onChange={handleChange(condition.id, Fields.Element)}
              noNullOption
              isDisabled={!isConnectionConfigSketch}
              options={elements}
              value={elements.find((i) => i.value === condition.element_id) || {}}
              placeholder=""
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="label" htmlFor={Fields.Element}>
              {t('ConnectionConfig.Condition.subElement', 'Sous élément à vérifier')}
            </Form.Label>
            <AWSelect
              onChange={handleChange(condition.id, Fields.SubElement)}
              isDisabled={!subOptions.length || !isConnectionConfigSketch}
              noNullOption
              options={subOptions}
              value={subOptions.find((i) => i.value === condition.sub_element_id) || {}}
              placeholder=""
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label className="label" htmlFor={Fields.Value}>
              {t('ConnectionConfig.Condition.value', 'Valeur souhaitée')}
            </Form.Label>
            {valuesInput}
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label className="label" htmlFor={Fields.Mode}>
              {t('ConnectionConfig.Condition.mode', 'Effet de la condition')}
            </Form.Label>
            <AWSelect
              noNullOption
              onChange={handleChange(condition.id, Fields.Mode)}
              options={modes}
              isDisabled={!isConnectionConfigSketch}
              value={modes.find((m) => m.value === condition.mode)}
              placeholder=""
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

ConditionForm.propTypes = {
  condition: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    element_id: PropTypes.string,
    sub_element_id: PropTypes.string,
    condition_values: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      content: PropTypes.string.isRequired,
      condition_id: PropTypes.string.isRequired,
    })),
    mode: PropTypes.string,
  }).isRequired,
};

export default ConditionForm;
