import React, {
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';
import { Spinner } from 'react-bootstrap';
import FilteredCustomers from '../../components/FilteredCustomers/FilteredCustomers';
import { getConnectionConfigs } from '../../services/enterprise.service';

const additionalColumns = [
  {
    label: i18n.t('CustomersConnectionConfigs.connectionConfigsCount', 'Nombre de processus'),
    key: 'connectionConfigs',
  },
];

const CustomersConnectionConfigs = () => {
  const history = useHistory();

  const [connectionConfigs, setConnectionConfigs] = useState();

  const getAllConnectionConfigs = async () => {
    const connectionConfigsRes = await getConnectionConfigs({ fields: 'customer_id', page_size: 100 });
    if (connectionConfigsRes.success && connectionConfigsRes.data.length) {
      setConnectionConfigs(connectionConfigsRes.data.map((p) => ({ enterpriseId: p.customer_id })));
    } else {
      setConnectionConfigs([]);
    }
  };

  const getCustomerConnectionConfigs = async (customer) => {
    const response = await getConnectionConfigs({
      fields: 'customer_id',
      search: {
        customer_id: customer.id,
      },
      page_size: 100,
    });
    if (response.success) {
      return { connectionConfigs: response.data.map((p) => ({ enterpriseId: p.customer_id })) };
    }
    return [];
  };

  useEffect(() => {
    getAllConnectionConfigs();
  }, []);

  const goToConnectionConfigPage = (entity) => {
    history.push(`/customers/${entity.id}/configs`);
  };

  if (!connectionConfigs) {
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  return (
    <FilteredCustomers
      onCustomerClick={goToConnectionConfigPage}
      matchingIds={[...new Set(connectionConfigs.map((s) => s.enterpriseId))]}
      handleSearchResultClick={getCustomerConnectionConfigs}
      columns={additionalColumns}
      data={{ connectionConfigs }}
      sortByKey="connectionConfigs"
    />
  );
};

export default CustomersConnectionConfigs;
