import React from 'react';
import PropTypes from 'prop-types';

/**
 * StateCaption caption giving your information on an element.
 * It can change color and inside text to fit your every need.
 * @version 1
 * @since 12/11/2021
 */

const StateCaption = ({
  backgroundColor, color, children,
}) => (
  <div className="d-flex justify-content-center caption-container">
    <div
      className="caption"
      style={{
        backgroundColor,
        color,
        borderColor: color,
      }}
    >
      {children}
    </div>
  </div>
);

StateCaption.propTypes = {
  /** Text inside the caption */
  children: PropTypes.string,
  /** Background color of the caption */
  backgroundColor: PropTypes.string,
  /** Text color inside the caption */
  color: PropTypes.string,
};

StateCaption.defaultProps = {
  backgroundColor: '',
  color: '#12957D',
  children: '',
};

export default StateCaption;
