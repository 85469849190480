import {
  safeFetch,
} from '../helpers/response-handler';
import {
  queryBoySearch,
} from '../helpers/utils';

const {
  REACT_APP_URL_USER_API,
} = process.env;

const baseUrl = `${REACT_APP_URL_USER_API}/v1/user/support`;

export const getUserFromId = (id, options = {}) => (
  safeFetch(
    `${baseUrl}/users/${id}${queryBoySearch(options)}`,
    {
      method: 'GET',
    },
  )
);

export const getEnterprisesByUser = (id, options = {}) => (
  safeFetch(
    `${baseUrl}/users/${id}/enterprises${queryBoySearch(options)}`,
  )
);

export const getUsers = (options = {}) => (
  safeFetch(
    `${baseUrl}/users${queryBoySearch(options)}`,
    {
      method: 'GET',
    },
  )
);

export const getCognitoUserStatus = (email) => (
  safeFetch(`${baseUrl}/users/${email}/cognito/status`)
);

export const forceUserConfirmation = (email) => (
  safeFetch(
    `${baseUrl}/users/${email}/cognito/confirm`,
    {
      method: 'POST',
    },
  )
);

export const getEnterpriseSettings = (enterpriseId) => (
  safeFetch(
    `${baseUrl}/enterprises/${enterpriseId}/settings`,
  )
);

export const updateEnterpriseSettings = (enterpriseId, settings) => (
  safeFetch(
    `${baseUrl}/enterprises/${enterpriseId}/settings`,
    {
      method: 'POST',
      body: JSON.stringify(settings),
    },
  )
);

export const anonymizeUser = (userId) => (
  safeFetch(
    `${baseUrl}/users/${userId}/anonymize`,
  )
);
