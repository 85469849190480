import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import QuestionInput from '../QuestionInput/QuestionInput';
import { SurveyContext } from '../../contexts/survey.context';
import { updateSurvey } from '../../services/survey.service';
import { AlertContext } from '../../contexts/alert.context';
import { Status } from '../StatusTag/StatusTag';
import AWFormContent from '../AWFormContent/AWFormContent';
import QuestionsList from '../QuestionsList/QuestionsList';

/**
 * Survey allows you to choose from a question type.
 * @version 1
 * @since 11/04/2022
 */

const SurveyForm = ({ questions, id }) => {
  const { t } = useTranslation();
  const {
    question,
    setQuestion,
    reset,
    survey,
    setSurvey,
  } = useContext(SurveyContext);
  const { setNotif } = useContext(AlertContext);
  const [savedIndex, setSavedIndex] = useState();

  const updateQuestions = async () => {
    const newSurvey = cloneDeep(survey);
    if (question.key) {
      newSurvey.content = newSurvey.content.map((page) => (
        page.map((q) => (
          question.key === q.key ? question : q
        ))
      ));
    } else if (newSurvey.content.length) {
      if (![null, undefined].includes(savedIndex) && newSurvey.content[savedIndex]) {
        newSurvey.content[savedIndex].push(question);
      } else if (
        ![null, undefined].includes(savedIndex)
        && savedIndex === newSurvey.content.length
      ) {
        newSurvey.content.push([question]);
      } else {
        newSurvey.content[(newSurvey.content.length - 1)].push(question);
      }
    } else {
      newSurvey.content.push([question]);
    }
    const res = await updateSurvey(newSurvey.id, newSurvey);
    res.displayNotif(setNotif);
    if (res.success) {
      setSurvey({ ...newSurvey, ...res.data });
      reset();
    }
  };

  const addQuestion = (item) => {
    setSavedIndex(item);
    setQuestion({});
  };

  return (
    <AWFormContent>
      <AWFormContent.ContentList title={t('SurveyForm.summary', 'Organisation des questions')}>
        <QuestionsList questions={questions} id={id} onChangeQuestion={addQuestion} />
      </AWFormContent.ContentList>
      <AWFormContent.Content>
        {
          question ? (
            <QuestionInput
              onSubmit={updateQuestions}
              disabled={survey.status !== Status.Sketch}
            />
          ) : ''
        }
      </AWFormContent.Content>
    </AWFormContent>
  );
};

SurveyForm.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        format: PropTypes.string,
        items: PropTypes.arrayOf(
          PropTypes.shape(
            {
              type: PropTypes.string,
              value: PropTypes.string,
            },
          ),
        ),
      }),
    ),
  ),
  id: PropTypes.string,
};

SurveyForm.defaultProps = {
  questions: [],
  id: '',
};

export default SurveyForm;
