import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import radioIcon from '../../assets/single-choice.svg';
import multiIcon from '../../assets/multi.svg';
import switchIcon from '../../assets/bool.svg';
import dateInputIcon from '../../assets/date-input.svg';
import numberIcon from '../../assets/number-input.svg';
import rangeIcon from '../../assets/range.svg';
import listIcon from '../../assets/list.svg';
import textIcon from '../../assets/text-input.svg';
import textareaIcon from '../../assets/textarea.svg';
import fileIcon from '../../assets/file.svg';

import Tooltip from '../Tooltip/Tooltip';
import { randString } from '../../helpers/utils';
import { FORMATS, TYPES } from '../../helpers/constants';

/**
 * QuestionType makes a question type selection box appear
 * depending on the type of question sent in the prop.
 * @version 1
 * @since 4/11/2022
 */

const QuestionType = ({
  type,
  format,
  onClick,
  isActive,
  disabled,
}) => {
  const { t } = useTranslation();
  const id = useMemo(() => randString(), []);

  const question = useMemo(() => {
    switch (type) {
      case TYPES.String:
        switch (format) {
          case FORMATS.Date:
            return {
              name: t('QuestionType.date', 'Date'),
              icon: dateInputIcon,
              tooltip: t('QuestionType.datedetail', "Choix d'une date"),
            };
          case FORMATS.File:
            return {
              name: t('QuestionType.file', 'Fichier'),
              icon: fileIcon,
              tooltip: t('QuestionType.fileDetail', "Upload d'un fichier"),
            };
          case FORMATS.Text:
            return {
              name: t('QuestionType.paragraph', 'Paragraphe'),
              icon: textareaIcon,
              tooltip: t('QuestionType.textdetail', 'Champ libre pour saisie de lettres et chiffres'),
            };
          default:
            return {
              name: t('QuestionType.text', 'Champ libre'),
              icon: textIcon,
              tooltip: t('QuestionType.textdetail', 'Champ libre pour saisie de lettres et chiffres'),
            };
        }
      case TYPES.Array:
        switch (format) {
          case FORMATS.Select:
            return {
              name: t('QuestionType.list', 'Liste déroulante'),
              icon: listIcon,
              tooltip: t('QuestionType.listdetail', 'Liste déroulante de choix avec une seule réponse possible'),
            };
          case FORMATS.Multi:
            return {
              name: t('QuestionType.multi', 'Choix multiple'),
              icon: multiIcon,
              tooltip: t('QuestionType.multidetail', 'Plusieurs réponses possibles dans une liste prédéfinie'),
            };
          default:
            return {
              name: t('QuestionType.listofanswers', 'Liste de réponses'),
              icon: radioIcon,
              tooltip: t('QuestionType.radiodetail', 'Une ou plusieurs réponses possibles dans une liste prédéfinie'),
            };
        }
      case TYPES.Number:
        switch (format) {
          case FORMATS.Range:
            return {
              name: t('QuestionType.range', 'Intervalle'),
              icon: rangeIcon,
              tooltip: t('QuestionType.rangedetail', "Placement d'un curseur dans un interval donné"),
            };
          default:
            return {
              name: t('QuestionType.number', 'Nombre'),
              icon: numberIcon,
              tooltip: t('QuestionType.numberdetail', 'Champ libre pour saisie de chiffres uniquement'),
            };
        }
      case TYPES.Boolean:
        return {
          name: t('QuestionType.boolean', 'Booléen'),
          icon: switchIcon,
          tooltip: t('QuestionType.booleandetail', 'Oui ou Non'),
        };
      default:
        return {
          name: t('QuestionType.text', 'Champ libre'),
          icon: textIcon,
          tooltip: t('QuestionType.textdetail', 'Champ libre pour saisie de lettres et chiffres'),
        };
    }
  }, [type, format]);

  return (
    <>
      <button
        className={
          [
            isActive ? 'question-type--active question-type' : 'question-type',
            disabled ? 'question-type--disabled' : '',
          ].join(' ')
        }
        type="button"
        disabled={disabled}
        onClick={onClick}
        data-tip="react-tooltip"
        data-for={`tooltip-${id}`}
      >
        <img src={question.icon} alt="icon" className="question-type__icon" />
      </button>
      <Tooltip
        id={`tooltip-${id}`}
        place="bottom"
      >
        {question.name}
      </Tooltip>
    </>
  );
};

QuestionType.propTypes = {
  /** Type of input */
  type: PropTypes.string.isRequired,
  format: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

QuestionType.defaultProps = {
  format: '',
  disabled: false,
};

export default QuestionType;
