import React, {
  useEffect,
  useState,
  useContext,
} from 'react';
import {
  useRouteMatch,
  Link,
  useParams,
} from 'react-router-dom';
import {
  Col,
  Row,
  Container,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { ConnectionConfigContext } from '../../contexts/connectionConfig.context';
import { AlertContext } from '../../contexts/alert.context';
import Header from '../../components/Header/Header';
import ReturnButton from '../../components/ReturnButton/ReturnButton';
import {
  getEnterprises,
  getConnectionConfigs as getConnectionConfigsService,
} from '../../services/enterprise.service';
import AWCard from '../../components/AWCard/AWCard';
import { formatDate } from '../../helpers/utils';
import StateCaption from '../../components/StateCaption/StateCaption';
import { statusList } from '../../helpers/constants';

const CustomerConnectionConfigs = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { enterpriseId } = useParams();
  const [connectionConfigs, setConnectionConfigs] = useState([]);
  const { setNotif } = useContext(AlertContext);
  const { resetConnectionConfig } = useContext(ConnectionConfigContext);
  const [customer, setCustomer] = useState();

  const getEnterprise = async () => {
    if (enterpriseId) {
      const enterpriseRes = await getEnterprises({
        fields: 'name',
        search: {
          id: enterpriseId,
        },
      });
      enterpriseRes.displayNotif(setNotif);
      if (enterpriseRes.success && enterpriseRes.data?.length === 1) {
        setCustomer(enterpriseRes.data[0]);
      }
    }
  };

  const getConnectionConfigs = async () => {
    const configsRes = await getConnectionConfigsService({
      fields: 'title,id,status,created_at',
      search: { customer_id: enterpriseId },
      page_size: 100,
    });
    configsRes.displayNotif(setNotif);
    if (configsRes.success) {
      setConnectionConfigs(configsRes.data);
    }
  };

  useEffect(() => {
    getConnectionConfigs();
    getEnterprise();
    resetConnectionConfig();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Row>
          <li className="d-flex mb-4 mt-3">
            <ReturnButton previousPagePath="/customers/configs" />
            <h1 className="mb-3 mt-1">
              {t('ConnectionConfig.title', 'Processus - {{customer}}', { customer: customer?.name })}
            </h1>
          </li>
          <Col md={4} lg={3}>
            <Link to={`${url}/new/info`}>
              <AWCard title={t('ConnectionConfig.addConnectionConfig', 'Créer un processus')}>
                <div className="d-flex flex-1 justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faPlus} size="2x" />
                </div>
              </AWCard>
            </Link>
          </Col>
          {(connectionConfigs && connectionConfigs.length)
            ? connectionConfigs.map((connectionConfig) => (
              <Col md={4} lg={3} key={connectionConfig.id}>
                <Link to={`${url}/${connectionConfig.id}/info`}>
                  <AWCard
                    title={connectionConfig.title}
                  >
                    <p className="card-text">
                      {`${t('Common.createdAt', 'Crée le')} ${formatDate(connectionConfig.created_at)}`}
                    </p>
                    {
                      connectionConfig.status ? (
                        <StateCaption
                          color={statusList[connectionConfig.status]?.color}
                          backgroundColor={statusList[connectionConfig.status]?.background}
                        >
                          {t(`Status.${connectionConfig.status}`)}
                        </StateCaption>
                      ) : <> </>
                    }
                  </AWCard>
                </Link>
              </Col>
            )) : <> </>}
        </Row>
      </Container>
    </>
  );
};

export default CustomerConnectionConfigs;
