import React, { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import AWSelect from '../../components/AWSelect/AWSelect';
import HealthContainer from '../../components/HealthContainer/HealthContainer';
import Header from '../../components/Header/Header';
import { getHealth, ENVS } from '../../services/health.service';
import { capitalize } from '../../helpers/utils';

const { REACT_APP_DOMAINS } = process.env;
const domains = REACT_APP_DOMAINS.split(',');

const Health = () => {
  const [allServices, setAllServices] = useState([]);
  const [services, setServices] = useState([]);

  const getHealths = async () => {
    const _services = {};
    for (let j = 0; j < domains.length; j += 1) {
      const domain = domains[j];
      const envs = Object.keys(ENVS);
      for (let i = 0; i < envs.length; i += 1) {
        const env = envs[i];
        _services[domain] = [
          ...(_services[domain] || []),
          getHealth(domain, env, 'private'),
          getHealth(domain, env, 'public'),
        ];
      }
      // eslint-disable-next-line no-await-in-loop
      _services[domain] = await Promise.all(_services[domain]);
      _services[domain] = _services[domain].map((health) => ({
        health: health.res.hasError() ? false : health.res.data?.health,
        version: health.res.hasError() ? '' : health.res.data?.version,
        domain: health.domain,
        env: health.env,
        isPublic: health.mode === 'public',
      }));
      setServices((s) => ({
        ...s,
        [domain]: _services[domain],
      }));
    }
    setAllServices(_services);
  };

  const handleChange = (opts) => {
    const _domains = opts.map((option) => option.value);
    const result = _domains.reduce((acc, domain) => ({
      ...acc,
      [domain]: allServices[domain],
    }), {});
    setServices(_domains.length ? result : allServices);
  };

  const options = useMemo(() => domains.map((domain) => (
    {
      value: domain,
      label: domain,
    })), []);

  useEffect(() => {
    getHealths(domains);
  }, []);

  return (
    <div className="h-100-vh">
      <Header />
      <div className="p-5">
        <AWSelect isMulti options={options} onChange={handleChange} />
      </div>
      {
        Object.keys(services)?.length ? (
          <div className="w-100 p-5">
            {Object.keys(services).map((service) => (
              <p>
                <h2>
                  {capitalize(service)}
                </h2>
                <div className="d-flex flex-wrap">
                  {services[service].map((api) => (
                    <HealthContainer service={api} key={`${api.domain}.${api.isPublic}.${api.env}`} />
                  ))}
                </div>
              </p>
            ))}
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        )
      }
    </div>
  );
};

export default Health;
