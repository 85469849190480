import React from 'react';

import '../../styles/css/style.css';
import DocumentInfo from '../DocumentInfo/DocumentInfo';
import DocumentAction from '../DocumentAction/DocumentAction';
import Comment from '../Comment/Comment';
/**
 * DocumentInfoAction is a component allowing you to
 * show documents information, followup, comment it or make an action on it.
 * @version 1
 * @since 13/12/2021
 */

const DocumentInfoAction = () => (
  <div className="docinfo-container">
    <DocumentAction />
    <DocumentInfo />
    <Comment />
  </div>
);

export default DocumentInfoAction;
