import React, {
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash';
import useLoadingPromise from '../../hooks/useLoadingPromise';
import Header from '../../components/Header/Header';
import LabelValueLine from '../../components/LabelValueLine/LabelValueLine';
import SearchInput from '../../components/SearchBox/SearchInput';
import { getInvoice, getFees, resetAmountFees } from '../../services/invoices.service';
import { getUserFromId } from '../../services/user.service';
import { AWColors, formatDate } from '../../helpers/utils';
import ActionButton from '../../components/ActionButton/ActionButton';
import { AlertContext } from '../../contexts/alert.context';

const Invoices = () => {
  const { t } = useTranslation();
  const [invoice, setInvoice] = useState({});
  const { waitWithLoad } = useLoadingPromise();
  const [invoices, setInvoices] = useState([]);
  const [fees, setFees] = useState([]);
  const [error, setError] = useState(false);
  const [user, setUser] = useState([]);
  const { setNotif } = useContext(AlertContext);

  const fetchInvoice = async (search) => {
    setError(false);
    const res = await waitWithLoad(getInvoice(search));
    if (res.success) {
      const validatorUser = res?.data?.validated_by;
      if (validatorUser) {
        const user = await getUserFromId(validatorUser, { fields: 'firstname,lastname,email' });
        setUser(user.data);
      }

      setInvoices([{ ...res.data, name: `${t('Invoice.invoice')} : ${res.data.number}` }]);
    }
  };

  const fetchFees = async (invoiceId, type) => {
    const res = await waitWithLoad(getFees(invoiceId, { type }));
    if (res.success) {
      setFees(res.data);
    }
  };

  const handleReset = () => {
    setInvoices([]);
    setInvoice({});
    setError(false);
  };

  const handleClick = async (invoice) => {
    const { id, type } = invoice;
    setInvoice(invoice);
    fetchFees(id, type);
  };

  const resetAmount = async () => {
    const res = await waitWithLoad(resetAmountFees(invoice.id));
    if (res.success) {
      setFees(res.data);
      setNotif({
        translated: true,
        message: t('Fee.ResetAmount.success'),
        variant: 'success',
      });
    } else {
      setNotif({
        translated: true,
        message: t('Fee.ResetAmount.failed'),
        variant: 'danger',
      });
    }
  };

  return (
    <div className="h-100 d-flex flex-column">
      <Header />
      <div className="d-flex justify-content-center align-items-center mt-5">
        <h1>
          {t('Invoice.title')}
        </h1>
      </div>
      <div className="d-flex justify-content-center h-100 mt-5">
        <div className="cognito-user">

          <SearchInput
            label={t('Invoice.title')}
            placeholder={t('Invoice.placeholderSearch')}
            search={fetchInvoice}
            data={invoices}
            onReset={handleReset}
            onClick={handleClick}
            className="w-100"
          />
          {
              error ? (
                <span className="d-flex justify-content-center">
                  {t('ApiErrors.Server')}
                </span>
              ) : ''
            }
          <h3 className="mt-3">
            {t('Survey.info')}
          </h3>
          <div className="cognito-user__user bg-white">
            <LabelValueLine className="mb-3" label={t('Invoice.Fields.id')}>
              {invoice?.id || ''}
            </LabelValueLine>
            <LabelValueLine className="mb-3" label={t('Invoice.Fields.type')}>
              {invoice?.type ? invoice?.type === 'inbound' ? t('Invoice.Fields.inbound') : t('Invoice.Fields.outbound') : ''}
            </LabelValueLine>
            <LabelValueLine className="mb-3" label={t('Invoice.Fields.number')}>
              {!isNil(invoice?.number) ? invoice.number : ''}
            </LabelValueLine>
            <LabelValueLine className="mb-3" label={t('Survey.status')}>
              {invoice?.status || ''}
            </LabelValueLine>
            <LabelValueLine className="mb-3" label={t('Common.createdAt')}>
              {invoice?.created_at ? formatDate(invoice?.created_at, 'DD/MM/YYYY HH:mm:ss') : ''}
            </LabelValueLine>
            {invoice?.validated_at
              ? (
                <LabelValueLine className="mb-3" label={t('Invoice.Fields.validated_at')}>
                  {invoice?.validated_at ? formatDate(invoice?.validated_at, 'DD/MM/YYYY HH:mm:ss') : ''}
                </LabelValueLine>
              )
              : ''}
            {invoice?.validated_at
              ? (
                <LabelValueLine className="mb-3" label={t('Invoice.Fields.validated_by')}>
                  {`${user?.firstname || ''} ${user?.lastname || ''}`}
                </LabelValueLine>
              )
              : ''}

            {invoice?.validated_at
              ? (
                <LabelValueLine className="mb-3" label={t('Invoice.Fields.validated_by')}>
                  {user?.email || ''}
                </LabelValueLine>
              )
              : ''}
          </div>
          { (invoice?.type === 'outbound' && fees?.length) && (
          <div className="d-flex justify-content-end mt-3">
            <ActionButton
              onClick={resetAmount}
              className="action-button w-50 "
              type="button"
              backgroundColor={AWColors.Green}
              color="white"
            >
              {t('Fee.ResetAmount.label')}
            </ActionButton>
          </div>
          )}
        </div>

      </div>
      { !isEmpty(invoice) && (
      <div className="d-flex justify-content-center h-100 ">
        <div className="cognito-user">
          <h3 className="mt-3">
            {t('Fee.title')}
          </h3>
          <div className="overflow-auto">
            { fees?.length ? (
              fees?.map((fee) => (
                <div className="cognito-user__user bg-white">
                  <LabelValueLine className="mb-3" label={t('Fee.Fields.label')}>
                    {fee?.label || ''}
                  </LabelValueLine>
                  <LabelValueLine className="mb-3" label={t('Fee.Fields.quantity')}>
                    {!isNil(fee?.quantity) ? fee.quantity : ''}
                  </LabelValueLine>
                  <LabelValueLine className="mb-3" label={t('Fee.Fields.unitPrice')}>
                    {!isNil(fee?.unit_price) ? fee.unit_price : ''}
                  </LabelValueLine>
                  <LabelValueLine className="mb-3" label={t('Fee.Fields.vatRate')}>
                    {!isNil(fee?.vat_rate) ? fee.var_rate : ''}
                  </LabelValueLine>
                  <LabelValueLine className="mb-3" label={t('Fee.Fields.providerId')}>
                    {fee?.vendor_id || ''}
                  </LabelValueLine>
                  <LabelValueLine className="mb-3" label={t('Fee.Fields.amountBeforeTaxes')}>
                    {!isNil(fee?.amount_before_taxes) ? fee.amount_before_taxes : ''}
                  </LabelValueLine>
                  <LabelValueLine className="mb-3" label={t('Common.createdAt')}>
                    {fee?.created_at ? formatDate(fee?.created_at, 'DD/MM/YYYY HH:mm:ss') : ''}
                  </LabelValueLine>
                </div>
              ))
            ) : (
              <span className="d-flex justify-content-center">
                {t('Invoice.noFee')}
              </span>
            )}
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default Invoices;
