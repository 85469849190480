import React from 'react';
import PropTypes from 'prop-types';

import UserContextProvider from './user.context';
import EnterprisesContextProvider from './enterprises.context';
import DocumentsContextProvider from './documents.context';
import VendorsListContextProvider from './vendorList.context';
import FileContextProvider from './file.context';
import AlertContextProvider from './alert.context';
import SurveyContextProvider from './survey.context';
import ConnectionConfigContextProvider from './connectionConfig.context';

const CombinedContextProvider = ({ children }) => (
  <AlertContextProvider>
    <UserContextProvider>
      <VendorsListContextProvider>
        <EnterprisesContextProvider>
          <DocumentsContextProvider>
            <FileContextProvider>
              <SurveyContextProvider>
                <ConnectionConfigContextProvider>
                  {children}
                </ConnectionConfigContextProvider>
              </SurveyContextProvider>
            </FileContextProvider>
          </DocumentsContextProvider>
        </EnterprisesContextProvider>
      </VendorsListContextProvider>
    </UserContextProvider>
  </AlertContextProvider>
);

CombinedContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CombinedContextProvider;
