/* eslint-disable no-param-reassign */
import React, {
  useEffect,
  useContext,
  useMemo,
  useState,
} from 'react';
import { cloneDeep } from 'lodash';
import { Switch, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, Spinner } from 'react-bootstrap';

import {
  initMembers,
  initEnterprise,
  initConnectionConfig,
} from './connectionConfig.builder';

import AWFormPage from '../../components/AWFormPage/AWFormPage';
import ProtectedRoute from '../../helpers/ProtectedRoute';
import Content from './Content';
import General from './General';
import { ConnectionConfigContext } from '../../contexts/connectionConfig.context';
import useLoadingPromise from '../../hooks/useLoadingPromise';
import greenCheck from '../../assets/green-check.svg';
import greyCross from '../../assets/grey-cross.svg';
import StatusTag, { Status } from '../../components/StatusTag/StatusTag';
import ActionButton from '../../components/ActionButton/ActionButton';
import { modifyStatusConnectionConfig } from '../../services/enterprise.service';
import { AlertContext } from '../../contexts/alert.context';

const ConnectionConfig = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { enterpriseId, configId } = useParams();

  const [validateModal, setValidateModal] = useState(false);

  const { waitWithLoad, isLoading } = useLoadingPromise();
  const { setNotif } = useContext(AlertContext);
  const {
    enterprise,
    connectionConfig,
    setConnectionConfig,
    isConnectionConfigContentDisabled,
    initStates,
    setMembers,
    setEnterprise,
  } = useContext(ConnectionConfigContext);

  const baseUrl = useMemo(() => {
    let url = `/customers/${enterpriseId}/configs`;
    if (pathname.includes('/new')) {
      url += '/new';
      return url;
    }
    if (configId) {
      url += `/${configId}`;
    }
    return url;
  }, [pathname]);

  const buildConnectionConfig = () => {
    const clone = cloneDeep(connectionConfig);
    clone.steps = clone.steps.map((step) => {
      delete step.display;
      if (step.tasks && step.tasks.length) {
        step.tasks = step.tasks.map((task) => {
          delete task.display;
          return task;
        });
      }
      return step;
    });
    return clone;
  };

  const isSubmitButtonEnabled = useMemo(() => (
    !isLoading && buildConnectionConfig().steps.every((s) => s.tasks.length && s.checkers?.length)
  ), [connectionConfig, isLoading]);

  const getMembers = async () => {
    const _members = await waitWithLoad(initMembers(enterprise));
    setMembers(_members);
  };

  const getConnectionConfig = async () => {
    const _connectionConfig = await waitWithLoad(initConnectionConfig(configId));
    if (_connectionConfig) setConnectionConfig(_connectionConfig);
  };

  const getEnterprise = async () => {
    const _enterprise = await waitWithLoad(initEnterprise(enterpriseId));
    setEnterprise(_enterprise);
    setConnectionConfig({
      ...connectionConfig,
      customer_id: _enterprise.customer_id,
    });
  };

  useEffect(() => {
    getEnterprise();
  }, []);

  useEffect(() => {
    if (enterprise.identification_number && connectionConfig.customer_id) {
      getMembers();
      initStates();
    }
    if (configId) {
      getConnectionConfig();
    }
  }, [
    enterprise.identification_number,
    configId,
    connectionConfig.customer_id,
    connectionConfig.status,
  ]);

  const leftTabs = [
    {
      linkTo: `${baseUrl}/info`,
      active: pathname.includes('/info'),
      disabled: false,
      icon: !isConnectionConfigContentDisabled ? greenCheck : greyCross,
      content: t('ConnectionConfig.info', 'Informations'),
    },
    {
      linkTo: `${baseUrl}/content`,
      active: pathname.includes('/content'),
      icon: isSubmitButtonEnabled ? greenCheck : greyCross,
      disabled: isConnectionConfigContentDisabled,
      content: t('ConnectionConfig.steps', 'Etapes'),
    },
  ];

  const validateConnectionConfig = async () => {
    const res = await modifyStatusConnectionConfig(connectionConfig.id, Status.Validated);
    if (res.success) {
      setNotif({
        translated: true,
        message: t('ConnectionConfig.updateSuccess.', 'Le processus est désormais validé'),
        variant: 'success',
      });
      setConnectionConfig({
        ...connectionConfig,
        status: res.data.status,
      });
    } else {
      setNotif({
        message: t(
          'ConnectionConfig.updateFailed.',
          'Le changement du processus en état validé a échoué (état : brouillon)',
        ),
        variant: 'danger',
      });
    }
    setValidateModal(false);
  };

  if (isLoading) {
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  return (
    <>
      <AWFormPage
        formTitle={t('ConnectionConfig.title', 'Processus de mise en relation')}
        previousPagePath={`/customers/${enterpriseId}/configs`}
      >
        <AWFormPage.Side
          leftTabs={leftTabs}
        >
          <div className="mt-5">
            <div className="d-flex align-items-center">
              <label className="label m-0 px-3" htmlFor="connectionConfigDesc">
                {t('ConnectionConfig.status', 'Statut')}
              </label>
              <div>
                <StatusTag status={connectionConfig?.status || Status.Sketch} />
              </div>
            </div>
          </div>
          {
            connectionConfig?.status === Status.Sketch && connectionConfig.id ? (
              <div className="mt-5">
                <div className="d-flex justify-content-center my-4">
                  <ActionButton
                    backgroundColor="#12957d"
                    color="white"
                    onClick={() => setValidateModal(true)}
                    disabled={(
                      connectionConfig?.status !== Status.Sketch
                      || isConnectionConfigContentDisabled
                      || !isSubmitButtonEnabled
                    )}
                  >
                    {t('Common.validate', 'Valider')}
                  </ActionButton>
                </div>
              </div>
            ) : <> </>
          }
        </AWFormPage.Side>
        <AWFormPage.Main>
          <Switch>
            <ProtectedRoute exact path="/customers/:enterpriseId/configs/new/info">
              <General baseUrl={baseUrl} />
            </ProtectedRoute>
            <ProtectedRoute exact path="/customers/:enterpriseId/configs/new/content">
              <Content
                buildConnectionConfig={buildConnectionConfig}
                isSubmitButtonEnabled={isSubmitButtonEnabled}
              />
            </ProtectedRoute>
            <ProtectedRoute exact path="/customers/:enterpriseId/configs/:configId/info">
              <General baseUrl={baseUrl} />
            </ProtectedRoute>
            <ProtectedRoute exact path="/customers/:enterpriseId/configs/:configId/content">
              <Content
                buildConnectionConfig={buildConnectionConfig}
                isSubmitButtonEnabled={isSubmitButtonEnabled}
              />
            </ProtectedRoute>
          </Switch>
        </AWFormPage.Main>
      </AWFormPage>
      <Modal
        show={validateModal}
        centered
      >
        <Modal.Header>
          <p>{t('ConnectionConfig.validate', 'Valider la configuration')}</p>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t(
              'ConnectionConfig.confirmValidation',
              'Une fois validée, cette configuration ne sera plus modifiable.',
            )}
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <ActionButton
            type="button"
            onClick={() => setValidateModal(false)}
          >
            {t('Common.back', 'Retour')}
          </ActionButton>
          <ActionButton
            backgroundColor="#12957D"
            color="white"
            onClick={validateConnectionConfig}
            type="button"
          >
            {t('Common.validate', 'Valider')}
          </ActionButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConnectionConfig;
