import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const RowEvent = ({ start, end }) => {
  const [time, setTime] = useState();
  const startDate = start.dateHighRes;
  const endDate = end?.dateHighRes || start.dateHighRes + 20;
  const ellapsed = endDate - startDate;
  useEffect(() => {
    if (end) {
      setTime(`${Math.round(ellapsed)}ms`);
    }
  }, [end, ellapsed]);
  const [open, setOpen] = useState(false);
  return (
    <div className="row-event-root" onClick={() => setOpen(!open)}>
      <div>
        <div className="event-toggle">
          <FontAwesomeIcon
            icon={open ? faChevronDown : faChevronRight}
            className="cursor-pointer"
          />
          <div className={`event-type ${end?.executionResult?.type}` || 'in-progress'} />
          {' '}
          {start.event}
        </div>
        {open && (
        <div>
          <ul>
            <li>
              Event:
              {start.event}
            </li>
            <li>
              RequestId:
              {start.requestId}
            </li>
            <li>
              Executed subscriber:
              {start.subscriber}
            </li>
            <li>
              Time:
              {time}
            </li>
            <li>
              Type:
              {end?.executionResult?.type || 'in progress'}
            </li>
            <li>
              Data:
              <code>{JSON.stringify(end?.executionResult?.data || 'No Data', null, 2)}</code>
            </li>
          </ul>
        </div>
        )}
      </div>
    </div>
  );
};

RowEvent.propTypes = {
  start: PropTypes.shape().isRequired,
  end: PropTypes.shape().isRequired,
};

RowEvent.defaultProps = {
};
export default RowEvent;
