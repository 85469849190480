import React, {
  useMemo,
  useEffect,
  useState,
  useContext,
} from 'react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import List from '../List/List';
import ActionButton from '../ActionButton/ActionButton';
import { SurveyContext } from '../../contexts/survey.context';
import { updateQuestionVisible } from '../../services/survey.service';
import { AlertContext } from '../../contexts/alert.context';
import AWForm from '../AWForm/AWForm';

const SurveyResults = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const { survey, setSurvey, reset } = useContext(SurveyContext);
  const { setNotif } = useContext(AlertContext);
  const columns = useMemo(() => [
    {
      label: t('Input.title', 'Libelle'),
      key: 'title',
      width: '55',
    },
    {
      label: t('Input.shortTitle', 'Libelle court'),
      key: 'short_title',
      width: '30',
    },
    {
      label: t('SurveyResults.isVisible', 'Visible par défaut'),
      key: 'is_visible',
      width: '15',
    },
  ], []);

  const updateVisible = (key) => (e) => {
    const newSurvey = cloneDeep(survey);
    newSurvey.content = newSurvey.content.map((page) => (
      page.map((q) => (
        q.key === key ? { ...q, visible: e.target.checked } : q
      ))
    ));
    setSurvey(newSurvey);
  };

  useEffect(() => {
    if (survey.content?.length) {
      setData(
        survey.content.flat().map((question) => (
          {
            key: question.key,
            title: question.title,
            short_title: question.shortTitle,
            is_visible: (
              <Form.Check
                type="checkbox"
                id={question.key}
                checked={question.visible}
                className="visible-checkbox"
                onChange={updateVisible(question.key)}
              />
            ),
          }
        )),
      );
    }
  }, [survey.content]);

  const updateVisibleQuestions = async (e) => {
    e.preventDefault();

    const res = await updateQuestionVisible(
      survey.id,
      survey.content.flat().reduce((acc, question) => ({
        ...acc,
        [question.key]: question.visible,
      }), {}),
    );
    res.displayNotif(setNotif);
    if (res.success) {
      setSurvey({ ...survey, ...res.data });
      setNotif({
        translated: true,
        message: t('Survey.editSuccess', 'Le questionnaire a bien été sauvegardé'),
        variant: 'success',
      });
      reset();
    }
  };

  return (
    <AWForm
      title={t('SurveyResults.resultsList', 'Liste de résultats')}
      loading={false}
      onSubmit={updateVisibleQuestions}
      width="85%"
    >
      <List
        columns={columns}
        data={data}
        paddingBottom={100}
      />
      <div className="d-flex justify-content-center">
        <ActionButton
          type="submit"
          buttonClassName="w-25 mt-4"
          backgroundColor="#12957d"
          color="white"
        >
          <span>
            {t('SurveyLanding.submit', 'Enregistrer')}
          </span>
        </ActionButton>
      </div>
    </AWForm>
  );
};

export default SurveyResults;
