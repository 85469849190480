import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const VendorsListContext = createContext();

// eslint-disable-next-line
const VendorsListContextProvider = ({ children }) => {
  const [searchEvent, setSearchEventOnly] = useState(0);

  const setSearchEvent = () => {
    setSearchEventOnly(searchEvent + 1);
  };

  const value = useMemo(() => ({ searchEvent, setSearchEvent }), [searchEvent]);

  return (
    <VendorsListContext.Provider value={value}>
      {children}
    </VendorsListContext.Provider>
  );
};

VendorsListContextProvider.propTypes = {
  // eslint-disable-next-line
  chidlren: PropTypes.node,
};

VendorsListContextProvider.defaultProps = {
  // eslint-disable-next-line
  children: <></>,
};

export default VendorsListContextProvider;
