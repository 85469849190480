import React, {
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';
import * as DocumentService from '../services/document.service';
import { getUserFromId } from '../services/user.service';
import { AlertContext } from './alert.context';

export const DocumentsContext = createContext();

// eslint-disable-next-line react/prop-types
const DocumentsContextProvider = ({ children }) => {
  const [allDocuments, setAllDocuments] = useState([]);
  const [document, setDocument] = useState({});
  const [comments, setComments] = useState([]);
  const [username, setUsername] = useState();
  const [documentLoading, setLoading] = useState();

  const { setNotif } = useContext(AlertContext);

  const getDocumentsFromEnterprise = async (enterpriseId) => {
    await setLoading(true);
    if (enterpriseId) {
      try {
        const documentsResponse = await DocumentService
          .getDocumentsFromEnterprise(enterpriseId);
        documentsResponse.displayNotif(setNotif);
        if (documentsResponse.success) {
          setAllDocuments(documentsResponse.data);
        }
      } catch {
        await setLoading(false);
      }
    }
    await setLoading(false);
  };

  const getDocumentActionUserName = async (userId) => {
    if (userId) {
      const userResponse = await getUserFromId(
        userId,
        { fields: 'firstname,lastname' },
      );
      userResponse.displayNotif(setNotif);
      if (userResponse.success) {
        setUsername(userResponse.data);
      }
    }
  };

  const value = useMemo(() => ({
    allDocuments,
    getDocumentsFromEnterprise,
    setAllDocuments,
    document,
    setDocument,
    comments,
    setComments,
    username,
    documentLoading,
    setLoading,
    getDocumentActionUserName,
  }), [allDocuments, document, comments, username, documentLoading]);
  return (
    <DocumentsContext.Provider
      value={value}
    >
      {children}
    </DocumentsContext.Provider>
  );
};

export default DocumentsContextProvider;
