import React, { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { EnterprisesContext } from '../../contexts/enterprises.context';

const FilterRadio = ({
  onChangeValue, title, choices, propertyName, size,
}) => {
  const { filters } = useContext(EnterprisesContext);

  const [active, setActive] = useState(
    filters[propertyName]
      || choices.find((choice) => !!choice.default)?.value
      || '',
  );
  const isActive = (name) => name === active;

  const filterChange = (value) => (e) => {
    setActive(value);
    onChangeValue(e?.target?.value);
  };

  const classNameSize = useMemo(
    () => (size === 'big' ? 'filtre-option__big' : ''),
    [size],
  );

  return (
    <div className="label-and-option">
      {title && <div className="filtre-label">{title}</div>}
      <div>
        {choices.map((choice) => (
          <span key={choice.name}>
            <input
              type="radio"
              className="btn-check"
              id={choice.name}
              value={choice.value}
              onChange={filterChange(choice.value)}
              checked={isActive(choice.value)}
            />
            <label
              className={`btn filtre-option ${classNameSize}`}
              htmlFor={choice.name}
            >
              {choice.message}
            </label>
          </span>
        ))}
      </div>
    </div>
  );
};

FilterRadio.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  choices: PropTypes.any,
  onChangeValue: PropTypes.func,
  title: PropTypes.string,
  propertyName: PropTypes.string,
  size: PropTypes.string,
};

FilterRadio.defaultProps = {
  choices: [],
  onChangeValue: '',
  title: '',
  propertyName: '',
  size: '',
};

export default FilterRadio;
