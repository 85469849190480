// eslint-disable-next-line
import i18n from '../i18n';
import { alphaSort } from './utils';

export const V1_PLATFORM = 'https://support.addworking.io';

export const ACTION_STATUS = {
  validated: 'validated',
  rejected: 'rejected',
  precheck: 'precheck',
  pending: 'pending',
};

export const TYPE_NAME_DOCUMENT_URSSAF = 'certificate_of_payment_social_contribution';

export const TYPES = {
  String: 'string',
  Array: 'array',
  Boolean: 'boolean',
  Number: 'number',
};

export const FORMATS = {
  Multi: 'multi',
  Checkbox: 'checkbox',
  Select: 'select',
  Date: 'date',
  Text: 'text',
  Range: 'range',
  File: 'file',
};

export const ConnectionTaskContents = {
  DocumentType: 'DOCUMENT_TYPE',
  Compliance: 'COMPLIANCE',
  LegalCompliance: 'LEGAL_COMPLIANCE',
  BusinessCompliance: 'BUSINESS_COMPLIANCE',
  CustomField: 'CUSTOM_FIELD',
  Fields: 'FIELDS',
  Activity: 'ACTIVITY',
  Form: 'FORM',
};

export const ConditionTypes = {
  CustomField: 'CUSTOM_FIELD',
  Form: 'FORM',
};

export const ConditionTypesList = [
  { value: ConditionTypes.CustomField, label: i18n.t('Task.customField', 'Champ personnalisé') },
  { value: ConditionTypes.Form, label: i18n.t('Task.survey', 'Questionnaire') },
];

export const ConditionModes = {
  Hidden: 'hidden',
  Validation: 'validation',
};

export const TypesWithData = [
  ConnectionTaskContents.CustomField,
  ConnectionTaskContents.DocumentType,
  ConnectionTaskContents.Fields,
  ConnectionTaskContents.Form,
];

export const taskContents = [
  {
    label: i18n.t('Task.completion', 'Complétion du profil'),
    value: 'COMPLETION',
  },
  {
    label: i18n.t('Task.compliance', 'Conformité'),
    value: 'COMPLIANCE',
  },
  {
    label: i18n.t('Task.legalCompliance', 'Conformité légale'),
    value: 'LEGAL_COMPLIANCE',
  },
  {
    label: i18n.t('Task.businessCompliance', 'Conformité métier'),
    value: 'BUSINESS_COMPLIANCE',
  },
  {
    label: i18n.t('Task.passwork', 'Passwork'),
    value: 'PASSWORK',
  },
  {
    label: i18n.t('Task.documentType', 'Type de document'),
    value: 'DOCUMENT_TYPE',
  },
  {
    label: i18n.t('Task.fields', "Informations de l'entreprise"),
    value: 'FIELDS',
  },
  {
    label: i18n.t('Task.activity', 'Activité'),
    value: 'ACTIVITY',
  },
  {
    label: i18n.t('Task.customField', 'Champ personnalisé'),
    value: 'CUSTOM_FIELD',
  },
  {
    label: i18n.t('Task.survey', 'Questionnaire'),
    value: 'FORM',
  },
].sort(alphaSort('label'));

export const enterpriseInformations = [
  {
    label: i18n.t('Enterprise.department', 'Couverture géographique'),
    value: 'departments',
  },
  {
    label: i18n.t('Enterprise.website', 'Site internet'),
    value: 'website',
  },
  {
    label: i18n.t('Enterprise.commercialBrochure', 'Plaquette commerciale'),
    value: 'commercial_brochure',
  },
  {
    label: i18n.t('Enterprise.turnover', "Chiffre d'affaires"),
    value: 'business_turnover',
  },
  {
    label: i18n.t('Enterprise.employeesNumber', 'Effectif'),
    value: 'employees_number',
  },
].sort(alphaSort('label'));

export const statusListToString = {
  1: 'sketch',
  2: 'validated',
};

export const statusList = {
  sketch: {
    color: '#727272',
    background: 'rgba(114, 114, 114, 0.05)',
  },
  ready: {
    color: '#12957D',
    background: 'rgba(18, 149, 125, 0.05)',
  },
  launched: {
    color: '#E34A30',
    background: 'rgba(227,74,48, 0.05)',
  },
  stopped: {
    color: '#727272',
    background: 'rgba(114, 114, 114, 0.05)',
  },
  validated: {
    color: '#12957D',
    background: 'rgba(18, 149, 125, 0.05)',
  },
};

export const country = {
  FR: 'fr',
  DE: 'de',
  BE: 'be',
  LU: 'lu',
};
