import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import DraggableQuestion, { ItemTypes } from '../DraggableQuestion/DraggableQuestion';
import { randString } from '../../helpers/utils';
import { updateSurvey, deleteQuestion } from '../../services/survey.service';
import { AlertContext } from '../../contexts/alert.context';
import { SurveyContext } from '../../contexts/survey.context';
import { Status } from '../StatusTag/StatusTag';
import ActionButton from '../ActionButton/ActionButton';

const QuestionsList = ({ questions, id, onChangeQuestion }) => {
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const [indexHovered, setIndexHovered] = useState();
  const { setNotif } = useContext(AlertContext);
  const {
    setQuestion,
    setSurvey,
    survey,
    question,
  } = useContext(SurveyContext);

  useEffect(() => {
    setList(
      questions.reduce((acc, page, index) => (
        [
          ...acc,
          // eslint-disable-next-line
          { page: true, id: randString(), position: index + 1 },
          ...page,
        ]
      ), []),
    );
  }, [questions]);

  const getBaseIndex = useCallback((key) => (
    list.map((x) => x.key).indexOf(key)
  ), [list]);

  const moveQuestion = (card) => {
    setIndexHovered(null);
    if (!card.page) {
      let clone = cloneDeep(list);
      const item = clone.find((question) => question.key === card.id);
      clone = clone.filter((question) => question.key !== card.id);
      clone.splice(getBaseIndex(card.id) > card.index ? card.index + 1 : card.index, 0, item);
      if (clone[0].page) {
        setList(clone);
        rebuildQuestions(clone);
      }
    }
  };

  const [, drop] = useDrop({
    accept: ItemTypes.Question,
    drop: moveQuestion,
  });

  const addPage = () => {
    if (!question) {
      const count = list.filter((item) => item.page).length;
      setList([...list, { page: true, id: randString(), position: (count + 1) }]);
    }
  };

  const listToContent = (_list) => _list.reduce((acc, item) => {
    if (item.page) {
      acc.push([]);
    } else {
      acc[acc.length - 1].push(item);
    }
    return acc;
  }, []).filter((page) => !!page.length);

  const rebuildQuestions = async (updatedList) => {
    const content = listToContent(updatedList);
    const updateRes = await updateSurvey(id, { content });
    updateRes.displayNotif(setNotif);
    if (updateRes.success) {
      setSurvey({ ...survey, content });
    }
  };

  const setActiveQuestion = (key) => {
    setQuestion(list.find((item) => item.key === key));
  };

  const handleTitle = async (title) => {
    const clone = cloneDeep(survey);
    if (clone.pageTitles) {
      const titleIndex = clone.pageTitles.findIndex((t) => t.position === title.position);
      if (titleIndex > -1) {
        clone.pageTitles[titleIndex] = title;
      } else {
        clone.pageTitles.push(title);
      }
      setSurvey(clone);
    } else {
      clone.pageTitles = [title];
    }
    setSurvey(clone);
    const res = await updateSurvey(id, clone);
    res.displayNotif(setNotif);
  };

  const addQuestion = (nextItem) => {
    setQuestion({});
    const pages = list.filter((item) => item.page).map((item) => item.id);
    if (!nextItem) {
      onChangeQuestion(pages.length - 1);
    } else {
      const index = pages.indexOf(nextItem.id) - 1;
      onChangeQuestion(index);
    }
  };

  const handleDelete = async (key) => {
    const response = await deleteQuestion(survey.id, key);
    response.displayNotif(setNotif);
    if (response.success) {
      const newList = list.filter((item) => item.key !== key);
      setSurvey({ ...survey, content: listToContent(newList) });
    }
  };

  const Card = useCallback(({ card, index, children }) => {
    if (survey?.status !== Status.Sketch) {
      return (
        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-static-element-interactions */
        <div
          onClick={() => setActiveQuestion(card.key)}
          className={card.page ? 'page-block' : 'question-block question-block--fixed'}
        >
          {card.page ? `Page ${card.position}` : card.title}
        </div>
      );
    }
    return (
      <>
        <DraggableQuestion
          key={card.key}
          onClick={setActiveQuestion}
          index={index}
          id={card.key}
          text={card.title}
          onDrop={moveQuestion}
          onHover={setIndexHovered}
          onDelete={handleDelete}
          page={card.page}
          type={card.page}
          position={card.position}
          handleTitle={handleTitle}
        />
        {children}
        {(list[index + 1]?.page || !list[index + 1]) && (
          (
            <div className="text-center">
              <button onClick={() => addQuestion(list[index + 1])} type="button" className="question-list-button question-list-button__add-question">
                {`+ ${t('QuestionsList.addQuestion', 'Ajouter une question')}`}
              </button>
            </div>
          )
        )}
      </>
    );
  }, [list]);

  return (
    <div ref={drop}>
      {
        list.map((question, index) => (
          <Card key={question.page ? question.id : question.key} card={question} index={index}>
            {
              indexHovered === index ? (
                <div className="question-block--hover" />
              ) : ''
            }
          </Card>
        ))
      }
      <ActionButton
        buttonClassName="my-4 text-center dashed-button"
        disabled={survey?.status !== Status.Sketch || list[list.length - 1]?.page}
        onClick={addPage}
      >
        {t('QuestionsList.addPage', 'Ajouter une page')}
      </ActionButton>
    </div>
  );
};

QuestionsList.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        format: PropTypes.string,
        items: PropTypes.arrayOf(
          PropTypes.shape(
            {
              type: PropTypes.string,
              value: PropTypes.string,
            },
          ),
        ),
      }),
    ),
  ).isRequired,
  id: PropTypes.string,
  onChangeQuestion: PropTypes.func.isRequired,
};

QuestionsList.defaultProps = {
  id: '',
};

export default QuestionsList;
