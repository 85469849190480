import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Route } from 'react-router-dom/';
import PropTypes from 'prop-types';
import { currentSession } from './session';
import { UserContext } from '../contexts/user.context';

const ProtectedRoute = ({ children, path, exact }) => {
  const [safeChildren, setSafeChildren] = useState();
  const { pathname } = useLocation();
  const { setRedirection, getRedirection, logOut } = useContext(UserContext);

  const forceRedirect = (path = '/') => {
    if (!path.match(/https?:\/\//) && path.startsWith('/')) {
      window.open(path, '_self');
    }
  };

  useEffect(() => {
    (async () => {
      const session = await currentSession();
      if (session.isLogged) {
        const redirect = await getRedirection();
        if (redirect && redirect !== '/dashboard') {
          forceRedirect(redirect);
        }
        setSafeChildren(children);
      } else {
        setRedirection(pathname);
        logOut();
        forceRedirect();
      }
    })();
  }, [children, pathname]);

  return (
    <Route
      path={path}
      exact={exact}
      // eslint-disable-next-line
      render={() => safeChildren}
    />
  );
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  path: PropTypes.string,
  children: PropTypes.element,
  exact: PropTypes.bool,
};

ProtectedRoute.defaultProps = {
  children: '',
  path: '',
  exact: false,
};
