import { safeFetch } from '../helpers/response-handler';
import { queryToString } from '../helpers/utils';

const { REACT_APP_URL_COMPLIANCE_API } = process.env;

// REACT_APP_URL_API_DOCUMENT -> Monorepo
// REACT_APP_URL_DOCUMENT_API -> Old microservice
/** Be careful this is two differents services */
const { REACT_APP_URL_API_DOCUMENT, REACT_APP_URL_DOCUMENT_API } = process.env;

const uriApiDocument = `${REACT_APP_URL_API_DOCUMENT}/v1/document${process.env.NODE_ENV.match(/prod(uction)?/) ? '-ai' : ''}`;

export const getStatsOnDocumentTypes = (query = {}, body = {}) => safeFetch(
  `${uriApiDocument}/documents/stats${queryToString(query)}`,
  {
    method: 'POST',
    body: JSON.stringify(body),
  },
);

// eslint-disable-next-line
export const getDocumentsFromEnterprise = (enterpriseId) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/enterprises/${enterpriseId}/documents`,
);

export const precheck = (documentId) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/document/${
    documentId}/precheck`,
  {
    method: 'POST',
  },
);

export const removePrecheck = (documentId) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/document/${
    documentId}/remove-precheck`,
  {
    method: 'POST',
  },
);

export const accept = (documentId, body) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/document/${
    documentId}/accept`,
  {
    method: 'POST',
    body: JSON.stringify(body),
  },
);

export const reject = (documentId, body) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/document/${
    documentId}/reject`,
  {
    method: 'POST',
    body: JSON.stringify(body),
  },
);

export const rejectAllDocuments = (body) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/document/reject-all`,
  {
    method: 'POST',
    body: JSON.stringify(body),
  },
);

export const updateDocument = (documentId, body) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/document/${documentId}`,
  {
    method: 'PATCH',
    body: JSON.stringify(body),
  },
);

export const validAuthenticityProof = (documentId, mime, key) => (
  safeFetch(
    `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/document/${documentId}/authenticity-proof`,
    {
      method: 'POST',
      body: JSON.stringify({ key, mime }),
    },
  )
);

export const getLastJsonFile = (documentId) => safeFetch(
  `${REACT_APP_URL_DOCUMENT_API}/v1/document/support/urssaf/${documentId}/historic/lastValidation`,
  {
    method: 'GET',
  },
);

export const getJsonFileOfDocument = (body) => safeFetch(
  `${REACT_APP_URL_DOCUMENT_API}/v1/document/support/urssaf?enterpriseId=true`,
  {
    method: 'POST',
    body: JSON.stringify(body),
  },
);

export const getDocumentTypesOfAddworking = (query = {}) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/documentType${queryToString(query)}`,
  {
    method: 'GET',
  },
);

export const getDocumentTypesOfCustomer = (customerId) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/document-type/customers/${customerId}`,
  {
    method: 'GET',
  },
);

export const search = (ressource, body, opts) => {
  const preparedParams = Object.entries(opts).reduce((acc, [key, value]) => {
    if (key === 'groupBy' && Array.isArray(value) && value.length > 0) {
      acc[key] = value.join(',');
    } else {
      acc[key] = typeof value === 'object' ? JSON.stringify(value) : value;
    }
    return acc;
  }, {});

  const params = new URLSearchParams(preparedParams).toString();
  const url = `${uriApiDocument}/${ressource}/search${params ? `?${params}` : ''}`;

  return safeFetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });
};
