import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

const Tooltip = ({
  children,
  place,
  type,
  effect,
  id,
  className,
}) => (
  <ReactTooltip
    place={place}
    type={type}
    effect={effect}
    id={id}
    className="tooltip opaque"
  >
    <span className={`tip ${className}`}>
      {children}
    </span>
  </ReactTooltip>
);

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  place: PropTypes.string,
  type: PropTypes.string,
  effect: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
};

Tooltip.defaultProps = {
  place: 'right',
  type: 'light',
  effect: 'solid',
  id: '',
  className: '',
};

export default Tooltip;
