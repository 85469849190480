import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '../helpers/ProtectedRoute';
import Enterprises from '../views/Enterprises/Enterprises';
import Enterprise from '../views/Enterprise/Enterprise';

const EnterprisesRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <ProtectedRoute path={`${path}/`} exact>
        <Enterprises />
      </ProtectedRoute>

      <ProtectedRoute path={`${path}/:enterpriseId`}>
        <Enterprise />
      </ProtectedRoute>
    </Switch>
  );
};

export default EnterprisesRoutes;
