import React from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../../components/Header/Header';
import addworkingLogo from '../../assets/logo_addworking.svg';

const Forbidden = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid h-90-vh">
      <div className="row h-100-percent">
        <Header />
        <div className="d-flex flex-column justify-content-center align-items-center h-100-percent">
          <img src={addworkingLogo} alt="AddWorking Logo" width="150" />
          <h1 className="mt-5">
            {t('Errors.forbiddenApp', "Vous n'avez pas les autorisations nécessaires pour accéder à cette application")}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Forbidden;
