import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '../helpers/ProtectedRoute';
import DoctypesCollections from '../views/DoctypesCollections/DoctypesCollections';

const doctypeCollectionRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <ProtectedRoute path={`${path}/`} exact>
        <DoctypesCollections />
      </ProtectedRoute>
    </Switch>
  );
};

export default doctypeCollectionRoutes;
