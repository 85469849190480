import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Form,
  Modal,
  Spinner,
} from 'react-bootstrap';
import Header from '../../components/Header/Header';
import { safeFetch } from '../../helpers/response-handler';
import ActionButton from '../../components/ActionButton/ActionButton';
import { AWColors, getMultiLangLabel } from '../../helpers/utils';
import i18n from '../../i18n';
import useLoadingPromise from '../../hooks/useLoadingPromise';
import { AlertContext } from '../../contexts/alert.context';

const ADDWORKING_ID = 'f1494810-ed7a-406f-8aeb-7845c4105b01';

const RIGHTS = {
  role: {
    general: [
      'is_admin',
      'is_power_user',
      'is_operator',
      'is_legal_representative',
      'is_signatory',
      'is_allowed_to_invite_vendors',
      'is_allowed_to_view_business_turnover',
      'is_financial',
    ],
    conformity: [
      'is_vendor_compliance_manager',
      'is_customer_compliance_manager',
    ],
    operations: [
      'is_work_field_creator',
      'is_work_field_administrator',
      'is_mission_offer_broadcaster',
      'is_mission_offer_closer',
      'is_contract_creator',
      'is_document_validator',
      'is_allowed_to_send_contract_to_signature',
      'is_accounting_monitoring',
    ],
  },
  accesses: [
    'access_to_billing',
    'access_to_contract',
    'access_to_enterprise',
    'access_to_mission',
    'access_to_mission_purchase_order',
    'access_to_user',
    'read_evaluations',
    'create_evaluation',
    'update_evaluation',
    'delete_evaluation',
  ],
};

const Groups = () => {
  const { t } = useTranslation();
  const { isLoading, waitWithLoad } = useLoadingPromise();
  const { isLoading: upsertLoading, waitWithLoad: waitUpsert } = useLoadingPromise();
  const { setNotif } = useContext(AlertContext);

  const [groups, setGroups] = useState([]);
  const [groupModal, setGroupModal] = useState({});
  const [validationMode, setValidationMode] = useState(false);

  const getGroups = async () => {
    const groupsRes = await safeFetch(
      `${process.env.REACT_APP_URL_USER_API}/v1/user/enterprises/${ADDWORKING_ID}/groups`,
    );
    if (groupsRes.success) {
      setGroups(groupsRes.data.filter((group) => !group.enterprise_id));
    }
  };

  useEffect(() => {
    waitWithLoad(getGroups());
  }, []);

  const RightCheck = useCallback(({ value }) => (
    <Form.Check
      checked={groupModal.group?.rights?.includes(value)}
      label={t(`Rights.${value}`)}
      value={value}
      id={value}
      onChange={handleGroupRight}
    />
  ), [groupModal]);

  const handleGroupChange = (key) => (e) => {
    setGroupModal((prev) => ({
      ...prev,
      group: {
        ...prev.group,
        [key]: e.target.value,
      },
    }));
  };

  const handleGroupRight = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setGroupModal(
        (prev) => ({
          ...prev,
          group: {
            ...prev.group,
            rights: [...new Set([...(prev.group.rights ?? []), value])],
          },
        }),
      );
    } else {
      setGroupModal((prev) => ({
        ...prev,
        group: {
          ...prev.group,
          rights: (prev.group.rights ?? []).filter((right) => right !== value),
        },
      }));
    }
  };

  const upsertGroup = async () => {
    const body = {
      label: groupModal.group.label,
      description: groupModal.group.description,
      rights: groupModal.group.rights,
    };
    if (groupModal.group.id) {
      const updateGroupRes = await safeFetch(
        `${process.env.REACT_APP_URL_USER_API}/v1/user/system-groups/${groupModal.group.id}`,
        {
          method: 'PATCH',
          body: JSON.stringify(body),
        },
      );
      if (updateGroupRes.success) {
        setNotif({
          translated: true,
          message: t('Groups.updateSuccess'),
          variant: 'success',
        });
        waitWithLoad(getGroups());
      } else {
        setNotif({
          translated: true,
          message: t('Groups.updateFail'),
          variant: 'danger',
        });
      }
    } else {
      const createGroupRes = await safeFetch(
        `${process.env.REACT_APP_URL_USER_API}/v1/user/system-groups`,
        {
          method: 'POST',
          body: JSON.stringify(body),
        },
      );
      if (createGroupRes.success) {
        setNotif({
          translated: true,
          message: t('Groups.createSuccess'),
          variant: 'success',
        });
        waitWithLoad(getGroups());
      } else {
        setNotif({
          translated: true,
          message: t('Groups.createFail'),
          variant: 'danger',
        });
      }
    }
    setGroupModal({});
    setValidationMode(false);
  };

  const createMode = groupModal.group && !groupModal.group.id;

  const getValidationButtonName = () => {
    if (validationMode) {
      return createMode ? t('Common.confirm') : t('Common.continue');
    }
    return createMode ? t('Common.add') : t('Common.update');
  };

  return (
    <div className="groups">
      <Modal
        show={!!groupModal.show}
        onHide={() => setGroupModal({})}
        centered
        className="groups"
      >
        <Modal.Header closeButton>
          {t(createMode ? 'Groups.add' : 'Groups.update')}
        </Modal.Header>
        <Modal.Body>
          {createMode ? (
            <p className="text-danger mt-1 mb-3">
              {t('Groups.creationWarning')}
            </p>
          ) : null}
          <div
            className="d-flex flex-column gap-3"
          >
            <div className="d-flex flex-column">
              <label className="text-secondary" htmlFor="group-name">
                {t('Groups.name')}
              </label>
              <Form.Control
                id="group-name"
                className="border"
                value={getMultiLangLabel(i18n, groupModal.group?.label) ?? ''}
                onChange={handleGroupChange('label')}
              />
            </div>
            <div className="d-flex flex-column">
              <label className="text-secondary" htmlFor="group-description">
                {t('Groups.description')}
              </label>
              <Form.Control
                id="group-description"
                className="border"
                value={getMultiLangLabel(i18n, groupModal.group?.description) ?? ''}
                onChange={handleGroupChange('description')}
              />
            </div>
            <div>
              <div className="h-40-vh overflow-auto">
                <p>{t('Rights.roles')}</p>
                <label className="text-secondary" htmlFor="group-rights">
                  {t('Rights.general')}
                </label>
                {RIGHTS.role.general.map((right) => (
                  <RightCheck key={right} value={right} />
                ))}
                <label className="text-secondary" htmlFor="group-rights">
                  {t('Rights.compliance')}
                </label>
                {RIGHTS.role.conformity.map((right) => (
                  <RightCheck key={right} value={right} />
                ))}
                <label className="text-secondary" htmlFor="group-rights">
                  {t('Rights.operations')}
                </label>
                {RIGHTS.role.operations.map((right) => (
                  <RightCheck key={right} value={right} />
                ))}
                <p className="mt-4 mb-2">
                  {t('Rights.appAccess')}
                </p>
                {RIGHTS.accesses.map((right) => (
                  <RightCheck key={right} value={right} />
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex-nowrap">
          {validationMode ? (
            <p className="text-danger me-3">
              <small>
                {t(createMode ? 'Groups.validationWarning' : 'Groups.updateWarning')}
              </small>
            </p>
          ) : null}
          <ActionButton
            onClick={() => (
              validationMode
                ? setValidationMode(false)
                : setGroupModal({})
            )}
          >
            {t('Common.cancel')}
          </ActionButton>
          <ActionButton
            backgroundColor={validationMode ? AWColors.Red : AWColors.Green}
            color="white"
            onClick={() => (
              !validationMode
                ? setValidationMode(true)
                : waitUpsert(upsertGroup()))}
            disabled={
              !groupModal.group
              || !groupModal.group.label
              || !groupModal.group.description
              || upsertLoading
            }
          >
            {getValidationButtonName()}
          </ActionButton>
        </Modal.Footer>
      </Modal>
      <Header />
      <Container>
        <h1 className="text-center mt-3 mb-5">
          {t('Groups.manage')}
        </h1>
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="d-flex flex-column gap-4 align-items-center">
              {groups.map((group) => (
                <div
                  key={group.id}
                  className="border rounded bg-white shadow-sm p-3 w-50 pointer"
                  onClick={() => setGroupModal({ show: true, group })}
                >
                  <p className="group-label">
                    {getMultiLangLabel(i18n, group.label)}
                  </p>
                  <p>
                    {getMultiLangLabel(i18n, group.description)}
                  </p>
                </div>
              ))}
            </div>
            <div className="add-group-btn">
              <ActionButton
                backgroundColor={AWColors.Green}
                color="white"
                onClick={() => setGroupModal({ show: true, group: {} })}
              >
                {t('Groups.add')}
              </ActionButton>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default Groups;
