import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DocumentStatusIcon from '../DocumentStatusIcon/DocumentStatusIcon';
import { toKey } from '../../helpers/utils';
import { DocumentsContext } from '../../contexts/documents.context';
import link from '../../assets/link.svg';
import { V1_PLATFORM, TYPE_NAME_DOCUMENT_URSSAF } from '../../helpers/constants';
import { UserContext } from '../../contexts/user.context';
import jsonLogo from '../../assets/json-svgrepo.svg';

const ActiveDocumentTab = ({ documents }) => {
  const { t } = useTranslation();
  const { setDocument, document } = useContext(DocumentsContext);
  const { enterpriseId } = useParams();
  const { user } = useContext(UserContext);

  const typeDisplayName = (doc) => (
    doc.type_display_name.includes('{')
      ? JSON.parse(doc.type_display_name)?.fr
      : doc.type_display_name
  );

  const setActiveDocument = (doc) => () => {
    setDocument(doc);
  };

  const isActive = (doc) => {
    if (!doc.document_id) {
      return doc.type_id === document.type_id;
    }
    return document?.document_id === doc.document_id;
  };

  const getFileId = (document) => document.file_ids.at(0);

  const { REACT_APP_DEV_USERS } = process.env;

  const users = REACT_APP_DEV_USERS?.split(',');

  return (
    <div className="document-list">
      {documents.map((doc) => (
        <div
          key={toKey(doc)}
          className={
            isActive(doc)
              ? 'document-list__item document-list__item__active'
              : 'document-list__item'
          }
          onClick={setActiveDocument(doc)}
          aria-hidden="true"
        >
          <div className="document-list__icons">
            {doc.is_pre_check && <DocumentStatusIcon status="precheck" />}
            <DocumentStatusIcon status={doc?.status || ''} />
          </div>
          <div className="document-list__title">
            {typeDisplayName(doc)}
            &nbsp;&nbsp;
            <a
              href={
                `${V1_PLATFORM}/addworking/enterprise/${
                  enterpriseId
                }/document/document_type/${
                  doc.type_id
                }/history`
              }
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={link}
                alt="link"
                aria-hidden="true"
              />
            </a>
            { users?.includes(user.email) && doc?.type_name === TYPE_NAME_DOCUMENT_URSSAF && (
              <a
                href={`/compliance/provider/${enterpriseId}/document/${getFileId(doc)}/json`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={jsonLogo}
                  alt="json"
                  title="json"
                  className="link"
                />
              </a>
            )}
            <br />
            <span className="document-list__client">{doc.customer_name}</span>
            {doc.falsified ? (
              <>
                <br />
                <span className="document-list__falsified">{t('DocumentListTab.falsified')}</span>
              </>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

ActiveDocumentTab.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape(),
  ),
};

ActiveDocumentTab.defaultProps = {
  documents: [],
};

export default ActiveDocumentTab;
