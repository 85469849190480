import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from 'react-bootstrap';
import { AlertContext } from '../../contexts/alert.context';

const AddworkingAlert = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { notif } = useContext(AlertContext);

  useEffect(() => {
    if (notif.message) {
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 3000);
    }
  }, [notif]);

  const message = useMemo(() => {
    if (notif.translated) {
      return notif.message;
    }
    return notif?.message ? t(`ApiErrors.${notif.message}`) : '';
  }, [notif]);

  return (
    <Alert
      variant={notif.variant || 'danger'}
      show={show}
      className="ad-alert"
    >
      {message}
    </Alert>
  );
};

export default AddworkingAlert;
