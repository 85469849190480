import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';

import Display from '../Display/Display';
import UploadFile from '../UploadFile/UploadFile';

import '../../styles/css/style.css';

import { validAuthenticityProof } from '../../services/document.service';
import { getUploadSignedURL, uploadToS3 } from '../../services/file.service';
import { DocumentsContext } from '../../contexts/documents.context';
import { FileContext } from '../../contexts/file.context';
import { AlertContext } from '../../contexts/alert.context';
import { EnterprisesContext } from '../../contexts/enterprises.context';

import usePrevious from '../../hooks/usePrevious';

const NO_PROOF_ID = 'PROOF';

/**
 * DocumentDisplay is a component that takes a list of files from a document and displaying
 * them in different tabs.
 * @component
 * @version 1
 * @since 13/12/2021
 * @example
 * return (
 *  <DocumentDisplay />
 * )
 */

const DocumentDisplay = () => {
  const [activeFile, setActiveFile] = useState();
  const [loading, setLoading] = useState(false);
  const { document, getDocumentsFromEnterprise } = useContext(DocumentsContext);
  const { enterprise } = useContext(EnterprisesContext);
  const { file, getDocumentFile, setFile } = useContext(FileContext);
  const { setNotif } = useContext(AlertContext);
  const [documentsToDisplay, setDocumentsToDisplay] = useState([]);
  const { t } = useTranslation();
  const prevDocument = usePrevious(document);
  const prevFile = usePrevious(file);

  useEffect(() => {
    if (
      document
      && document.file_ids
      && prevDocument?.document_id !== document?.document_id
    ) {
      setLoading(true);
      const id = document.file_ids[0];
      getDocumentFile(id);
      setDocumentsToDisplay(document.file_ids);
      setActiveFile(id);
    }
  }, [document]);

  useEffect(() => {
    if (!prevFile?.url || (prevFile?.url !== file?.url)) {
      setLoading(false);
    }
  }, [file]);

  const handleClick = (docId) => () => {
    if (docId && docId !== NO_PROOF_ID) {
      setLoading(true);
      getDocumentFile(docId);
    } else {
      setFile({});
    }
    setActiveFile(docId);
  };

  const isActive = (docId) => activeFile === docId;

  const handleErrorFile = (message) => {
    setNotif({ message, translated: true });
  };

  const uploadFile = async (f) => {
    const urlResponse = await getUploadSignedURL(f.type);
    urlResponse.displayNotif(setNotif);
    if (urlResponse.success) {
      const uploadResponse = await uploadToS3(urlResponse.data.signedUrl, f);
      uploadResponse.displayNotif(setNotif);
      if (uploadResponse.success) {
        const validResponse = await validAuthenticityProof(
          document?.document_id,
          f.type,
          urlResponse.data.key,
        );
        validResponse.displayNotif(setNotif);
        if (validResponse.success) {
          getDocumentFile(urlResponse.data.key);
          getDocumentsFromEnterprise(enterprise.id);
          setActiveFile(urlResponse.data.key);
        }
      }
    }
  };

  const submitProof = (file) => {
    setLoading(true);
    uploadFile(file);
  };

  const DocumentScreen = useCallback(() => {
    if (loading) {
      return (
        <div className="outlet">
          <div className="document document-loading">
            <Spinner animation="border" role="status" />
          </div>
        </div>
      );
    }
    if (file?.url) {
      return (
        <div className="outlet">
          <Display url={file?.url} mime={file?.mime} />
        </div>
      );
    }
    if (activeFile === NO_PROOF_ID) {
      return (
        <div className="nodocument d-flex justify-content-center align-items-start">
          <UploadFile onErrorMessage={handleErrorFile} onSubmit={submitProof} />
        </div>
      );
    }
    return (
      <div className="nodocument">
        {t('DocumentDisplay.nodoc', 'Aucun document')}
      </div>
    );
  }, [loading, file.url, activeFile]);

  return (
    <div className="display-container">
      {document && (
        <div className="tabs">
          <ul className="nav nav-doc">
            {documentsToDisplay.map((docId, index) => (
              <li
                key={docId}
                className={`${isActive(docId) ? ' active' : ''} doc-tab`}
                onClick={handleClick(docId)}
                aria-hidden="true"
              >
                {`DOC ${index + 1}`}
              </li>
            ))}
            {
              (document?.proof_authenticity_id || document?.need_an_authenticity_check) && (
                <li
                  className={`${isActive(document?.proof_authenticity_id || NO_PROOF_ID) ? ' active' : ''} doc-tab`}
                  onClick={handleClick(document?.proof_authenticity_id || NO_PROOF_ID)}
                  aria-hidden="true"
                >
                  {t('DocumentDisplay.authenticityProof', 'Preuve')}
                </li>
              )
            }
          </ul>
          <DocumentScreen />
        </div>
      )}
    </div>
  );
};

export default DocumentDisplay;
