import React from 'react';
import PropTypes from 'prop-types';

const HealthContainer = ({ service }) => {
  const style = {
    backgroundColor: service.health ? '#00d066' : '#fb3a2f',
    width: '314px',
    margin: '0 0.5em 2em 0.5em',
  };

  const environment = {
    dev: 'dev',
    staging: 'staging',
    prod: 'production',
    demo: 'demo',
  };

  return (
    <div
      className="health-bloc d-flex flex-column align-items-center text-center"
      style={style}
    >
      <h5 className="mb-3">
        {service.domain}
        -api
      </h5>
      <p className="mb-2">
        Environnement de&nbsp;
        {environment[service.env]}
        {service.isPublic ? ' (Public)' : ' (Private)'}
      </p>
      <p>
        Status :
        {service.health ? ' Online' : ' Offline'}
      </p>
      {
        service.health
        && (
          <p>
            Version :&nbsp;
            {service.version}
          </p>
        )
      }
    </div>
  );
};

HealthContainer.propTypes = {
  service: PropTypes.shape({
    health: PropTypes.bool,
    version: PropTypes.string,
    domain: PropTypes.string,
    env: PropTypes.string,
    isPublic: PropTypes.bool,
  }).isRequired,
};

export default HealthContainer;
