/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Modal, Spinner, Table,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import ReactJson from 'react-json-view';
import { isNil } from 'lodash';
import dayjs from 'dayjs';
import useLoadingPromise from '../../hooks/useLoadingPromise';
import { search } from '../../services/document.service';
import { compareDatesWithoutTime, formatDate, toKey } from '../../helpers/utils';
import { V1_PLATFORM } from '../../helpers/constants';
import Tooltip from '../Tooltip/Tooltip';
import AWSelect from '../AWSelect/AWSelect';

const DOCUMENTS_PAGE_SIZE = 25;

const DocumentsList = () => {
  const blankContentModalMetadata = {
    title: '',
    data: null,
    show: false,
  };

  const { t } = useTranslation();
  const [documents, setDocuments] = useState([]);
  const [modalMetadata, setModalMetadata] = useState(blankContentModalMetadata);

  const { waitWithLoad, isLoading } = useLoadingPromise();
  const [page, setPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [typeId, setTypeId] = useState({ label: '-', value: '' });
  const [documentTypes, setDocumentTypes] = useState([]);

  const getDocuments = async () => {
    const res = await waitWithLoad(search(
      'documents',
      {
        columns: [
          'enterprise.name',
          'enterprise.id',
          'doctype.name',
          {
            fields: [
              'files.id',
              'files.name',
            ],
            aggregation: 'selectSubObject',
          },
          'to_accept.firstname',
          'to_reject.firstname',
          'created_at',
          'updated_at',
          'ai_updated_at',
          'valid_from',
          'ai_valid_from',
          'valid_until',
          'status',
          'ai_status',
          'reason_for_rejection',
          'ai_comment',
          'metadata',
          'ai_metadata',
          'auto_processing',
          'id',
        ],
        search: {
          ai_status: { $nnull: null },
          ...(typeId?.value?.length) ? { type_id: typeId.value } : {},
        },
      },
      {
        limit: DOCUMENTS_PAGE_SIZE,
        offset: page * DOCUMENTS_PAGE_SIZE - DOCUMENTS_PAGE_SIZE,
        orderBy: { ai_updated_at: 'DESC' },
      },
    ));
    if (res.success) {
      if (res.data.length < DOCUMENTS_PAGE_SIZE) {
        setHasMorePages(false);
      }
    }
    setDocuments((docs) => (res.success ? [...docs, ...res.data] : []));
    setPage((p) => (res.success ? p + 1 : p));
  };

  const getDocumentTypes = async () => {
    const now = new Date();
    const createdAt = dayjs(now).subtract(1, 'month').format('YYYY-MM-DD');
    const res = await waitWithLoad(search(
      'documents',
      {
        columns: [
          'type_id',
          'doctype.id',
          'doctype.name',
        ],
        search: { created_at: { $gt: createdAt } },
      },
      {
        isAiComplianceChecker: true,
      },
    ));
    setDocumentTypes((res?.data || [])
    // eslint-disable-next-line camelcase
      .map(({ type_id, doc_type: { name } }) => ({
        label: name, value: type_id,
      })));
  };

  const displayLink = (label, enterpriseId, documentId) => {
    if (label?.length && enterpriseId) {
      return (
        <a
          href={`${V1_PLATFORM}/addworking/enterprise/${enterpriseId}/document/${documentId}`}
          target="_blank"
          rel="noreferrer"
          alt={label}
          className="text-break"
        >
          {label}
        </a>
      );
    }
    return null;
  };

  const displayMetadata = (item, key) => {
    if (item?.auto_processing) {
      return (
        <div
          aria-hidden
          onClick={() => setModalMetadata({
            show: true,
            data: item[key] ?? {},
            title: t(`AiComplianceChecker.${key}`),
          })}
        >
          <FontAwesomeIcon
            icon={faEye}
            className="pointer me-2"
          />
        </div>
      );
    }
    return null;
  };

  const getTextColor = (item, key) => {
    if (['status', 'ai_status'].includes(key)) {
      return item.status === item.ai_status ? 'green' : 'red';
    }

    if (['valid_from', 'ai_valid_from'].includes(key)) {
      return compareDatesWithoutTime(item.valid_from, item.ai_valid_from) ? 'green' : 'red';
    }

    if (['valid_until', 'ai_valid_until'].includes(key)) {
      return compareDatesWithoutTime(item.valid_until, item.ai_valid_until)
        || compareDatesWithoutTime(item.valid_until, dayjs(item.ai_valid_until).add(1, 'day')) ? 'green' : 'red';
    }
    return 'black';
  };

  const displayDate = (iso, format = 'DD/MM/YYYY') => ((iso) ? formatDate(iso, format) : '-');

  const displayString = (value) => (!isNil(value) ? value : '-');

  const getBackgroundColor = (key) => {
    if (['valid_from', 'ai_valid_from'].includes(key)) {
      return 'antiquewhite';
    }
    if (['valid_until', 'ai_valid_until'].includes(key)) {
      return 'aliceblue';
    }

    if (['status', 'ai_status'].includes(key)) {
      return 'beige';
    }

    return 'white';
  };

  useEffect(() => {
    getDocumentTypes();
  }, []);
  useEffect(() => {
    getDocuments();
  }, [typeId]);

  const columns = [
    { title: t('AiComplianceChecker.enterpriseName'), key: 'enterpriseName' },
    { title: t('AiComplianceChecker.doctypeName'), key: 'doctypeName' },
    { title: t('AiComplianceChecker.id'), key: 'id' },
    { title: t('AiComplianceChecker.validity_period'), key: 'validity_period' },
    { title: t('AiComplianceChecker.created_at'), key: 'created' },
    { title: t('AiComplianceChecker.valid_from'), key: 'valid_from' },
    { title: t('AiComplianceChecker.ai_valid_from'), key: 'ai_valid_from' },
    { title: t('AiComplianceChecker.valid_until'), key: 'valid_until' },
    { title: t('AiComplianceChecker.ai_valid_until'), key: 'ai_valid_until' },
    { title: t('AiComplianceChecker.status'), key: 'status' },
    { title: t('AiComplianceChecker.ai_status'), key: 'ai_status' },
    { title: t('AiComplianceChecker.ai_metadata'), key: 'ai_metadata' },
    { title: t('AiComplianceChecker.ai_updated_at'), key: 'ai_updated_at' },
  ];

  return (
    <>
      <Form.Group className="mb-2 w-25">
        <Form.Label className="label">
          {t('AiComplianceChecker.doctypeName')}
        </Form.Label>
        <AWSelect
          options={documentTypes}
          value={typeId}
          isSearchable
          onChange={setTypeId}
        />
      </Form.Group>
      <div>
        <Table responsive className="table-list">
          <thead>
            <tr>
              {columns.map(({ title, key }) => (
                <th
                  key={key}
                  style={{ backgroundColor: getBackgroundColor(key) }}
                >
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {documents.map((doc) => (
              <tr key={doc?.id}>
                <td>
                  {displayLink(doc?.enterprise?.name, doc?.enterprise?.id, doc?.id)}
                </td>
                <td>
                  {displayLink(doc?.doc_type?.name, doc?.enterprise?.id, doc?.id)}
                </td>
                <td>
                  {displayString(doc.id)}
                </td>
                <td>
                  {displayString(doc?.doc_type?.validity_period)}
                </td>
                <td>
                  {displayDate(doc?.created_at)}
                </td>
                <td style={{ backgroundColor: getBackgroundColor('valid_from'), color: getTextColor(doc, 'valid_from') }}>
                  {displayDate(doc?.valid_from)}
                </td>
                <td style={{ backgroundColor: getBackgroundColor('valid_from'), color: getTextColor(doc, 'valid_from') }}>
                  {displayDate(doc?.ai_valid_from)}
                </td>
                <td style={{ backgroundColor: getBackgroundColor('valid_until'), color: getTextColor(doc, 'valid_until') }}>
                  {displayDate(doc?.valid_until)}
                </td>
                <td style={{ backgroundColor: getBackgroundColor('ai_valid_until'), color: getTextColor(doc, 'ai_valid_until') }}>
                  {displayDate(doc?.ai_valid_until)}
                </td>
                <td
                  data-for={`status-${doc.id}`}
                  data-tip="react-tooltip"
                  style={{ backgroundColor: getBackgroundColor('status'), color: getTextColor(doc, 'status') }}
                >
                  {displayString(doc?.status)}
                  {(doc?.reason_for_rejection?.length > 1 && doc?.status) && (
                    <Tooltip
                      place="left"
                      id={`status-${doc.id}`}
                    >
                      <div className="p-1">{doc?.reason_for_rejection}</div>
                    </Tooltip>
                  )}
                </td>

                <td
                  data-for={`ai-status-${doc.id}`}
                  data-tip="react-tooltip"
                  style={{ backgroundColor: getBackgroundColor('ai_status'), color: getTextColor(doc, 'ai_status') }}
                >
                  {displayString(doc?.ai_status)}
                  {(doc?.ai_comment?.length > 1 && doc?.ai_status) && (
                    <Tooltip
                      place="right"
                      id={`ai-status-${doc.id}`}
                    >
                      <div className="p-1">{doc?.ai_comment}</div>
                    </Tooltip>
                  )}
                </td>
                <td>
                  {displayMetadata(doc, 'ai_metadata')}
                </td>
                <td>
                  {displayDate(doc?.ai_updated_at, 'DD/MM/YYYY HH:mm')}
                </td>
              </tr>
            ))}
          </tbody>
          {
            !!(hasMorePages && documents?.length) && (
              <div className="list-pagination" onClick={getDocuments}>
                <button className="list-pagination__button" type="button">
                  {
                    isLoading
                      ? <Spinner animation="border" size="sm" />
                      : t('Pagination.more')
                  }
                </button>
              </div>
            )
          }
        </Table>
      </div>
      <Modal
        show={modalMetadata.show}
        centered
        onHide={() => setModalMetadata(blankContentModalMetadata)}
        size="xl"

      >
        <Modal.Header closeButton>
          <p>{modalMetadata.title}</p>
        </Modal.Header>
        <Modal.Body>
          <ReactJson src={modalMetadata.data} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DocumentsList;
