/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useRef, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { toKey, formatDate } from '../../helpers/utils';
import Tooltip from '../Tooltip/Tooltip';

const List = ({
  columns,
  data,
  onClick,
  onNextPage,
  hasMorePages,
  pageLoading,
  dataLoading,
  paddingBottom,
}) => {
  const { t } = useTranslation();
  const listRef = useRef(null);
  const [height, setHeight] = useState();
  const [scrollPosition, setScrollPosition] = useState(0);

  const getValue = (item, key) => key.split('.').reduce((acc, currentKey) => {
    if (acc && acc?.[currentKey]) {
      return acc[currentKey];
    }
    return undefined;
  }, item);

  const handleClick = (entity) => () => {
    onClick(entity);
  };

  const displayField = (item, column) => {
    if (item && typeof item === 'object') {
      if (column.render) return column.render(item);
      const value = getValue(item, column.key);
      const id = toKey(column);
      if (column.isArray) {
        return (
          <div>
            <div data-for={id} data-tip="react-tooltip">
              {value.length === 1 ? value[0] : item[column.key].length}
            </div>
            {
              (value.length > 1) && (
                <Tooltip
                  place="left"
                  id={id}
                >
                  {
                    value.map((el) => (
                      <div>{el}</div>
                    ))
                  }
                </Tooltip>
              )
            }
          </div>
        );
      }

      if (column.isBoolean) {
        return value ? t('Common.yes', 'Oui') : t('Common.no', 'Non');
      }
      if (column.isDate) {
        return value ? formatDate(value) : '-';
      }
      return value;
    }
    return '';
  };

  const calculWidth = (header) => {
    if (header.width) return `${header.width}%`;
    const noWidth = columns
      .filter((h) => !h.width)
      .length;
    const sumWidth = columns
      .map((h) => (h.width || 0))
      .reduce((a, b) => (a + b));
    return `${(100 - sumWidth) / noWidth}%`;
  };

  const resizeList = () => {
    if (listRef.current) {
      setHeight(window.innerHeight - listRef.current.getBoundingClientRect().top - paddingBottom);
    }
  };

  const listenScroll = () => {
    if (listRef.current) {
      setScrollPosition(listRef.current.scrollTop);
    }
  };

  useEffect(() => {
    resizeList();
    window.onresize = resizeList;
    if (listRef.current) {
      listRef.current.onscroll = listenScroll;
    }
    return () => {
      window.onresize = null;
      if (listRef.current) {
        listRef.current.onscroll = null;
      }
    };
  }, []);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo(0, scrollPosition);
    }
  }, [data]);

  const getClassNameColumn = (column) => {
    let className = 'list-items__item';
    if (column.isBold) className += '--bold';
    if (column.isTruncate) className += ' text-truncate';

    return className;
  };

  return (
    <div ref={listRef} className="list" style={{ height }}>
      <div className="list-headers">
        {
          columns.map((column) => (
            <div
              key={toKey(column.label)}
              className="list-headers__item"
              style={{ width: calculWidth(column) }}
            >
              {column.label}
            </div>
          ))
        }
      </div>
      {
        dataLoading ? (
          <div className="h-100 d-flex align-items-center justify-content-center">
            <Spinner animation="border" role="status" />
          </div>
        ) : (
          <>
            {
              data.map((item) => (
                <div
                  key={toKey(item)}
                  className="list-items"
                  onClick={handleClick(item)}
                >
                  {
                      columns.map((column) => {
                        const key = toKey(item);
                        return (
                          <div
                            key={key}
                            className={getClassNameColumn(column)}
                            style={{ width: calculWidth(column) }}
                            data-for={key}
                            data-tip="react-tooltip"
                          >
                            {displayField(item, column)}
                            {(column.isTruncate && getValue(item, column.key)?.length) && (
                              <Tooltip id={key} place="left">
                                <div className="p-1">{getValue(item, column.key)}</div>
                              </Tooltip>
                            )}

                          </div>
                        );
                      })
                    }
                </div>
              ))
            }
            {
              !!(hasMorePages && data.length) && (
                <div className="list-pagination" onClick={onNextPage}>
                  <button className="list-pagination__button" type="button">
                    {
                      pageLoading
                        ? <Spinner animation="border" size="sm" />
                        : t('Pagination.more', 'Afficher plus')
                    }
                  </button>
                </div>
              )
            }
          </>
        )
      }
    </div>
  );
};

List.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      width: PropTypes.number,
      isArray: PropTypes.bool,
      isBoolean: PropTypes.bool,
      isDate: PropTypes.bool,
      isBold: PropTypes.bool,
      render: PropTypes.func,
    }),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasMorePages: PropTypes.bool,
  onClick: PropTypes.func,
  onNextPage: PropTypes.func,
  pageLoading: PropTypes.bool,
  dataLoading: PropTypes.bool,
  paddingBottom: PropTypes.number,
};

List.defaultProps = {
  onNextPage: null,
  hasMorePages: false,
  pageLoading: false,
  dataLoading: false,
  onClick: () => { },
  paddingBottom: 40,
};

export default List;
