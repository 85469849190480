import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import Header from '../Header/Header';
import List from '../List/List';
import SearchInput from '../SearchBox/SearchInput';
import { getEnterpriseLegalForms, getEnterprises } from '../../services/enterprise.service';
import { AlertContext } from '../../contexts/alert.context';
import { V1_PLATFORM } from '../../helpers/constants';
import link from '../../assets/link.svg';
import useLoadingPromise from '../../hooks/useLoadingPromise';

const CUSTOMERS_PAGE_SIZE = 25;

const CUSTOMERS_FIELDS = [
  'name',
  'identification_number',
  'legal_form_id',
  'parent_id',
  'id',
];

const FilteredCustomers = ({
  columns: _columns,
  data,
  matchingIds,
  sortByKey,
  onCustomerClick,
  handleSearchResultClick,
}) => {
  const { t } = useTranslation();
  const { setNotif } = useContext(AlertContext);
  const { waitWithLoad, isLoading } = useLoadingPromise();

  const [proposals, setProposals] = useState([]);
  const [legalForms, setLegalForms] = useState([]);
  const [page, setPage] = useState(1);
  const [customers, setCustomers] = useState([]);
  const [hasMorePages, setHasMorePages] = useState(true);

  const uniq = useCallback((withCustomData, withoutCustomData) => {
    const identifications = withCustomData.map((customer) => customer.id);
    const filtered = withoutCustomData
      .filter((customer) => (!identifications.includes(customer.id)));
    return [...withCustomData, ...filtered];
  }, []);

  const LinkV1 = useCallback(({ id }) => (
    <a
      href={`${V1_PLATFORM}/enterprise/${id}`}
      onClick={(e) => e.stopPropagation()}
      target="_blank"
      rel="noreferrer"
      className="d-inline-flex justify-content-center align-items-center"
    >
      <img
        src={link}
        className="link"
        alt="link"
        aria-hidden="true"
      />
    </a>
  ), []);

  const columns = [
    {
      label: t('FilteredCustomers.legalForm', 'Forme légale'),
      key: 'legal_form',
    },
    {
      label: t('FilteredCustomers.companyName', "Nom de l'entreprise"),
      key: 'name',
    },
    {
      label: t('FilteredCustomers.siret', 'SIRET'),
      key: 'identification_number',
    },
    {
      label: t('FilteredCustomers.parent', 'Parent'),
      key: 'parent',
    },
    ..._columns,
    {
      label: t('FilteredCustomers.v1Link', 'Lien v1'),
      key: 'link',
    },
  ];

  const getEnterprisesWithout = () => (
    getEnterprises(
      {
        fields: CUSTOMERS_FIELDS,
        search: {
          is_customer: true,
          identificationNumber: { $null: false },
        },
        page_size: CUSTOMERS_PAGE_SIZE,
        page,
      },
    )
  );

  const buildAdditionalData = (customerId, _data) => (
    Object.keys(_data).reduce((acc, current) => {
      acc[current] = _data[current].filter((e) => e.enterpriseId === customerId).length;
      return acc;
    }, {})
  );

  const buildCustomers = (newCustomers, _data = data) => (
    newCustomers.map((customer) => {
      const additionalData = buildAdditionalData(customer.id, _data);
      return {
        ...customer,
        ...additionalData,
        legal_form: legalForms.find((lg) => (
          customer.legal_form_id && lg.id === customer.legal_form_id
        ))?.display_name || '',
        link: <LinkV1 id={customer.id} />,
      };
    })
  );

  const getCustomerParent = async (customer) => {
    if (customer.parent_id) {
      const parentRes = await getEnterprises({
        fields: 'name',
        search: {
          id: customer.parent_id,
        },
      });
      if (parentRes.success) {
        return {
          customer: customer.id,
          name: parentRes.data[0].name,
        };
      }
    }
    return { id: customer.id };
  };

  const combineParents = async (newCustomers) => {
    const parents = await Promise.all(newCustomers.map((c) => getCustomerParent(c)));
    return [
      ...customers,
      ...newCustomers.map((c) => ({
        ...c,
        parent: parents.find((p) => p.customer === c.id)?.name,
      })),
    ];
  };

  const initCustomers = async () => {
    const [customerResWith, customerResWithout] = await Promise.all([
      matchingIds?.length ? getEnterprises(
        {
          fields: CUSTOMERS_FIELDS,
          search: {
            is_customer: true,
            id: {
              $or: matchingIds,
            },
          },
        },
      ) : null,
      getEnterprisesWithout(),
    ]);
    if (customerResWithout.success) {
      if (customerResWithout.data.length < CUSTOMERS_PAGE_SIZE) {
        setHasMorePages(false);
      }
      const list = uniq(customerResWith?.hasError()
        ? [] : customerResWith?.data || [], customerResWithout.data);
      const newCustomers = buildCustomers(list);
      let tmpCustomers = sortByKey
        ? [...customers, ...newCustomers].sort((a, b) => (a[sortByKey] < b[sortByKey] ? 1 : -1))
        : [...customers, ...newCustomers];
      if (tmpCustomers.length) {
        tmpCustomers = await combineParents(tmpCustomers);
      }
      setCustomers(tmpCustomers);
      setPage((p) => p + 1);
    }
  };

  const initLegalForms = async () => {
    const legalFormRes = await getEnterpriseLegalForms(
      {
        fields: 'id,name,display_name',
      },
    );
    legalFormRes.displayNotif(setNotif);
    if (legalFormRes.success) {
      setLegalForms(legalFormRes.data);
    }
  };

  const nextCustomers = async () => {
    const response = await waitWithLoad(getEnterprisesWithout());
    response.displayNotif(setNotif);
    if (response.success) {
      if (response.data.length < CUSTOMERS_PAGE_SIZE) {
        setHasMorePages(false);
      }
      const newCustomers = buildCustomers(response.data);
      const customersWithParents = await waitWithLoad(combineParents(newCustomers));
      setCustomers(customersWithParents);
      setPage(page + 1);
    }
  };

  useEffect(() => {
    waitWithLoad(initLegalForms());
  }, []);

  useEffect(() => {
    if (legalForms.length) {
      waitWithLoad(initCustomers());
    }
  }, [legalForms]);

  const search = async (value) => {
    const response = await getEnterprises(
      {
        fields: CUSTOMERS_FIELDS,
        search: {
          is_customer: true,
          name: { $sw: value },
        },
      },
    );
    if (response.success) {
      setProposals(response.data);
    } else {
      setProposals([]);
    }
  };

  const handleReset = () => {
    setProposals([]);
  };

  const getCustomer = async (customer) => {
    const response = await waitWithLoad(handleSearchResultClick(customer));
    const result = buildCustomers([customer], response);
    setCustomers([{ ...result[0] }]);
    setHasMorePages(false);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
        <Col xs={3}>
          <div className="filtre-container padding-col">
            <h2>{t('Filter.title', 'Filtres')}</h2>
            <SearchInput
              label={t('Filter.clientName', 'Raison sociale client')}
              placeholder={t(
                'Filter.placeholderClient',
                'Recherche une raison sociale client',
              )}
              search={search}
              data={proposals}
              onClick={getCustomer}
              onReset={handleReset}
            />
          </div>
        </Col>
        <Col xs={9}>
          <h1>
            {t('FilteredCustomers.title', 'Liste des clients')}
          </h1>
          <List
            columns={columns}
            data={customers}
            hasMorePages={hasMorePages}
            onNextPage={nextCustomers}
            onClick={onCustomerClick}
            dataLoading={isLoading}
          />
        </Col>
      </div>
      <div className="version">
        {`V: ${process.env.REACT_APP_VERSION}`}
      </div>
    </div>
  );
};

FilteredCustomers.propTypes = {
  onCustomerClick: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    key: PropTypes.string,
  })),
  matchingIds: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.shape(),
  sortByKey: PropTypes.string,
  handleSearchResultClick: PropTypes.func,
};

FilteredCustomers.defaultProps = {
  columns: [],
  data: {},
  matchingIds: [],
  sortByKey: '',
  handleSearchResultClick: () => { },
  onCustomerClick: () => { },
};

export default FilteredCustomers;
