import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { taskContents } from '../../helpers/constants';
import { ConnectionConfigContext } from '../../contexts/connectionConfig.context';

const StepTasks = ({
  step,
  deleteStepEnabled,
  handleDeleteStep,
  handleDeleteTask,
  handleStep,
  handleAddTask,
  handleTask,
}) => {
  const { t } = useTranslation();
  const { isConnectionConfigSketch } = useContext(ConnectionConfigContext);

  return (
    <div>
      <div className="page-block d-flex justify-content-between">
        <div
          aria-hidden
          className="pointer"
          onClick={() => handleStep(step)}
        >
          {t('ConnectionConfig.step', 'Etape {{step}}', { step: step.step })}
        </div>
        {deleteStepEnabled ? (
          <div
            aria-hidden
            onClick={() => handleDeleteStep(step)}
          >
            <FontAwesomeIcon
              icon={faTrash}
              className="pointer me-2"
            />
          </div>
        ) : <> </>}
      </div>
      {step.tasks?.length ? step.tasks.map((task) => (
        <div
          key={task.id}
          className="question-block d-flex justify-content-between align-items-center"
        >
          <div
            aria-hidden
            className="pointer"
            onClick={() => handleTask(task, step.step)}
          >
            {`
            ${t('ConnectionConfig.task', 'Tâche')}
            ${taskContents.find((t) => t.value === task.content)?.label?.toLowerCase() || ''}
          `}
          </div>
          {step.tasks.length > 1 && isConnectionConfigSketch ? (
            <div
              aria-hidden
              onClick={() => handleDeleteTask(task)}
            >
              <FontAwesomeIcon
                icon={faTrash}
                className="pointer me-2"
              />
            </div>
          ) : <> </>}
        </div>
      )) : <> </>}
      {
        isConnectionConfigSketch ? (
          <div className="text-center">
            <button
              onClick={() => handleAddTask(step.step)}
              type="button"
              className="question-list-button question-list-button__add-question"
            >
              {`+ ${t('ConnectionConfig.addOneTask', 'Ajouter une tâche')}`}
            </button>
          </div>
        ) : <> </>
      }
    </div>
  );
};

StepTasks.propTypes = {
  step: PropTypes.shape({
    step: PropTypes.number,
    tasks: PropTypes.arrayOf(PropTypes.shape),
  }).isRequired,
  deleteStepEnabled: PropTypes.bool.isRequired,
  handleDeleteStep: PropTypes.func.isRequired,
  handleStep: PropTypes.func.isRequired,
  handleAddTask: PropTypes.func.isRequired,
  handleDeleteTask: PropTypes.func.isRequired,
  handleTask: PropTypes.func.isRequired,
};

export default StepTasks;
