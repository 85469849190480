import { safeFetch } from '../helpers/response-handler';

export const ENVS = {
  dev: {
    private: (domain) => `https://api-dev.addworking.io/v1/${domain}/health`,
    public: (domain) => `https://api-dev.public.addworking.io/v1/${domain}/health`,
  },
  staging: {
    private: (domain) => `https://api-staging.addworking.io/v1/${domain}/health`,
    public: (domain) => `https://api-staging.public.addworking.io/v1/${domain}/health`,
  },
  prod: {
    private: (domain) => `https://api.addworking.io/v1/${domain}/health`,
    public: (domain) => `https://api.public.addworking.io/v1/${domain}/health`,
  },
  demo: {
    private: (domain) => `https://api-demo.addworking.io/v1/${domain}/health`,
    public: (domain) => `https://api-demo.public.addworking.io/v1/${domain}/health`,
  },
};

export const getHealth = async (domain, env, mode) => {
  const res = await safeFetch(ENVS[env][mode](domain));
  return {
    res,
    env,
    mode,
    domain,
  };
};
