import React, { useEffect, useState, useContext } from 'react';
import '../../styles/css/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight, faExclamationCircle, faExclamationTriangle, faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import { useRouteMatch, useLocation } from 'react-router-dom';
import PromiseB from 'bluebird';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import {
  getEnterpriseDifferences,
  acceptEnterpriseDifferences,
  getEnterpriseDifferencesAccept,
  refreshEnterprises,
} from '../../services/enterprise.service';
import { getSignedURL } from '../../services/file.service';
import { AlertContext } from '../../contexts/alert.context';

const { REACT_APP_VERSION } = process.env;

const EnterpriseDashboard = () => {
  const { t } = useTranslation();
  const fieldTranslation = {
    activity_id: t('Enterprise.activity_id'),
    identification_number: t('Enterprise.identification_number'),
    name: t('Enterprise.name'),
    website: t('Enterprise.website'),
    main_activity_code: t('Enterprise.main_activity_code'),
    is_headquarter: t('Enterprise.is_headquarter'),
    registration_town: t('Enterprise.registration_town'),
    registration_date: t('Enterprise.registration_date'),
    share_capital: t('Enterprise.share_capital'),
    creation_date: t('Enterprise.creation_date'),
    logo_id: t('Enterprise.logo'),
    legal_form: t('Enterprise.legal_form'),
    legal_form_id: t('Enterprise.legal_form_id'),
    tax_identification_number: t('Enterprise.tax_identification_number'),
    latitude: t('Enterprise.latitude'),
    longitude: t('Enterprise.longitude'),
    cessation_date: t('Enterprise.cessation_date'),
    has_website: t('Enterprise.has_website'),
    additionnal_address: t('Enterprise.additionnal_address'),
    country: t('Enterprise.country'),
    address: t('Enterprise.address'),
    zipcode: t('Enterprise.zipcode'),
    town: t('Enterprise.town'),
    year: t('Enterprise.year'),
    amount: t('Enterprise.amount'),
    activity: t('Enterprise.activity'),
    employees_count: t('Enterprise.employees_count'),
  };
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { params } = useRouteMatch();
  const [error, setError] = useState();
  const [differences, setDifferences] = useState();
  const [accepts, setAccepts] = useState();
  const [refreshInProgress, setRefreshInProgress] = useState(false);
  const [enterprise, setEnterprise] = useState();
  const [enterpriseId] = useState(params.enterpriseId);
  const [displayOnlyNonReviewFields, setDisplayOnlyNonReviewFields] = useState(searchParams.get('displayOnlyNonReviewFields') === 'true');
  const { setNotif } = useContext(AlertContext);
  const [tab, setTab] = useState(displayOnlyNonReviewFields ? 'all' : 'registration');

  function displayError(err, messageToDisplay) {
    setError(messageToDisplay);
    throw err;
  }
  async function reload() {
    setError(null);
    try {
      const { data: differences } = await getEnterpriseDifferences(enterpriseId)
        .catch((err) => displayError(err, t('Enterprise.Upgrades.error_get_differences')));
      const { data: accepts } = await getEnterpriseDifferencesAccept(enterpriseId)
        .catch((err) => displayError(err, t('Enterprise.Upgrades.error_get_differences')));
      await PromiseB.map(differences.diffs, async (diff) => {
        if (diff.field === 'logo_id') {
          const { values } = diff.diff;
          if (values.addworking) {
            const { data: url } = await getSignedURL(values.addworking?.toLowerCase());
            values.addworking = { id: values.addworking, url: url.signedUrl };
          }
          if (values.clean) {
            const { data: url } = await getSignedURL(values.clean?.toLowerCase());
            values.clean = { id: values.clean, url: url.signedUrl };
          }
        }
      });
      if (differences.enterprise.logo_id) {
        const { data: url } = await getSignedURL(differences.enterprise.logo_id?.toLowerCase());
        differences.enterprise.logo_id = { id: differences.enterprise.logo_id, url: url.signedUrl };
      }
      setEnterprise(differences?.enterprise);
      if (!differences?.enterprise) {
        setError(t('Enterprise.Upgrades.enterpriseNotExists'));
        return;
      }
      setAccepts((accepts || []).reduce((reducer, change) => {
        // eslint-disable-next-line no-param-reassign
        reducer[change.field] = change;
        return reducer;
      }, {}));
      setDifferences((differences?.diffs || []).reduce((reducer, change) => {
        // eslint-disable-next-line no-param-reassign
        reducer[change.field] = change;
        return reducer;
      }, {}));
    } catch (err) {
      setError('');
    }
  }
  useEffect(async () => {
    reload();
  }, [enterpriseId]);

  function renderClean(clean, transform, type) {
    if (type) {
      if (type === 'img') {
        return (<img width={50} height={50} src={(transform == null ? clean : transform(clean)) || 'https://placehold.co/50x50?text=?'} alt="logo_id" />);
      }
    } if (transform) {
      return (<div>{transform(clean) || 'N/C'}</div>);
    } if (typeof clean === 'string') {
      return (<div>{clean}</div>);
    } if (typeof clean === 'number') {
      return (<div>{Intl.NumberFormat('fr').format(clean)}</div>);
    } if (typeof clean === 'boolean') {
      return (<div>{clean ? 'oui' : 'non'}</div>);
    } if (Array.isArray(clean)) {
      return (<div>{JSON.stringify(clean)}</div>);
    } if (clean == null) {
      return (<div>N/C</div>);
    }
    return <div>{JSON.stringify(clean)}</div>;
  }

  async function accept(difference, source, transform, partials, additionalFlag) {
    const value = transform
      ? transform(difference.diff.values[source])
      : difference.diff.values[source];
    const result = await acceptEnterpriseDifferences(
      enterpriseId,
      difference._id._id,
      value,
      source,
      partials,
      additionalFlag,
    );
    if (result.success) {
      setNotif({
        message: t('Enterprise.Upgrades.acceptSuccess'),
        translated: true,
        variant: 'success',
      });
    } else {
      setNotif({
        message: t('Enterprise.Upgrades.acceptError'),
        translated: true,
      });
    }
    await reload();
    return result;
  }

  function renderDifferences(
    field,
    { transformRender, transformSave } = { transformRender: undefined, transformSave: undefined },
    type = undefined,
  ) {
    const values = differences[field]?.diff?.values;
    return (
      <div className="column">
        <div className="changes">
          <button
            type="button"
            className={`card ${accepts?.[field]?.source === 'addworking' ? 'accept' : ''}`}
            onClick={() => accept(differences[field], 'addworking', transformSave)}
          >
            {renderClean(values?.addworking, transformRender, type)}
            <div className="description">
              {' '}
              {t('Enterprise.Upgrades.actual')}
            </div>
          </button>
          <FontAwesomeIcon
            icon={faChevronRight}
            className="cursor-pointer"
          />
          <button
            type="button"
            className={`card ${accepts?.[field]?.source === 'clean' ? 'accept' : ''}`}
            onClick={() => accept(differences[field], 'clean', transformSave)}
          >
            {renderClean(values?.clean, transformRender, type)}
            <div className="description">{t('Enterprise.Upgrades.proposed')}</div>
          </button>
        </div>
      </div>
    );
  }
  function renderField(
    field,
    transform,
    type,
  ) {
    return (
      !displayOnlyNonReviewFields
      || (differences?.[field]?.diff?.values && !accepts?.[field]?.source)
    ) && (
      <Row className="align-center column group">
        <Col className="label">{fieldTranslation?.[field] || field}</Col>
        <Col>
          {renderClean(enterprise?.[field], transform?.transformRender, type)}
        </Col>
        {differences?.[field]?.diff?.values && (
          <Col className="column group subgroup">
            <div className="label">{t('Enterprise.Upgrades.improve')}</div>
            {renderDifferences(field, transform, type)}
          </Col>
        )}
      </Row>
    );
  }
  function renderAddress(address, version) {
    return (
      <div>
        {version && (
          <button
            type="button"
            key={`${address?.additionnal_address}-${address?.zipcode}-${address?.town}`}
            className={`card ${accepts?.addresses?.source === version ? 'accept' : ''}`}
            onClick={() => (version ? accept(differences.addresses, version) : null)}
          >
            <div>{address.additionnal_address}</div>
            <div>{address.address}</div>
            <div>{address.zipcode}</div>
            <div>{address.town}</div>
            <div>{address.country}</div>
            {version === 'addworking' && <div className="description">{t('Enterprise.Upgrades.actual')}</div>}
            {version === 'clean' && <div className="description">{t('Enterprise.Upgrades.proposed')}</div>}
            {!version && <div className="description">{t('Enterprise.Upgrades.inDB')}</div>}
          </button>
        )}
        {!version && (
          <div key={`${address?.additionnal_address}-${address?.zipcode}-${address?.town}`}>
            <div>{address.additionnal_address}</div>
            <div>{address.address}</div>
            <div>{address.zipcode}</div>
            <div>{address.town}</div>
            <div>{address.country}</div>
          </div>
        )}
      </div>
    );
  }

  function renderFinancials(address, version) {
    return (
      <div>
        {version && (
          <button
            type="button"
            key={`${address?.year}-${address?.official_amount}`}
            className={`card ${accepts?.financial?.source === version ? 'accept' : ''}`}
            onClick={() => (version ? accept(differences.financial, version) : null)}
          >
            <div style={{ fontWeight: 'bold' }}>{address.year || 'N/C'}</div>
            <div>{address.official_amount ? Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(address.official_amount) : 'N/C'}</div>
            {version === 'addworking' && <div className="description">{t('Enterprise.Upgrades.actual')}</div>}
            {version === 'clean' && <div className="description">{t('Enterprise.Upgrades.proposed')}</div>}
            {!version && <div className="description">{t('Enterprise.Upgrades.inDB')}</div>}
          </button>
        )}
        {!version && (
          <div key={`${address?.year}-${address?.official_amount}`}>
            <div style={{ fontWeight: 'bold' }}>{address.year || 'N/C'}</div>
            <div>{address.official_amount ? Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(address.official_amount) : 'N/C'}</div>
          </div>
        )}
      </div>
    );
  }
  function renderActivities(activity, version) {
    const isAccepted = accepts?.activities?.partials
      ? accepts?.activities?.partials.includes(activity)
      : accepts?.activities?.source === version;
    return version
      ? (
        <button
          type="button"
          key={activity}
          className={`card ${isAccepted ? 'accept' : ''}`}
          onClick={
            () => (version ? accept(differences.activities, version, null, [activity]) : null)
          }
        >
          <div>{activity}</div>
          {version === 'addworking' && <div className="description">{t('Enterprise.Upgrades.actual')}</div>}
          {version === 'clean' && <div className="description">{t('Enterprise.Upgrades.proposed')}</div>}
        </button>
      )
      : (
        <div key={activity}>
          <div>{activity}</div>
          <div className="description">{t('Enterprise.Upgrades.inDB')}</div>
        </div>
      );
  }
  function renderArray(label, field, renderFunction = () => {}) {
    return (
      !displayOnlyNonReviewFields
      || (differences?.[field]?.diff?.values && !accepts?.[field]?.source)
    ) && (
      <Row className="align-center column group">
        <Col xs={3} className="label">{label}</Col>
        <Col xs={12} className="column">
          {(enterprise?.[field] || [])?.map((address) => (
            renderFunction(address, null)
          ))}
        </Col>
        {differences?.[field]?.diff?.values && (
        <Col xs={12} className="column group subgroup">
          <div className="label">{t('Enterprise.Upgrades.improve')}</div>
          {(differences?.[field]?.diff?.values?.clean || [])?.map((address) => (
            renderFunction(address, 'clean')
          ))}
          {(differences?.[field]?.diff?.values?.addworking || [])?.map((address) => (
            renderFunction(address, 'addworking')
          ))}
        </Col>
        )}
      </Row>
    );
  }
  async function actionOnClosedEnterprise(action) {
    await accept(differences?.CLOSED_ESTABLISHMENT, 'clean', undefined, undefined, action);
  }
  async function launchRefreshEnterprise() {
    setRefreshInProgress(true);
    const result = await refreshEnterprises([enterprise.id]);
    if (result.success) {
      setNotif({
        message: t('Enterprise.Upgrades.refreshSuccess'),
        translated: true,
        variant: 'success',
      });
    } else {
      setNotif({
        message: t('Enterprise.Upgrades.refreshError'),
        translated: true,
      });
    }
    setRefreshInProgress(false);
    await reload();
  }
  return !error ? (
    <div className="container-fluid enterprise-refresh-root">
      <div className="row">
        <Header />
        {
        differences?.CLOSED_ESTABLISHMENT && (
        <div className="closed-enterprise-banner">
          <FontAwesomeIcon icon={faExclamationTriangle} className="arrow" />
          {
            differences?.CLOSED_ESTABLISHMENT?.diff.reason === 'CLOSED_ESTABLISHMENT_FOUND_SUCCESSOR' && (
              <span>
                {t('Enterprise.Upgrades.CLOSED_ESTABLISHMENT_FOUND_SUCCESSOR')}
                <div>
                  (
                  {differences?.CLOSED_ESTABLISHMENT.diff.values.addworking.identification_number}
                  {' => '}
                  {differences?.CLOSED_ESTABLISHMENT.diff.values.clean.identification_number}
                  )
                </div>
                <div>
                  <button type="button" onClick={() => actionOnClosedEnterprise('ACTION_ESTABLISHMENT_CHANGE_IDENTIFICATION_NUMBER')}>
                    {t('Enterprise.Upgrades.ACTION_ESTABLISHMENT_CHANGE_IDENTIFICATION_NUMBER')}
                  </button>
                  {/*
                  Too hard to handle for moment, maybe later
                  <button onClick={() => (
                    actionOnClosedEnterprise('ACTION_ESTABLISHMENT_CREATE_ENTERPRISE')
                  )}>
                    {t('Enterprise.Upgrades.ACTION_ESTABLISHMENT_CREATE_ENTERPRISE')}
                  </button>
                  */}
                </div>
              </span>
            )
          }
          {
            differences?.CLOSED_ESTABLISHMENT?.diff.reason === 'CLOSED_ESTABLISHMENT_NO_SUCCESSOR' && (
              <span>
                {t('Enterprise.Upgrades.CLOSED_ESTABLISHMENT_NO_SUCCESSOR')}
              </span>
            )
          }
          {
            differences?.CLOSED_ESTABLISHMENT?.diff.reason === 'CLOSED_ESTABLISHMENT_FOUND_SUCCESSOR_IN_DB' && (
              <span>
                {t('Enterprise.Upgrades.CLOSED_ESTABLISHMENT_FOUND_SUCCESSOR_IN_DB')}
                <div>
                  (
                  {differences?.CLOSED_ESTABLISHMENT.diff.values.addworking.identification_number}
                  {' => '}
                  {differences?.CLOSED_ESTABLISHMENT.diff.values.clean.identification_number}
                  )
                </div>
                <div>
                  <button type="button" onClick={() => actionOnClosedEnterprise('ACTION_CLOSE_NEW_ENTERPRISE')}>
                    {t('Enterprise.Upgrades.ACTION_CLOSE_NEW_ENTERPRISE')}
                  </button>
                </div>
              </span>
            )
          }
          <FontAwesomeIcon icon={faExclamationTriangle} className="arrow" />
        </div>
        )
      }
        <div className="jumbo">
          <Col className="text" xs={10}>
            {enterprise?.logo_id?.url && (
              <img src={enterprise?.logo_id?.url ? enterprise?.logo_id?.url : ''} alt="" />
            )}
            <h1>
              {enterprise?.name}
              {' '}
              <div className="identificationNumber">
                (
                {enterprise?.identification_number}
                )
              </div>
            </h1>
          </Col>
          <Col className="text" xs={2}>
            {
            !refreshInProgress && (
            <button
              type="button"
              onClick={launchRefreshEnterprise}
            >
              <FontAwesomeIcon icon={faSyncAlt} className="arrow" />
            </button>
            )
          }
          </Col>
        </div>
        <div className="enterprise">
          <Row xs={11} className="header">
            <label htmlFor="non-review-fields-input" className="input-container">
              <input
                id="non-review-fields-input"
                type="checkbox"
                defaultChecked={displayOnlyNonReviewFields}
                onInput={(ev) => {
                  setDisplayOnlyNonReviewFields(ev.target.checked);
                  if (ev.target.checked) setTab('all');
                }}
              />
              {t('Enterprise.Upgrades.displayOnlyNonReviewFields')}
            </label>
          </Row>
          <Row className="container-tabs">
            <div className="tabs">
              <ul>
                <li onClick={() => setTab('all')} className={tab === 'all' ? 'active' : ''}>
                  {t('Enterprise.Upgrades.Tab.all')}
                  <FontAwesomeIcon icon={faChevronRight} className="arrow" />
                </li>
                <li onClick={() => setTab('registration')} className={tab === 'registration' ? 'active' : ''}>
                  {t('Enterprise.Upgrades.Tab.registration')}
                  <FontAwesomeIcon icon={faChevronRight} className="arrow" />
                </li>
                <li onClick={() => setTab('addresses')} className={tab === 'addresses' ? 'active' : ''}>
                  {t('Enterprise.Upgrades.Tab.address')}
                  <FontAwesomeIcon icon={faChevronRight} className="arrow" />
                </li>
                <li onClick={() => setTab('activities')} className={tab === 'activities' ? 'active' : ''}>
                  {t('Enterprise.Upgrades.Tab.activities')}
                  <FontAwesomeIcon icon={faChevronRight} className="arrow" />
                </li>
                <li onClick={() => setTab('financial')} className={tab === 'financial' ? 'active' : ''}>
                  {t('Enterprise.Upgrades.Tab.financials')}
                  <FontAwesomeIcon icon={faChevronRight} className="arrow" />
                </li>
                <li onClick={() => setTab('other')} className={tab === 'other' ? 'active' : ''}>
                  {t('Enterprise.Upgrades.Tab.other')}
                  <FontAwesomeIcon icon={faChevronRight} className="arrow" />
                </li>
              </ul>
            </div>
            <div className="enterprise-form-container">
              {
                (tab === 'registration' || tab === 'all') && (
                  <div>
                    <h2>{t('Enterprise.Upgrades.Tab.registration')}</h2>
                    <section>
                      {renderField('name')}
                      {renderField('logo_id', {
                        transformRender: (value) => value?.url,
                        transformSave: (value) => value?.id?.toLowerCase(),
                      }, 'img')}
                      {renderField('registration_town')}
                      {renderField('registration_date', {
                        transformRender: (value) => (value ? dayjs(value).format('DD/MM/YYYY') : value),
                        transformSave: (value) => (value ? dayjs(value).format('YYYY-MM-DD') : value),
                      })}
                      {renderField('creation_date', {
                        transformRender: (value) => (value ? dayjs(value).format('DD/MM/YYYY') : value),
                        transformSave: (value) => (value ? dayjs(value).format('YYYY-MM-DD') : value),
                      })}
                      {renderField('legal_form')}
                      {renderField('tax_identification_number')}
                      {renderField('share_capital')}
                      {renderField('cessation_date', {
                        transformRender: (value) => (value ? dayjs(value).format('DD/MM/YYYY') : value),
                        transformSave: (value) => (value ? dayjs(value).format('YYYY-MM-DD') : value),
                      })}
                    </section>
                  </div>
                )
              }
              {
                (tab === 'addresses' || tab === 'all') && (
                  <div>
                    <h2>{t('Enterprise.Upgrades.Tab.address')}</h2>
                    <section>
                      {renderArray('Addresses', 'addresses', renderAddress)}
                      {renderField('is_headquarter')}
                      {renderField('latitude')}
                      {renderField('longitude')}
                    </section>
                  </div>
                )
              }
              {
                (tab === 'financial' || tab === 'all') && (
                  <div>
                    <h2>{t('Enterprise.Upgrades.Tab.financials')}</h2>
                    <section>
                      {renderArray('Finances', 'financial', renderFinancials)}
                    </section>
                  </div>
                )
              }
              {
                (tab === 'other' || tab === 'all') && (
                  <div>
                    <h2>{t('Enterprise.Upgrades.Tab.other')}</h2>
                    <section>
                      {renderField('website')}
                      {/* {renderField('employees_count', {
                        transformRender: (value) => value?.min || value,
                        transformSave: (value) => value?.id || value,
                      })} */}
                    </section>
                  </div>
                )
              }
              {
                (tab === 'activities' || tab === 'all') && (
                  <div>
                    <h2>{t('Enterprise.Upgrades.Tab.activities')}</h2>
                    <section>
                      {renderField('main_activity_code')}
                      {renderArray('Activités', 'activities', renderActivities)}
                    </section>
                  </div>
                )
              }
            </div>
          </Row>
        </div>
      </div>
      <div className="version">
        {`V: ${REACT_APP_VERSION}`}
      </div>
    </div>
  ) : (
    <div>
      <Header />
      <div className="error">
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="cursor-pointer"
        />
        {error}
      </div>
    </div>
  );
};

export default EnterpriseDashboard;
