import React, {
  createContext, useState, useContext, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentsContext } from './documents.context';
import { AlertContext } from './alert.context';
import * as FileService from '../services/file.service';

export const FileContext = createContext();

// eslint-disable-next-line react/prop-types
const FileContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const [alertPostComment, setAlertPostComment] = useState({});
  const [comments, setComments] = useState([]);
  const { document } = useContext(DocumentsContext);
  const { setNotif } = useContext(AlertContext);
  const [file, setFile] = useState({});
  const [refusalReasons, setRefusalReasons] = useState([]);

  const getAllComments = async (documentId) => {
    if (documentId) {
      const commentsResponse = await FileService.getComments(documentId);
      setComments(
        commentsResponse.success
          ? commentsResponse.data : [],
      );
    }
  };

  const postComment = async (comment) => {
    const apiResponse = await FileService.createComment(comment);
    apiResponse.displayNotif(setNotif);
    if (apiResponse.success) {
      getAllComments(document.document_id);
      setAlertPostComment({
        type: 'valid',
        message: t('Commment.validpostcomment', 'Votre commentaire a bien été enregistré'),
      });
    } else {
      setAlertPostComment({
        type: 'error',
        message: t('Commment.errorpostcomment', "Votre commentaire n'a pas pu être créé"),
      });
    }
  };

  const deleteComment = async (commentId) => {
    const apiResponse = await FileService.deleteComment(commentId);
    apiResponse.displayNotif(setNotif);
    if (apiResponse.success) {
      getAllComments(document.document_id);
    }
  };

  const getDocumentFile = async (fileId) => {
    if (fileId) {
      const apiResponse = await FileService.getDocumentFile(fileId);
      apiResponse.displayNotif(setNotif);
      if (apiResponse.success) {
        setFile({
          mime: apiResponse.data?.mime,
          url: apiResponse.data?.signed_url,
        });
      }
    } else {
      setFile({ mime: '', url: '' });
    }
  };

  const getDocumentRefusalReasons = async (documentType) => {
    const reasonsResponse = await FileService.getRefusalReasons(documentType);
    reasonsResponse.displayNotif(setNotif);
    if (reasonsResponse.success) {
      setRefusalReasons(reasonsResponse.data);
    }
  };

  const value = useMemo(() => ({
    comments,
    setComments,
    getAllComments,
    postComment,
    deleteComment,
    getDocumentFile,
    file,
    setFile,
    getDocumentRefusalReasons,
    refusalReasons,
    alertPostComment,
    setAlertPostComment,
  }), [file, comments, refusalReasons, alertPostComment]);

  return (
    <FileContext.Provider
      value={value}
    >
      {children}
    </FileContext.Provider>
  );
};

export default FileContextProvider;
