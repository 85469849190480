import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import ActionButton from '../../components/ActionButton/ActionButton';
import AWSelect from '../../components/AWSelect/AWSelect';
import ConditionForm from './Forms/ConditionForm';
import { randString } from '../../helpers/utils';
import { ConnectionConfigContext } from '../../contexts/connectionConfig.context';

const Step = ({
  step,
}) => {
  const { t } = useTranslation();
  const {
    connectionConfig,
    members,
    setConnectionConfig,
    isConnectionConfigSketch,
  } = useContext(ConnectionConfigContext);

  const addCondition = (stepNumber) => () => {
    const clone = cloneDeep(connectionConfig);
    const _step = clone.steps.find((s) => s.step === stepNumber);
    _step.conditions.push({ id: randString() });
    setConnectionConfig(clone);
  };

  const deleteCondition = (condition) => {
    const clone = cloneDeep(connectionConfig);
    const step = clone.steps.find(
      (_step) => _step.conditions.some((c) => c.id === condition.id),
    );
    step.conditions = step.conditions.filter((c) => c.id !== condition.id);
    setConnectionConfig(clone);
  };

  const handleStepCheckers = (stepNumber) => (options) => {
    const clone = cloneDeep(connectionConfig);
    const step = clone.steps.find((step) => step.step === stepNumber);
    step.checkers = options.map((opt) => ({ user_id: opt.value }));
    setConnectionConfig(clone);
  };

  return (
    <>
      <Form.Group>
        <h3>{t('ConnectionConfig.step', 'Etape {{step}}', { step: step.step })}</h3>
        <Form.Label className="label" htmlFor="checkers">
          {t('ConnectionConfig.enterpriseCheckers', 'Validateur(s) d\'étape(s)')}
        </Form.Label>
        <AWSelect
          id="checkers"
          isMulti
          noNullOption
          options={members}
          isDisabled={!members?.length || !isConnectionConfigSketch}
          noOptionsMessage={() => t('ConnectionConfig.enterpriseAnyChecker', 'Aucun validateur')}
          onChange={handleStepCheckers(step.step)}
          value={members.filter((m) => step.checkers?.some((c) => c.user_id === m.value))}
          placeholder=""
        />
      </Form.Group>
      {step.checkers && step.checkers.length ? (
        <>
          {step.conditions ? step.conditions.map((condition, i) => (
            <div key={condition.id}>
              <div className="mt-4 mb-2 d-flex justify-content-between align-items-center">
                <h3 className="p-0">{`${t('ConnectionConfig.condition', 'Condition')} ${i + 1}`}</h3>
                {isConnectionConfigSketch ? (
                  <div
                    aria-hidden
                    onClick={() => deleteCondition(condition)}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="pointer me-2"
                    />
                  </div>
                ) : <> </>}
              </div>
              <ConditionForm condition={condition} />
            </div>
          )) : ''}
          {isConnectionConfigSketch ? (
            <div className="my-3 text-center">
              <ActionButton onClick={addCondition(step.step)}>
                {t('ConnectionConfig.addCondition', 'Ajouter une condition')}
              </ActionButton>
            </div>
          ) : <> </>}
        </>
      ) : ''}
    </>
  );
};

Step.propTypes = {
  step: PropTypes.shape({
    checkers: PropTypes.arrayOf(PropTypes.shape({
      user_id: PropTypes.string,
    })),
    conditions: PropTypes.arrayOf(PropTypes.shape),
    step: PropTypes.number,
    display: PropTypes.bool,
    tasks: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      content: PropTypes.string,
      owners: PropTypes.arrayOf(PropTypes.shape({
        user_id: PropTypes.string,
      })),
      data: PropTypes.string,
    })),
  }).isRequired,
};

export default Step;
