import React from 'react';
import PropTypes from 'prop-types';

const AWLink = ({
  onClick,
  onKeyDown,
  children,
  className,
}) => (
  <span
    className={`aw-link ${className}`}
    role="button"
    tabIndex="0"
    onClick={onClick}
    onKeyDown={onKeyDown}
  >
    {children}
  </span>
);

AWLink.propTypes = {
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AWLink.defaultProps = {
  onClick: null,
  onKeyDown: null,
  className: '',
};

export default AWLink;
