import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import greenCheck from '../../assets/stateValidated.svg';
import orangePause from '../../assets/stateWait.svg';
import greyCross from '../../assets/grey-cross.svg';
import orangePen from '../../assets/orange-pen.svg';
import redCross from '../../assets/stateRefused.svg';
import redPen from '../../assets/red-pen.svg';
import blueCheck from '../../assets/statePreChecked.svg';

import Tooltip from '../Tooltip/Tooltip';

const DocumentStatusIcon = ({ status }) => {
  const { t } = useTranslation();

  const statusList = {
    precheck: {
      svg: blueCheck,
      name: 'precheck',
      label: t('AddworkingDocumentTab.prechecktooltip', ' Pré-check'),
    },
    pending: {
      svg: orangePause,
      name: 'pending',
      label: t('Tooltip.pending', 'En attente'),
    },
    validated: {
      svg: greenCheck,
      name: 'valid',
      label: t('Tooltip.validated', 'Accepté'),
    },
    rejected: {
      svg: redCross,
      name: 'rejected',
      label: t('Tooltip.rejected', 'Rejeté'),
    },
    outdated: {
      svg: greyCross,
      name: 'expired',
      label: t('Tooltip.outdated', 'Expiré'),
    },
    pending_signature: {
      svg: orangePen,
      name: 'signaturepending',
      label: t(
        'Tooltip.pendingsignature',
        ' En attente de signature ',
      ),
    },
    refused_signature: {
      svg: redPen,
      name: 'signaturerejected',
      label: t(
        'Tooltip.signaturerefused',
        ' Signature refusée ',
      ),
    },
  };

  if (status !== 'missing') {
    return (
      <>
        <img
          src={statusList[status].svg}
          alt="document valid"
          className="document-list__icon"
          data-tip="react-tooltip"
          data-for={statusList[status].name}
        />
        <Tooltip
          id={statusList[status].name}
        >
          {statusList[status].label}
        </Tooltip>
      </>
    );
  }
  return '';
};

DocumentStatusIcon.propTypes = {
  status: PropTypes.string,
};

DocumentStatusIcon.defaultProps = {
  status: '',
};

export default DocumentStatusIcon;
