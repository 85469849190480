import {
  Auth,
} from 'aws-amplify';

export const getUserFromSession = (session) => ({
  fullName: session.idToken.payload.name,
  picture: session.idToken.payload.picture,
  email: session.idToken.payload.email,
});

export const currentSession = async () => {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    localStorage.setItem('token', token);
    return {
      isLogged: true,
      user: getUserFromSession(session),
      token,
    };
  } catch (error) {
    return {
      isLogged: false,
      error,
    };
  }
};

export const logOut = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  Auth.signOut();
};
