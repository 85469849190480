import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentsContext } from '../../contexts/documents.context';
import { UserContext } from '../../contexts/user.context';
import { EnterprisesContext } from '../../contexts/enterprises.context';
import { toKey } from '../../helpers/utils';
import ActiveDocumentTab from '../ActiveDocumentTab/ActiveDocumentTab';
import ActionButton from '../ActionButton/ActionButton';

/**
 * DocumentsTabs is a component that contains multiple tabs and allow
 * the user to show the tab he clicks on.
 * @version 1
 * @since 13/12/2021
 */

const ADDWORKING = 'ADDWORKING';

const DocumentsListTabs = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState('addworking');
  const [documents, setDocuments] = useState([]);
  const {
    allDocuments,
    setDocument,
    document,
    getDocumentsFromEnterprise,
  } = useContext(DocumentsContext);
  const { user } = useContext(UserContext);
  const { enterprise } = useContext(EnterprisesContext);

  const setDocumentActive = (documents) => {
    const activeDocument = documents.find((doc) => (
      doc.document_id === document?.document_id
    ));
    setDocument(activeDocument || documents[0]);
  };

  const sortDocuments = (a, b) => {
    const getName = (doc) => (
      doc.type_display_name.startsWith('{')
        ? JSON.parse(doc.type_display_name)?.fr
        : doc.type_display_name
    );
    return getName(a).toLowerCase().localeCompare(getName(b).toLowerCase());
  };

  useEffect(() => {
    if (allDocuments.length) {
      let documentsFound = [];
      switch (active) {
        case 'inactive':
          documentsFound = allDocuments
            .filter((document) => !document.customer_name);
          break;
        case 'active':
          documentsFound = allDocuments
            .filter((document) => (
              document.customer_name
              && document.customer_name !== ADDWORKING
            ));
          break;
        default:
          documentsFound = allDocuments
            .filter((document) => document.customer_name === ADDWORKING);
      }
      documentsFound = documentsFound.sort(sortDocuments);
      setDocumentActive(documentsFound);
      setDocuments(documentsFound);
    }
  }, [active, allDocuments]);

  const handleTab = (tab) => () => {
    setActive(tab);
  };

  const activeClassName = (tab) => (
    tab === active ? 'active' : ''
  );

  const tabs = [
    {
      label: 'AddWorking',
      name: 'addworking',
    },
    {
      label: t('State.active', 'Actif{{s}}', { s: 's' }),
      name: 'active',
    },
    {
      label: t('State.inactive', 'Inactif{{s}}', { s: 's' }),
      name: 'inactive',
    },
  ];

  const rejectAllDocuments = async () => {
    if (active === 'inactive') {
      const ids = documents.filter((e) => e.status === 'pending').map((e) => e.document_id);
      const body = {
        document_ids: { ids },
        auth_user_email: user.email,
      };
      if (body.document_ids && body.auth_user_email) {
        const res = await rejectAllDocuments(body);
        if (res.success) {
          getDocumentsFromEnterprise(enterprise.id);
        }
      }
    }
  };

  return (
    <div className="tabs">
      <ul className="nav">
        {
          tabs.map((tab) => (
            <li
              key={toKey(tab)}
              className={`${activeClassName(tab.name)} category-tab`}
              onClick={handleTab(tab.name)}
              aria-hidden="true"
            >
              {tab.label}
            </li>
          ))
        }
      </ul>
      <div className="outlet">
        {
          active === 'inactive' && (
            <div className="reject-all-button__container">
              <ActionButton
                onClick={rejectAllDocuments}
                type="button"
                buttonClassName="reject-all-button"
              >
                {t('DocumentListTab.rejectAllDocument', 'Rejeter tous les documents')}
              </ActionButton>
            </div>
          )
        }
        <ActiveDocumentTab documents={documents} />
      </div>
    </div>
  );
};

export default DocumentsListTabs;
