import React, { useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const PeriodSelector = ({ onPeriodChange }) => {
  const [selectedPeriod, setSelectedPeriod] = useState('30 days');
  const { t } = useTranslation();

  const periods = [
    { value: '7 days', label: t('Common.PeriodSelector.7days') },
    { value: '30 days', label: t('Common.PeriodSelector.30days') },
    { value: '90 days', label: t('Common.PeriodSelector.90days') },
    { value: 'current month', label: t('Common.PeriodSelector.currentMonth') },
    { value: 'current year', label: t('Common.PeriodSelector.currentYear') },
    { value: 'last week', label: t('Common.PeriodSelector.lastWeek') },
    { value: 'last month', label: t('Common.PeriodSelector.lastMonth') },
    { value: 'last year', label: t('Common.PeriodSelector.lastYear') },
  ];

  const getPeriodLabelByValue = (value) => periods.find((period) => period.value === value).label;

  const handleSelect = (period) => {
    let today = new Date();
    let startDate;

    switch (period) {
      case '7 days':
        startDate = new Date(today.setDate(today.getDate() - 7));
        today = new Date();
        break;
      case '30 days':
        startDate = new Date(today.setDate(today.getDate() - 30));
        today = new Date();
        break;
      case '90 days':
        startDate = new Date(today.setDate(today.getDate() - 90));
        today = new Date();
        break;
      case 'current month':
        startDate = new Date(today.getFullYear(), today.getMonth(), 2);
        today = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        break;
      case 'current year':
        startDate = new Date(today.getFullYear(), 0, 2);
        today = new Date(today.getFullYear(), 11, 32);
        break;
      case 'last week':
        startDate = new Date(today.setDate(today.getDate() - today.getDay() - 6));
        today.setDate(startDate.getDate() + 6);
        break;
      case 'last month':
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 2);
        today = new Date(today.getFullYear(), today.getMonth(), 1);
        break;
      case 'last year':
        startDate = new Date(today.getFullYear() - 1, 0, 2);
        today = new Date(today.getFullYear() - 1, 11, 32);
        break;
      default:
        startDate = new Date(today.setDate(today.getDate() - 30));
    }
    setSelectedPeriod(period);
    onPeriodChange(startDate, today);
  };

  return (
    <div className="d-flex justify-content-center my-3">
      <DropdownButton
        title={(
          <>
            <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faChevronDown} />
            {
              getPeriodLabelByValue(selectedPeriod)
            }
          </>
        )}
        variant="primary"
        onSelect={handleSelect}
      >
        {periods.map(({ value, label }) => (
          <Dropdown.Item key={value} eventKey={value}>
            {label}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );
};

PeriodSelector.propTypes = {
  onPeriodChange: PropTypes.func.isRequired,
};

export default PeriodSelector;
